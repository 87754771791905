<template>
  <div class="mt-5">
    <v-card outlined :loading="loadingForm" class="mt-5">
      <v-card-title
        >Informacion de otros dispositivos
        <v-spacer />
        <v-btn icon @click="dialogDevices = true">
          {{ devices.length }}
          <v-icon
            :disabled="schema == 'search' ? disabledButtons : false"
            class="ml-1"
            size="30"
            color="primary"
          >
            mdi-monitor
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle
        >Datos de los equipos: Biometricos, impresoras, torniquetes...
      </v-card-subtitle>
      <v-card-text>
        <v-form
          v-model="validDevices"
          ref="formDevice"
          :disabled="schema == 'search' ? disabledForms : false"
        >
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                label="Tipo de dispositivo"
                :items="deviceTypes"
                v-model="deviceType"
                :rules="rulesDeviceType"
                ref="deviceType"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Nombre del dispositivo"
                v-model="deviceName"
                :rules="rulesDeviceName"
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-switch v-model="useCode" label="Asignar codigo"></v-switch>
            </v-col>

            <v-col cols="12" md="6">
              <v-switch
                v-model="isServer"
                @change="changeIsServer"
                label="Servidor"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="schema == 'search' ? disabledForms : false"
          text
          color="primary"
          @click="loadDevice"
          >{{ `${!deviceIsEdit ? "AGREGAR" : "EDITAR"} DISPOSITIVO` }}
        </v-btn>
        <v-btn
          :disabled="schema == 'search' ? disabledForms : false"
          text
          color="red"
          v-if="deviceIsEdit"
          @click="cancelEditDevice"
        >
          CANCELAR
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogDevices" width="1000" persistent>
      <v-card max-height="400">
        <v-card-title
          >Dispositivos
          <v-spacer />
          <v-btn icon @click="dialogDevices = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="devices"
            :headers="deviceHeaders"
            hide-default-footer
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.type_text }}</td>
                <td>{{ props.item.name }}</td>
                <td>
                  {{ props.item.use_code ? `SI` : `NO` }}
                </td>
                <td>{{ props.item.is_server ? `SI` : `NO` }}</td>

                <td>
                  <v-btn
                    v-if="!disabledForms"
                    :disabled="deviceIsEdit"
                    icon
                    @click="editDevice(props.index)"
                    ><v-icon>mdi-check</v-icon></v-btn
                  >
                  <v-btn
                    v-if="!disabledForms"
                    :disabled="deviceIsEdit"
                    icon
                    @click="deleteDevice(props.index)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mixinForm } from "../../mixins";
export default {
  props: {
    schema: String,
    data: Function,
    click: Number,
    clear: Number,
    business: Object,
    disabledForms: Boolean,
  },
  mixins: [mixinForm],

  mounted() {
    if (this.business) this.loadData();
  },

  data() {
    return {
      isServer: false,
      useCode: false,
      devices: [],
      deletes: [],
      deviceId: 0,
      deviceEditIndex: "",
      deviceIsEdit: false,
      deviceType: "",
      deviceName: "",
      dialogDevices: false,
      disabledButtons: true,

      deviceHeaders: [
        {
          text: "Tipo",
          value: "type_text",
          sortable: false,
        },
        {
          text: "Nombre",
          value: "name",
          sortable: false,
        },

        {
          text: "Usa codigo",
          value: "useCode",
          sortable: false,
        },
        {
          text: "Servidor",
          value: "isServer",
          sortable: false,
        },
        {
          text: "Acciones",
          aling: "right",
          sortable: false,
        },
      ],
      rulesDeviceType: [
        (deviceType) =>
          (deviceType && deviceType != "") ||
          "Debe seleccionar un tipo de dispositivo",
      ],
      rulesDeviceName: [
        (deviceName) =>
          (deviceName && deviceName.length > 0) ||
          "Debe ingresar el nombre del dispositivo",
      ],

      rulesDeviceIp: [
        (deviceIp) =>
          !deviceIp || this.validIp(deviceIp) || "Debe ingresar una IP valida",
      ],
    };
  },

  watch: {
    click: function () {
      if (this.devices.length > 0) {
        for (let key in this.devices) {
          delete this.devices[key].type_text;
        }
      }
      this.data({ devices: this.devices, deletes: this.deletes });
    },

    clear: function () {
      this.devices = [];
      this.$refs.formDevice.resetValidation();
    },

    deviceName(value) {
      this.deviceName = value.toUpperCase();
    },
  },

  computed: {
    deviceTypes() {
      return this.$store.getters.getSetupGeneralSelectByKey(2);
    },
  },

  methods: {
    loadData() {
      this.devices = this.business.devices;

      for (let key in this.devices) {
        let deviceTypeText = this.deviceTypes.find(
          (deviceType) => deviceType.value === this.devices[key].type_id
        );
        this.devices[key]["type_text"] = deviceTypeText.text;
        this.devices[key].use_code =
          this.devices[key].code && this.devices[key].useCode != "0"
            ? true
            : false;
      }
      this.disabledButtons = false;
    },

    loadDevice() {
      if (this.validDevices) {
        let deviceTypeText = this.deviceTypes.find(
          (deviceType) => deviceType.value === this.deviceType
        );

        let device = {
          id: 0,
          action: "",
          type_id: this.deviceType,
          type_text: deviceTypeText.text,
          name: this.deviceName,
          use_code: this.useCode,
          is_server: this.isServer,
        };

        if (this.deviceIsEdit) {
          device.action = "edit";
          device.id = this.deviceId;
        } else {
          device.action = "new";
        }

        this.deviceIsEdit
          ? this.devices.splice(this.deviceEditIndex, 1, device)
          : this.devices.push(device);

        this.resetFormDevice();
      } else this.$refs.formDevice.validate();
    },

    editDevice(index) {
      this.deviceId = this.devices[index].id;
      this.deviceType = this.devices[index].type_id;
      this.deviceName = this.devices[index].name;
      this.useCode = this.devices[index].use_code;
      this.isServer = this.devices[index].is_server;
      this.dialogDevices = false;
      this.deviceEditIndex = index;
      this.deviceIsEdit = true;
    },

    deleteDevice(index) {
      this.$confirm("Desea eliminar este dispositivo?").then((response) => {
        if (response) {
          if (this.devices[index].id) {
            let item = {
              id: this.devices[index].id,
              name: "device",
            };
            this.deletes.push(item);
          }
          this.devices.splice(index, 1);
        }
      });
    },

    cancelEditDevice() {
      this.$confirm("Desea cancelar la edicion del dispositivo?").then(
        (response) => {
          if (response) {
            this.deviceEditIndex = "";
            this.deviceIsEdit = false;
            this.resetFormDevice();
          }
        }
      );
    },

    resetFormDevice() {
      this.deviceType = "";
      this.deviceName = "";
      this.deviceEditIndex = "";
      this.deviceIsEdit = false;
      this.useCode = false;
      this.isServer = false;
      this.$refs.formDevice.resetValidation();
    },

    changeIsServer() {
      if (this.isServer) {
        this.useCode = true;
      }
    },
  },
};
</script>

<style></style>
