//metodo que convierte un conjunto de palabras
//en un formato legible para ser usado en la busqueda
//con metodo like y envitar el %20 que se agrega por default
const formatManyWords = (words) => {
  let arrayArgs = words.split(" ");
  let argsFormat = "";
  for (let key in arrayArgs) {
    if (arrayArgs[key].length > 0) {
      argsFormat += `${arrayArgs[key].trim()}_`;
    }
  }
  argsFormat = argsFormat.substring(0, argsFormat.length - 1);
  return argsFormat;
};

const formatAmountCulqi = (amount) => {
  let amount_ = amount.toString();
  let intPart = amount_.substring(0, amount_.length - 2);
  let decimalPart = amount_.substring(amount_.length - 2, amount_.length);
  let amountCulqi = `${intPart},${decimalPart}`;
  return amountCulqi;
};

export { formatManyWords, formatAmountCulqi };
