<template>
  <v-container>
    <module-bar title="NUEVO COBRO UNICO">
      <template slot="controls">
        <complete-business
          v-if="isBussines"
          :onlyBusiness="0"
          :getBusiness="getBusiness"
          :getPotencial="getPotencial"
          :clear="clearAutocomplete"
          class="mb-2"
          @setBusiness="setBusiness"
          @setPotencial="setPotencial"
        />
      </template>
      <template slot="menu">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"
              ><v-icon>mdi-menu</v-icon></v-btn
            >
          </template>
          <v-card>
            <v-list>
              <v-list-item v-if="isBussines" @click="showBusiness">
                <v-list-item-content>
                  <v-list-item-title>Ver empresa</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- <v-list-item @click="showBusiness">
                <v-list-item-content>
                  <v-list-item-title @click="openDialogPaymentSaved"
                    >Cobro en borrador</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item> -->
              <v-list-item @click="resetPay(isBussines)">
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      isBussines
                        ? `Pago para empresa no registrada`
                        : `Pago para empresa`
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
    </module-bar>

    <potencial-simple
      v-if="!isBussines"
      :click="click"
      :potencial="getPotencial"
      @setPotencial="setPotencial"
      @setBusiness="setBusiness"
    />

    <!-- datatable -->
    <v-card outlined>
      <v-card-title> <v-spacer /> TOTAL: {{ total }} USD </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <complete-products
              :getProducts="getProducts"
              :countryId="country_id"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              v-if="isBussines"
              icon
              color="primary"
              @click="openNotification"
            >
              <v-icon>mdi-email-send-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="12">
            <v-data-table :headers="headers" :items="items">
              <template slot="item" slot-scope="props">
                <tr>
                  <td>{{ props.item.id }}</td>
                  <td>{{ props.item.name }}</td>
                  <td>{{ props.item.quantity }}</td>
                  <td>{{ props.item.price }}</td>
                  <td>{{ props.item.import }}</td>
                  <td>{{ props.item.tax }}%</td>
                  <td>{{ props.item.total }}</td>
                  <td>
                    <v-btn icon @click="activeDialog(props.index, 'datatable')">
                      <v-icon size="18">mdi-square-edit-outline </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="12" class="d-flex justify-end">
            SUBTOTAL: {{ subtotal }}
          </v-col>
          <v-col cols="12" md="12" class="d-flex justify-end">
            TAX: {{ taxes }}
          </v-col>
          <v-col cols="12" md="12" class="d-flex justify-end">
            TOTAL: {{ total }}
          </v-col>
          <v-col cols="12" xl="6" md="6" sm="6" class="d-flex justify-end">
            <v-switch
              label="Pago parcial"
              @change="changePayPart"
              v-model="isPayPart"
            />

            <v-text-field
              class="ml-2"
              :disabled="!isPayPart"
              v-model="amountPart"
              :rules="rulesAmountPart"
              ref="amountPart"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <!-- <v-btn
          :loading="loadingForm"
          text
          color="primary"
          outlined
          @click="register('0')"
          >Guardar Borrador</v-btn
        > -->
        <v-btn :loading="loadingForm" color="primary" @click="register('1')"
          >Registrar</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- dialogo notification a los involucrados-->
    <v-dialog v-model="dialogNotifications" width="500" max-width="500">
      <v-card>
        <v-card-title
          >Notificacion
          <v-spacer />
          <v-btn icon @click="dialogNotifications = !dialogNotifications"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <notification
            :business="business"
            :potencial="potencial"
            :getContacts="getContacts"
            @close="closeNotification"
            :finalized="finalized"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dialogo de item -->
    <v-dialog v-model="dialogItem" width="400" max-width="400" persistent>
      <v-card>
        <v-card-title
          >{{ titleDialog }}
          <v-spacer />
          <v-btn icon @click="closeDialogItem"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Cantidad"
                  v-model="quantity"
                  :rules="rulesQuantity"
                />
              </v-col>

              <v-col cols="12" md="12">
                <v-row>
                  <v-text-field
                    label="Precio"
                    v-model="price"
                    :rules="rulesPrice"
                    prefix="$"
                  >
                  </v-text-field>
                </v-row>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field
                  label="Impuesto"
                  v-model="tax"
                  :rules="rulesTax"
                  prefix="$"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" v-if="isDeleteItem" text @click="deleteItem()">
            Eliminar
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="schema == 'new' ? addItem() : editItem()"
            >{{ btnDialog }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialogo de pagos guardados en borrador -->
    <v-dialog v-model="dialogPaymentSaved" width="800">
      <v-card :loading="loadingPaymentSaved">
        <v-card-title
          >Lista de pagos en borrador<v-spacer /><v-btn
            icon
            @click="dialogPaymentSaved = !dialogPaymentSaved"
            ><v-icon>mdi-close</v-icon>
          </v-btn></v-card-title
        >
        <v-card-text>
          <v-data-table
            :headers="headerPaymentSaved"
            :items="paymentUniqueSaved"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.index + 1 }}</td>
                <td>{{ props.item.business.name }}</td>
                <td>{{ props.item.quantity }}</td>
                <td>
                  {{ props.item.createdBy.names }}
                  {{ props.item.createdBy.surnames }}
                  <p>
                    {{ props.item.created_at }}
                  </p>
                </td>
                <td>
                  <v-btn icon @click="selectPaymentUniqueSaved(props.item.id)">
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dialog ver empresa -->
    <v-dialog v-model="dialogShowBusiness" width="800" max-width="800">
      <v-card>
        <v-card-title>
          Ver Empresa
          <v-spacer />
          <v-btn icon @click="dialogShowBusiness = !dialogShowBusiness">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <info schema="search" :business="business" :disabledForms="true" />
          <devices schema="search" :business="business" :disabledForms="true" />
          <contacts
            schema="search"
            :business="business"
            :disabledForms="true"
          />
          <owners schema="search" :business="business" :disabledForms="true" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CompleteBusiness from "../../components/General/CompleteBusiness.vue";
import CompleteProducts from "../../components/General/CompleteProducts.vue";
import ModuleBar from "../../components/General/ModuleBar.vue";
import { mixinForm } from "../../mixins";
import bus from "../../events/bus";
import PotencialSimple from "../../components/Business/PotencialSimple.vue";
import Info from "../../components/Business/Info.vue";
import Devices from "../../components/Business/Devices.vue";
import Contacts from "../../components/Business/Contacts.vue";
import Owners from "../../components/Business/Owners.vue";
import Notification from "../../components/Business/Notification.vue";

export default {
  components: {
    ModuleBar,
    CompleteBusiness,
    CompleteProducts,
    PotencialSimple,
    Info,
    Devices,
    Contacts,
    Owners,
    Notification,
  },
  mixins: [mixinForm],

  data() {
    return {
      click: 0,
      business: null,
      potencial: null,
      country_id: 0,
      id: 0,
      name: null,
      quantity: null,
      price: null,
      tax: null,
      productId: 0,
      items: [],
      total: 0,
      subtotal: 0,
      taxes: 0,
      dialog: false,
      titleDialog: "",
      btnDialog: "",
      schema: "",
      index: 0,
      disabled: true,
      clearAutocomplete: false,
      dialogNotifications: false,
      self: [],
      value: "",
      type: "",
      fullName: "",
      others: [],
      isFinal: false,
      isPayPart: false,
      amountPart: "",
      dialogPaymentSaved: false,
      loadingPaymentSaved: false,
      paymentUniqueSaved: [],
      dialogItem: false,
      isBussines: true,
      isDeleteItem: false,
      dialogShowBusiness: false,
      notifications: [],
      finalized: false,

      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Item",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Cantidad",
          align: "start",
          sortable: false,
          value: "quantity",
        },
        {
          text: "Precio C/U",
          align: "start",
          sortable: false,
          value: "price",
        },
        {
          text: "Importe",
          align: "start",
          sortable: false,
          value: "import",
        },
        {
          text: "Impuesto",
          align: "start",
          sortable: false,
          value: "tax",
        },
        {
          text: "Total",
          align: "start",
          sortable: false,
          value: "total",
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "item",
        },
      ],
      headerPaymentSaved: [
        {
          text: "#",
          align: "start",
          sortable: false,
        },
        {
          text: "Empresa",
          align: "start",
          sortable: false,
        },
        {
          text: "Monto",
          align: "start",
          sortable: false,
        },
        {
          text: "Creador",
          align: "start",
          sortable: false,
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
        },
      ],
      rulesName: [
        (name) => (name && name != "") || "Debe ingresar el nombre del item ",
      ],
      rulesQuantity: [
        (quantity) =>
          (quantity && quantity != "") || "Debe ingresar la cantidad de items ",
        (quantity) =>
          (quantity && !isNaN(quantity)) || "La cantidad debe ser numerico",
      ],
      rulesPrice: [
        (price) =>
          (price && price != "") ||
          "Debe ingresar el precio individual del item",
        (price) => (price && !isNaN(price)) || "El precio debe ser numerico",
      ],
      rulesFullName: [
        (name) => (name && name != "") || "Debe ingresar el nombre completo ",
      ],
      rulesType: [(type) => (type && type != "") || "Debe seleccionar el tipo"],
      rulesValue: [
        (value) => (value && value != "") || "Debe ingresar el valor",
      ],
      rulesTax: [
        (tax) => (tax && !isNaN(tax)) || "El impuesto debe ser numerico",
      ],
      rulesAmountPart: [
        (amount) =>
          (amount && !isNaN(amount)) || "El monto parcial debe ser numerico",
        (amount) =>
          (amount && parseFloat(amount) > 0) ||
          "El monto parcial debe ser mayor a cero",
      ],
    };
  },

  methods: {
    getBusiness(business) {
      if (business) {
        this.business = business;
        this.country_id = business.district.province.departament.country.id;
        this.clearAutocomplete = false;
        this.finalized = false;

        if (this.$refs.formOther) {
          this.$refs.formOther.resetValidation();
        }
      } else {
        this.business = null;
        this.country_id = 0;
        this.clearAutocomplete = true;
      }
      this.disabled = this.business ? false : true;
    },

    getPotencial(potencial) {
      if (potencial) {
        this.country_id = potencial.country_id;
        this.potencial = potencial;
      }
    },

    setPotencial() {
      this.items = [];
      this.country_id = 0;
      this.potencial = null;
      this.getTotal();
    },

    setBusiness() {
      this.items = [];
      this.country_id = 0;
      this.business = null;
      this.getTotal();
    },

    getProducts(products) {
      if (products) {
        this.productId = products.id;
        this.dialogItem = true;
        this.name = products.name;
        this.price = products.price;
        this.titleDialog = "Agregar Item";
        this.btnDialog = "Agregar";
        this.schema = "new";
      }
    },

    showBusiness() {
      if (this.business || this.potencial) {
        this.dialogShowBusiness = true;
      } else {
        bus.$emit("snackbar", {
          color: "red",
          text: `Seleccione un empresa o un cliente potencial`,
        });
      }
    },

    activeDialog(index, opc = "") {
      if (this.$refs.form) this.$refs.form.resetValidation();
      this.dialogItem = true;
      if (opc != "") this.isDeleteItem = true;

      if (index >= 0) {
        this.index = index;
        this.quantity = this.items[index].quantity;
        this.tax = this.items[index].tax;
        this.price = this.items[index].price;
        this.titleDialog = "Editar Item";
        this.btnDialog = "Editar";
        this.schema = "edit";
      }
    },

    closeDialogItem() {
      this.dialogItem = !this.dialogItem;
      this.isDeleteItem = false;
    },

    addItem() {
      if (this.valid) {
        this.id++;
        let import_ = parseFloat(this.price) * parseFloat(this.quantity);
        let tax_ = parseFloat(this.tax);
        let total_ = import_ * (tax_ / 100) + import_;

        this.items.push({
          id: this.productId,
          name: this.name,
          quantity: this.quantity,
          price: this.price,
          tax: this.tax,
          import: import_,
          total: total_,
        });

        this.getTotal();
        this.clearForm();
        this.dialogItem = false;
      } else {
        this.$refs.form.validate();
      }
    },

    editItem() {
      if (this.valid) {
        let import_ = parseFloat(this.price) * parseFloat(this.quantity);

        let tax_ = parseFloat(this.tax);
        let total_ = import_ * (tax_ / 100) + import_;

        this.items[this.index].quantity = this.quantity;
        this.items[this.index].tax = tax_;
        this.items[this.index].total = total_;
        this.items[this.index].import = import_;

        this.dialog = false;
        this.dialogItem = false;
        this.isDeleteItem = false;
        this.index = 0;
        this.getTotal();
        this.clearForm();
      } else {
        this.$refs.form.validate();
      }
    },

    deleteItem() {
      this.$confirm("Desea eliminar este item?").then((response) => {
        if (response) {
          this.items.splice(this.index, 1);
          this.dialogItem = false;
          this.isDeleteItem = false;
          this.getTotal();
        }
      });
    },

    getTotal() {
      this.taxes = 0;
      this.subtotal = 0;
      this.total = 0;
      let taxes = 0;

      for (let key in this.items) {
        taxes =
          parseFloat(this.items[key].import) *
          (parseFloat(this.items[key].tax) / 100);
        this.taxes += taxes;
        this.subtotal += parseFloat(this.items[key].import);
        this.total += parseFloat(this.items[key].total);
      }
    },

    clearForm() {
      this.name = "";
      this.quantity = "";
      this.price = "";
      this.tax = "";
      this.$refs.form.resetValidation();
    },

    clearDataPart() {
      this.amountPart = 0;
      this.isPayPart = false;
    },

    register(schema) {
      this.click++;
      //proceso de empresa no registrada
      if (!this.isBussines && this.potencial == null) {
        bus.$emit("snackbar", {
          color: "red",
          text: `Verifique el formulario de cliente potencial`,
        });
        return;
      } else if (
        this.isBussines &&
        this.business == null &&
        this.potencial == null
      ) {
        //proceso para empresa o potencial registrado
        bus.$emit("snackbar", {
          color: "red",
          text: `Seleccione un empresa`,
        });
        return;
      } else {
        if (this.validRegister(schema)) {
          let items_ = [];
          this.loadingForm = true;

          for (let key in this.items) {
            let item = {
              product_id: this.items[key].id,
              name: this.items[key].name,
              quantity: this.items[key].quantity,
              price: this.items[key].price,
              tax: this.items[key].tax,
            };
            items_.push(item);
          }

          let is_potencial = 0;
          if (this.potencial != null) {
            is_potencial = 1;
          } else {
            if (this.isBussines) {
              is_potencial = 0;
            } else {
              is_potencial = 1;
            }
          }

          let payload = {
            business_id: this.business != null ? this.business.id : 0,
            is_potencial: is_potencial,
            potencial: this.potencial,
            condition: schema == 0 ? "save" : "final",
            type: "unique",
            status: "pending",
            items: items_,
            isPayPart: this.isPayPart,
            amountPart: this.amountPart,
            total: this.total,
            notifications: this.notifications,
          };

          this.$store
            .dispatch("registerPaymentUnique", payload)
            .then(() => {
              this.loadingForm = false;
              this.items = [];
              this.self = [];
              this.others = [];
              this.business = null;
              this.potencial = null;
              this.clearAutocomplete = true;
              this.finalized = true;
              this.getTotal();
              this.clearForm();
              this.clearDataPart();
              this.isBussines = true;
            })
            .catch(() => {
              this.loadingForm = false;
            });
        }
      }
    },

    validRegister(schema) {
      if (this.items.length > 0) {
        if (
          !this.isBussines ||
          schema == "0" ||
          this.self.length > 0 ||
          this.others.length > 0
        ) {
          if (this.isPayPart && parseFloat(this.amountPart) <= 0) {
            bus.$emit("snackbar", {
              color: "red",
              text: `El monto parcial tiene que ser mayor a cero`,
            });
            return false;
          } else if (
            this.isPayPart &&
            parseFloat(this.amountPart) > this.total
          ) {
            bus.$emit("snackbar", {
              color: "red",
              text: `El monto parcial no puede ser mayor al total`,
            });
          } else {
            return true;
          }
        } else {
          bus.$emit("snackbar", {
            color: "red",
            text: "Debe agregar al menos un destinatario",
          });
          return false;
        }
      } else {
        bus.$emit("snackbar", {
          color: "red",
          text: "Debe agregar al menos un item",
        });
        return false;
      }
    },

    openNotification() {
      this.dialogNotifications = !this.dialogNotifications;
    },

    addOther() {
      if (this.validOther) {
        let other = {
          fullName: this.fullName,
          type: this.type,
          value: this.value,
        };
        this.others.push(other);
        this.fullName = "";
        this.type = "";
        this.value = "";
        this.$refs.formOther.resetValidation();
      } else {
        this.$refs.formOther.validate();
      }
    },

    deleteOther(key) {
      this.$confirm("Desea eliminar este item?")
        .then((response) => {
          if (response) {
            this.others.splice(key, 1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    changePayPart() {
      if (!this.isPayPart) {
        this.amountPart = "";
        this.$refs.amountPart.resetValidation();
      }
    },

    openDialogPaymentSaved() {
      this.dialogPaymentSaved = !this.dialogPaymentSaved;
      this.loadingPaymentSaved = true;
      this.$store
        .dispatch("findPaymentUniqueByStatus", 0)
        .then((response) => {
          this.paymentUniqueSaved = response;
          this.loadingPaymentSaved = false;
        })
        .catch(() => {
          this.loadingPaymentSaved = false;
        });
    },

    resetPay(isBussines) {
      this.items = [];
      this.self = [];
      this.others = [];
      this.business = null;
      this.isBussines = !isBussines;
      this.potencial = null;
      this.clearAutocomplete = true;
      this.getTotal();
    },

    getContacts(self, others) {
      this.self = [];
      this.others = [];
      this.notifications = [];
      this.self = self;
      this.others = others;

      if (this.self.length > 0) {
        for (let key in this.self) {
          let aux = this.self[key].split("_");
          let item = {
            is_unique: 0,
            type: aux[1],
            value: aux[2],
            option: "self",
            table: aux[0],
            full_name: aux[3],
          };
          this.notifications.push(item);
        }
      }

      if (this.others.length > 0) {
        for (let key in this.others) {
          let item = {
            is_unique: 0,
            type: this.others[key].type,
            value: `${this.others[key].value}${this.others[key].value}`,
            option: "other",
            table: "contacts",
            full_name: this.others[key].fullName,
          };
          this.notifications.push(item);
        }
      }
    },

    closeNotification() {
      this.dialogNotifications = false;
    },
  },
};
</script>

<style></style>
