<template>
  <v-row>
    <v-col cols="12" sm="12" md="6">
      <v-text-field
        v-model="code"
        label="Codigo de producto"
        prepend-icon="mdi-filter-variant"
        @click:prepend="openDialogProducts"
        @keypress.enter="findProduct"
        :loading="loadingFindProduct"
      />
    </v-col>
    <!-- dialogo de productos -->
    <v-dialog
      persistent
      max-width="500"
      width="500"
      v-model="dialogSearchProducts"
    >
      <v-card>
        <v-card-title>
          <v-spacer />
          <v-btn icon @click="closeDialogProducts">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <div class="h4 ml-6 mb-2">PRODUCTOS DE {{ countryName }}</div>
        <v-card-text>
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="args"
                label="Buscar producto"
                @keypress.enter="searchProducts"
              />
            </v-col>
            <v-col cols="2" class="d-flex justify-start align-center">
              <v-btn icon color="primary" @click="searchProducts"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center" v-if="loadingProducts">
            <v-progress-circular indeterminate color="primary" width="2" />
          </v-row>
          <v-list>
            <v-list-item-group color="primary">
              <template v-for="(product, index) in products">
                <v-list-item :key="index" @click="selectProduct(product)">
                  <v-list-item-avatar>
                    <v-img :src="product.photo" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ product.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ product.price }} {{ countryCurrency }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ product.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="`div-${index}`" />
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import bus from "../../events/bus";
import { formatManyWords } from "../../util";

export default {
  props: { getProducts: Function, clear: Boolean, countryId: Number },

  data() {
    return {
      product: {},
      products: [],
      dialogSearchProducts: false,
      loadingProducts: false,
      countryName: "",
      countryCurrency: "",
      args: "",
      code: "",
      loadingFindProduct: false,
    };
  },

  computed: {
    countries() {
      return this.$store.getters.getCountrySelect;
    },
  },

  methods: {
    openDialogProducts() {
      if (this.countryId != 0) {
        let country = this.countries.find(
          (country) => country.value == this.countryId
        );
        this.countryName = country.text;
        this.countryCurrency = country.currency;

        this.dialogSearchProducts = true;
      } else {
        bus.$emit("snackbar", {
          color: "red",
          text: `No has seleccionado una empresa o agregado un cliente potencial`,
        });
      }
    },

    closeDialogProducts() {
      this.dialogSearchProducts = false;
      this.products = [];
      this.args = "";
    },

    selectProduct(product) {
      this.getProducts(product);
    },

    findProduct() {
      this.loadingFindProduct = true;
      setTimeout(() => {
        axios
          .get(`/product/find-by-code/${this.countryId}/${this.code}`)
          .then((response) => {
            if (response.data) {
              this.product = response.data;
              this.code = "";
              this.getProducts(this.product);
            } else {
              bus.$emit("snackbar", {
                color: "red",
                text: `Producto no existe en el pais seleccionado`,
              });
            }
            this.loadingFindProduct = false;
          })
          .catch(() => {
            this.loadingFindProduct = false;
          });
      }, 500);
    },

    searchProducts() {
      if (this.args.length > 0) {
        let argsFormat = formatManyWords(this.args);
        this.products = [];
        this.loadingProducts = true;
        setTimeout(() => {
          axios
            .get(`/product/find-by-args/${this.countryId}/${argsFormat}`)
            .then((res) => {
              this.loadingProducts = false;
              this.products = res.data;
            })
            .catch(() => {
              this.loadingProducts = false;
            });
        }, 500);
      }
    },
  },
};
</script>

<style></style>
