<template>
  <v-dialog v-model="dialog" persistent width="600">
    <v-card :loading="loading">
      <v-card-title
        >Logo de empresa: {{ businessName }}
        <v-spacer />
        <v-btn icon @click="dialog = !dialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <div class="text-center" v-if="disabled"><h2>Sin logo</h2></div>
        <v-img
          :aspect-ratio="1.7"
          :src="businessLogo"
          :lazy-src="businessThumb"
          contain
          alt="logo"
          v-if="!disabled"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                width="2"
                size="25"
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          elevation="0"
          v-if="isEdit"
          @click="deleteLogo"
          :disabled="disabled"
          color="error"
          >Eliminar Logo</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import bus from "../../events/bus";

export default {
  data() {
    return {
      dialog: false,
      businessId: 0,
      businessName: "",
      loading: true,
      disabled: true,
      businessLogo: null,
      businessThumb: null,
      isEdit: false,
    };
  },

  beforeMount() {
    bus.$on("openLogo", ({ businessId, isEdit }) => {
      this.businessId = businessId;
      this.isEdit = isEdit;
      this.dialog = true;
    });
  },

  computed: {
    business() {
      return this.$store.getters.getBusinessOne;
    },
  },

  watch: {
    dialog: function (value) {
      if (value) {
        if (this.business && this.business.id == this.businessId) {
          this.businessLogo = this.business.logo;
          this.businessThumb = this.business.thumb;
          this.loading = false;
          this.businessLogo != "" && this.businessLogo != null
            ? (this.disabled = false)
            : (this.disabled = true);
        } else {
          console.log("no");
          //   // si la empresa que esta en el store es diferente al id enviado
          //   this.$store
          //     .dispatch("findBusinessById", value.businessId)
          //     .then((response) => {
          //       this.businessLogo = response.logo;
          //       this.loading = false;
          //     })
          //     .catch(() => {
          //       this.loading = true;
          //     });
        }
      }
    },
  },

  methods: {
    deleteLogo() {
      this.$confirm(`Desea eliminar el logo?`).then((response) => {
        if (response) {
          this.$store
            .dispatch("deleteLogo", this.businessId)
            .then(() => {
              this.dialog = false;
              this.businessId = 0;
            })
            .catch(() => {});
        }
      });
    },
  },
};
</script>

<style></style>
