<template>
  <v-container>
    <v-row>
      <!-- Header -->
      <v-col cols="12">
        <module-bar title="PRODUCTOS">
          <template slot="search">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              style="max-width: 400px"
            ></v-text-field>
          </template>

          <template slot="buttons">
            <v-btn color="primary" text @click="openDialog()"> agregar </v-btn>
          </template>
        </module-bar>
      </v-col>

      <!-- Datatable -->
      <v-col cols="12">
        <v-data-table
          :items="products"
          :loading="loadingData"
          :headers="headers"
          :search="search"
          dense
          :mobile-breakpoint="1000"
        >
          <template v-slot:[`item.url_photo`]="{ item }">
            <v-avatar size="50" class="mt-2">
              <v-img :src="item.url_photo" />
            </v-avatar>
          </template>

          <template v-slot:[`item.created`]="{ item }">
            <p class="users my-1">
              {{
                `${item.createdBy.names} 
                   ${item.createdBy.surnames}`
              }}
              <br />
              {{ `${item.created_at}` }}
            </p>
          </template>

          <template v-slot:[`item.updated`]="{ item }">
            <p class="users my-1">
              {{
                `${item.updatedBy.names} 
                   ${item.updatedBy.surnames}`
              }}
              <br />
              {{ `${item.created_at}` }}
            </p>
          </template>

          <template v-slot:[`item.enabled`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="item.enabled ? 'green' : 'red'"
                  size="16"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-circle
                </v-icon>
              </template>
              <span>{{ item.enabled ? "Habilitado" : "Deshabilitado" }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialogSee(item)"
                >
                  <v-icon size="18">mdi-eye</v-icon>
                </button>
              </template>
              <span>Ver</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button
                  icon
                  class="ml-3"
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialog(item)"
                >
                  <v-icon size="18">mdi-pencil</v-icon>
                </button>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button
                  icon
                  class="ml-3 mr-3"
                  v-bind="attrs"
                  v-on="on"
                  @click="openBinnacle(item)"
                >
                  <v-icon size="18"> mdi-calendar-month </v-icon>
                </button>
              </template>
              <span>Bitacora</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Dialogo para crear y editar -->
    <v-dialog v-model="dialog" width="500" max-width="500" persistent>
      <v-card>
        <v-card-title>
          {{ dialogTitle }}
          <v-spacer />
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-if="!see"
                  ref="photo"
                  label="Foto del producto"
                  show-size
                  counter
                  truncate-length="20"
                  accept="image/*"
                  :rules="rulesPhoto"
                  @change="selectPhoto"
                ></v-file-input>
              </v-col>

              <!-- <v-img
                v-if="photo && see"
                max-height="300"
                max-width="400"
                :src="photo"
              ></v-img> -->

              <v-col cols="12">
                <v-text-field
                  label="Nombre del producto"
                  v-model="name"
                  :rules="rulesName"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Descripcion del producto"
                  v-model="description"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-form ref="formPrice" v-model="formPrice">
            <v-row>
              <v-col cols="12">
                <v-select
                  label="Paises"
                  v-model="countriesSelect"
                  :items="countries"
                  multiple
                  chips
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="allCountries"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="
                            countriesSelect.length > 0 ? 'indigo darken-4' : ''
                          "
                        >
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Select All </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 3">
                      <span>{{ item.text }}</span>
                    </v-chip>
                    <span v-if="index === 3" class="grey--text text-caption">
                      (+{{ countriesSelect.length - 3 }}
                      {{ countriesSelect.length == 4 ? `Pais` : `Paises` }})
                    </span>
                  </template>
                </v-select>
              </v-col>

              <v-col>
                <v-list>
                  <v-list-item v-for="(country, key) in prices" :key="key">
                    <v-list-item-content>
                      <v-text-field :key="key" v-model="prices[key].price" :prefix="`${country.text}: `" />
                    </v-list-item-content>
                    <v-list-item-action
                      ><v-icon @click="closePrice(key, country)"
                        >mdi-close</v-icon
                      ></v-list-item-action
                    >
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            :loading="loadingForm"
            color="primary"
            dark
            v-if="!see"
            @click="dialogRegister ? register() : edit()"
            >{{ dialogBtnTitle }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para la bitacora -->
    <!-- <binnacle :width="1000">
      <template slot="title">{{ titleBinnacle }}</template>
    </binnacle> -->
  </v-container>
</template>

<script>
import bus from "../../events/bus";
import { mixinForm } from "../../mixins";
import ModuleBar from "../../components/General/ModuleBar";

export default {
  components: {
    ModuleBar,
  },

  mixins: [mixinForm],

  data() {
    return {
      id: "",
      photo: null,
      countryId: [],
      name: "",
      price: 0,
      description: "",
      dialog: false,
      dialogTitle: "",
      dialogBtnTitle: "",
      dialogRegister: true,
      dialogBinnacle: false,
      loadingData: false,
      search: "",
      titleBinnacle: "",
      see: false,
      allCountry: false,
      prices: [],
      formPrice: false,
      countriesSelect: [],
      priceCountry:[],
      headers: [
        {
          text: "Foto",
          value: "url_photo",
        },
        {
          text: "Nombre",
          value: "name",
        },

        {
          text: "Estado",
          value: "enabled",
          aling: "center",
        },
        {
          text: "Registrado",
          value: "created",
        },
        {
          text: "Editado",
          value: "updated",
        },
        {
          text: "Acciones",
          value: "actions",
          aling: "center",
          sortable: false,
        },
      ],

      rulesName: [
        (name) =>
          (name && name.length >= 3) || "Debe ingresar el nombre del pais",
      ],
      rulesPhoto: [
        (logo) => !logo || logo.size < 500000 || "La foto supera los 500 kb",
      ],
      rulesCountry: [
        (country) => (country && country != "") || "Debe seleccionar el pais ",
      ],
      rulesPrice: [
        (price) =>
          (price && price != "") || "Debe ingresar el precio del producto",
        (price) => (price && !isNaN(price)) || "El precio debe ser numerico",
      ],
    };
  },
  

  mounted() {
    this.loadingData = true;
    this.$store
      .dispatch("loadProducts")
      .then(() => {
        this.loadingData = false;
      })
      .catch(() => {
        this.loadingData = false;
      });
  },

  watch: {
    countriesSelect: function (array) {
      if (array.length > 0) {
        array.forEach((elem) => {
          let country = this.countries.find((c) => c.value == elem);
          let indexC = this.countries.findIndex((c) => c.value == elem);
          let index = this.prices.findIndex((p) => p.value == country.value);
          if (index == -1) {
            this.countries[indexC].disabled = true;
            this.prices.push(country);
          }
        });
      }
    },
  },

  computed: {
    products() {
      return this.$store.getters.getProducts;
    },

    countries() {
      return this.$store.getters.getCountrySelect;
    },

    likesAllCountries() {
      return this.countriesSelect.length === this.countries.length;
    },

    likesSomeCountries() {
      return this.countriesSelect.length > 0 && !this.likesAllCountries;
    },

    icon() {
      if (this.likesAllCountries) return "mdi-close-box";
      if (this.likesSomeCountries) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  methods: {
    register() {
      this.loadingForm = true;
      if (this.valid) {
          // let data = {
          //   photo: this.photo,
          //   country_id: this.countryId,
          //   name: this.name,
          //   description: this.description,
          //   price: this.price,
          // };

          // console.log(data);

          // this.$store
          //   .dispatch("registerProduct", data)
          //   .then(() => {
          //     this.loadingForm = false;
          //     this.$refs.form.reset();
          //   })
          //   .catch(() => {
          //     this.loadingForm = false;
          //     this.$refs.form.reset();
          //   });
        
      } else {
        this.loadingForm = false;
        this.$refs.form.validate();
      }
    },

    edit() {
      this.loadingForm = true;
      if (this.valid) {
        const payload = new FormData();
        payload.append("photo", this.photo);
        payload.append("country_id", this.countryId);
        payload.append("name", this.name);
        payload.append("description", this.description);
        payload.append("price", this.price);
        payload.append("id", this.id);

        this.$store
          .dispatch("editProduct", payload)
          .then(() => {
            this.dialog = false;
            this.loadingForm = false;
            this.$refs.form.reset();
          })
          .catch(() => {
            this.dialog = false;
            this.loadingForm = false;
            this.$refs.form.reset();
          });
      } else {
        this.loadingForm = false;
        this.$refs.form.validate();
      }
    },

    changeStatus(item) {
      this.$confirm(
        `Desea ${item.enabled ? `deshabilitar` : `habilitar`} el producto: ${
          item.name
        } ?`
      ).then((response) => {
        if (response) {
          this.$store
            .dispatch("changeStatusProduct", item.id)
            .then(() => {
              this.loadingForm = false;
            })
            .catch(() => {
              this.loadingForm = false;
            });
        }
      });
    },

    openDialog(item) {
      this.see = false;
      this.dialog = true;
      if (item == null) {
        this.dialogTitle = "Registrar Producto";
        this.dialogBtnTitle = "Registrar";
        this.dialogRegister = true;
      } else {
        this.dialogTitle = "Editar Producto";
        this.dialogBtnTitle = "Editar";
        this.dialogRegister = false;
        this.name = item.name;
        this.id = item.id;
        this.description = item.description;
        this.countryId = item.country.id;
        this.price = item.price;
      }
    },

    openDialogSee(item) {
      this.dialog = true;
      this.see = true;
      this.dialogTitle = "Ver Producto";
      this.dialogRegister = false;
      this.name = item.name;
      this.id = item.id;
      this.description = item.description;
      this.countryId = item.country.id;
      this.price = item.price;
      this.photo = item.photo;
    },

    closeDialog() {
      this.$refs.form.reset();
      this.loadingForm = false;
      this.dialog = false;
    },

    openBinnacle(item) {
      this.titleBinnacle = `Bitacora del pais: ${item.name}`;
      item.table = "countries";
      bus.$emit("binnacle", item);
    },

    closeBinacle() {
      this.dialogBinnacle = false;
      this.loadingForm - false;
    },

    selectPhoto(File) {
      this.photo = File;
    },

    addPrice() {
      this.countryId.forEach((elem) => {
        let country = this.countries.find((c) => c.value == elem);
        let exist = this.prices.findIndex((p) => p.value == elem);
        if (exist == -1) {
          let data = {
            country: country.text,
            price: this.price,
            value: country.value,
          };
          country.disabled = true;
          this.prices.push(data);
        }
      });
    },

    closePrice(index, price) {
      let countryIndex = this.countries.findIndex(
        (c) => c.value == price.value
      );
      this.countries[countryIndex].disabled = false;
      this.countriesSelect.splice(index, 1);
      this.prices.splice(index, 1);
    },

    allCountries() {
      this.$nextTick(() => {
        if (this.likesAllCountries) {
          this.countries.forEach((elem) => {
            elem.disabled = false;
          });
          this.countriesSelect = [];
          this.prices = [];
        } else {
          let aux = this.countries.slice();
          for (let key in aux) {
            let index = this.prices.findIndex((p) => p.value == aux[key].value);
            if (index == -1) this.countriesSelect.push(aux[key].value);
          }
        }
      });
    },
  },
};
</script>

<style>
.users {
  font-size: 12px;
}
</style>
