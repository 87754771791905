<template>
  <v-dialog v-model="dialog" width="800">
    <v-card max-height="600">
      <v-card-title
        >Licencia
        <v-spacer />
        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon> </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row v-if="loadingData">
          <v-col cols="12" class="d-flex justify-center">
            <v-progress-circular
              indeterminate
              color="primary"
              width="2"
              size="60"
              class="mt-5"
            />
          </v-col>
        </v-row>
        <v-simple-table height="500" v-if="!loadingData">
          <tbody v-if="license">
            <tr>
              <td style="width: 25%"><b>Culqui</b></td>
              <td style="width: 20%">
                {{ license.culqui_id ? license.culqui_id : "" }}
              </td>
              <td style="width: 55%" class="font-italic">
                Token con que se sincroniza la licencia y culqui
              </td>
            </tr>
            <tr>
              <td><b>Nombre</b></td>
              <td>{{ license.name ? license.name : "" }}</td>
              <td class="font-italic">Nombre de la licencia</td>
            </tr>
            <tr>
              <td><b>Monto</b></td>
              <td>
                {{
                  license.currency
                    ? `${license.amount} ${license.currency.value}`
                    : ""
                }}
              </td>
              <td class="font-italic">
                Monto que se debe cancelar por la licencia
              </td>
            </tr>
            <tr>
              <td><b>Ciclo</b></td>
              <td>
                {{
                  license.cycle
                    ? `${license.cycle_amount} (${license.cycle.name})`
                    : ""
                }}
              </td>
              <td class="font-italic">
                Tiempo del ciclo para el proximo vencimiento
              </td>
            </tr>
            <tr>
              <td><b>Cantidad de clientes</b></td>
              <td>
                {{ license.quantity_clients ? license.quantity_clients : "" }}
              </td>
              <td class="font-italic">
                Limite pemitido para registrar cliente (-1 -> sin limites)
              </td>
            </tr>
            <tr>
              <td><b>Cantidad de planes</b></td>
              <td>
                {{ license.quantity_plans ? license.quantity_plans : "" }}
              </td>
              <td class="font-italic">
                Limite permitido para registrar planes (-1 -> sin limites)
              </td>
            </tr>
            <tr>
              <td><b>Cantidad de clases</b></td>
              <td>
                {{ license.quantity_clases ? license.quantity_clases : "" }}
              </td>
              <td class="font-italic">
                Limite permitido para registrar clases (-1 -> sin limites)
              </td>
            </tr>
            <tr>
              <td><b>Cantidad de productos</b></td>
              <td>
                {{ license.quantity_products ? license.quantity_products : "" }}
              </td>
              <td class="font-italic">
                Limite permitido para registrar productos (-1 -> sin limites)
              </td>
            </tr>
            <tr>
              <td><b>Cantidad de servicios</b></td>
              <td>
                {{ license.quantity_services ? license.quantity_services : "" }}
              </td>
              <td class="font-italic">
                Limite permitido para servicios planes (-1 -> sin limites)
              </td>
            </tr>
            <tr>
              <td><b>Cantidad de usuarios</b></td>
              <td>
                {{ license.quantity_users ? license.quantity_users : "" }}
              </td>
              <td class="font-italic">
                Limite permitido para registrar usuarios (-1 -> sin limites)
              </td>
            </tr>
            <tr>
              <td><b>Backup</b></td>
              <td>{{ license.backup ? "Si" : "No" }}</td>
              <td class="font-italic">
                Indica si la licencia contara con servicio de backup
              </td>
            </tr>
            <tr>
              <td><b>Android</b></td>
              <td>{{ license.android ? "Si" : "No" }}</td>
              <td class="font-italic">
                Indica si la licencia tiene soporte para la plataforma android
              </td>
            </tr>
            <tr>
              <td><b>IOS</b></td>
              <td>{{ license.ios ? "Si" : "No" }}</td>
              <td class="font-italic">
                Indica si la licencia tiene soporte para la plataforma IOS
              </td>
            </tr>
            <tr>
              <td><b>Notificaiones</b></td>
              <td>{{ license.notifications ? "Si" : "No" }}</td>
              <td class="font-italic">
                Indica si la licencia contara con notificaciones whatsapp
                (pagos, nuevos clientes, resumen, etc...)
              </td>
            </tr>
            <tr>
              <td><b>Soporte</b></td>
              <td>{{ license.support ? "Si" : "No" }}</td>
              <td class="font-italic">
                Indica si la licencia contara con soporte tecnico
              </td>
            </tr>
            <tr>
              <td><b>Tiempo de notificaion</b></td>
              <td>{{ `${license.time_notification} dias antes de vencer` }}</td>
              <td class="font-italic">
                Tiempo en que se empieza a notificar proximo vencimiento a la
                empresa
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import bus from "../../events/bus";

export default {
  data() {
    return {
      licenseId: 0,
      dialog: false,
      license: null,
      loadingData: true,
    };
  },

  beforeMount() {
    bus.$on("openLicense", (license) => {
      this.dialog = true;
      this.licenseId = license.licenceId;
      this.findLicense();
    });
  },

  methods: {
    findLicense() {
      this.loadingData = true;
      setTimeout(() => {
        this.$store
          .dispatch("showLicense", this.licenseId)
          .then((res) => {            
            this.license = res;
            this.loadingData = false;            
          })
          .catch(() => {
            this.loadingData = true;
          });
      }, 500);
    },
  },
};
</script>

<style></style>
