import axios from "axios";

const store = {

    state: {
        provinces: [],
        provinceSelect: [],
    },

    getters: {
        getProvinces(state) {
            return state.provinces;
        },

        getProvinceSelect(state) {
            return state.provinceSelect;
        }
    },

    mutations: {
        loadProvinces(state, provinces) {
            state.provinces = provinces;
        },

        addProvince(state, province) {
            state.provinces.push(province);
        },

        editProvince(state, provinceUpdate) {
            let index = state.provinces
                .findIndex((province) =>
                    province.id === provinceUpdate.id
                );
            state.provinces.splice(index, 1, provinceUpdate);
        },

        loadProvinceSelect(state, provinceSelect) {
            state.provinceSelect = provinceSelect;
        }
    },

    actions: {

        loadProvinces({ commit, getters }) {
            return new Promise((resolve, reject) => {
                if (getters.getDepartaments.length > 0)
                    resolve();
                else {
                    axios.get(`/province`).then((response) => {
                        commit('loadProvinces', response.data.provinces)
                        resolve();
                    }).catch((error) => {
                        reject(error);
                    });
                }
            })
        },

        registerProvince({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post(`/province/store`, payload).then((response) => {
                    commit('addProvince', response.data.province);
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            })
        },

        editProvince({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.put(`/province/${payload.id}`, payload).then((response) => {
                    commit('editProvince', response.data.province)
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            })
        },

        changeStatusProvince({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.put(`/province/change-status/${id}`).then((response) => {
                    commit('editProvince', response.data.province)
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            })
        },

        findSelectProvincesByDepartamentId({ commit }, departament_id) {
            return new Promise((resolve, reject) => {
                axios.get(`/province/find/select/${departament_id}`).then((response) => {
                    commit('loadProvinceSelect', response.data)
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            })
        }
    }
}

export default store;