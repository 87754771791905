<template>
  <div class="mt-5">
    <v-card outlined>
      <v-card-title> Informacion cliente potencial </v-card-title>
      <v-card-subtitle>Datos basicos del cliente potencial</v-card-subtitle>
      <v-card-text>
        <v-form ref="formClientP" v-model="valid" :disabled="disabledForms">
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                :items="countries"
                v-model="businessCountry"
                label="Seleccione el pais"
                :rules="rulesBusinessCountry"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="Nombre de la empresa"
                counter="50"
                v-model="businessName"
                :rules="rulesBusinessName"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mixinForm } from "../../mixins";

export default {
  props: {
    click: Number,
    data: Function,
    clear: Number,
    businessP: Object,
    disabledForms: Boolean,
  },
  mixins: [mixinForm],

  data() {
    return {
      businessCountry: "",
      businessName: "",

      rulesBusinessCountry: [
        (country) => (country && country != "") || "Debe seleccionar el pais ",
      ],
      rulesBusinessName: [
        (businessName) =>
          (businessName && businessName.length > 2) ||
          "Debe ingresar el nombre de la empresa",
        (businessName) =>
          (businessName && businessName.length < 50) ||
          "El nombre debe ser menor a 50 caracteres",
      ],
    };
  },

  watch: {
    click: function() {
      if (this.valid) {
        const payload = new FormData();
        payload.append("country_id", this.businessCountry);
        payload.append("name", this.businessName);
        this.data(payload);
      } else {
        this.$refs.formClientP.validate();
      }
    },

    clear: function() {
      this.businessCountry = "";
      this.businessName = "";
      this.$refs.formClientP.resetValidation();
    },

    businessP: function(value) {
      this.businessName = value.name;
      this.businessCountry = value.country_id;
    },
  },

  computed: {
    countries() {
      return this.$store.getters.getCountrySelect;
    },
  },
};
</script>

<style></style>
