import axios from "axios";

const store = {
  state: {
    countries: [],
    country_select: [],
  },

  getters: {
    getCountries(state) {
      return state.countries;
    },

    getCountryId: (state) => (id) => {
      return state.countries.find((country) => country.id === id);
    },

    getCountrySelect(state) {
      return state.country_select;
    },
  },

  mutations: {
    loadCountries(state, countries) {
      state.countries = countries;
    },

    addCountry(state, country) {
      state.countries.push(country);
    },


    editCountry(state, countryUpdate) {
      let index = state.countries.findIndex(
        (country) => country.id === countryUpdate.id
      );
      state.countries.splice(index, 1, countryUpdate);
    },

    loadCountrySelect(state, selects) {
      state.country_select = selects;
    },
  },

  actions: {
    loadCountries({ commit, getters }) {
      return new Promise((resolve, reject) => {
        if (getters.getCountries.length > 0) resolve();
        else {
          axios
            .get(`/country`)
            .then((response) => {
              commit("loadCountries", response.data);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },

    registerCountry({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/country/store`, payload)
          .then((response) => {
            commit("addCountry", response.data.country);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    editCountry({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/country/${payload.id}`, payload)
          .then((response) => {
            commit("editCountry", response.data.country);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    changeStatusCountry({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/country/change-status/${id}`)
          .then((response) => {
            commit("editCountry", response.data.country);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    loadCountrySelect({ commit, getters }) {
      return new Promise((resolve, reject) => {
        if (getters.getCountrySelect.length > 0) resolve();
        else {
          axios
            .get(`/country/find/select`)
            .then((response) => {
              commit("loadCountrySelect", response.data);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
  },
};

export default store;
