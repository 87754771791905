import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from "./router/";
import store from "./store";
import axios from "axios";
import "./interceptors";
import VuetifyConfirm from 'vuetify-confirm'

Vue.use(VuetifyConfirm, { vuetify })

Vue.use(require('vue-moment'));

Vue.config.productionTip = false

Vue.filter('upper', (value) => {
  if (!value) return;
  value = value.toString();
  return value.toUpperCase();
})

new Vue({
  vuetify,
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
