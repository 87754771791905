import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Login from "../views/Login";
import Dashboard from "../views/Dashboard";
import Panel from "../components/Dashboard/Panel";
import BusinessNew from "../views/Business/BusinessNew";
import Countries from "../views/Settings/Countries/Countries";
import Departaments from "../views/Settings/Departaments/Departaments";
import Provinces from "../views/Settings/Provinces/Provinces";
import Districts from "../views/Settings/Districts/Districts";
import Generals from "../views/Settings/Generals/Generals";
import Licenses from "../views/Settings/Licenses/Licenses";
import BusinessSearch from "../views/Business/BusinessSearch";
import PotencialSearch from "../views/Business/PotencialSearch";
import Group from "../views/Business/Group";
import PayUnique from "../views/Payments/PayUnique";
import Payments from "../views/Payments/Payments";
import PaySearch from "../views/Payments/PaySearch";
import Products from "../views/Products/Products";
import PayLicense from "../views/Payments/PayLicense";
import BusinessActivation from "../views/Business/BusinessActivation";
import Banks from "../views/Settings/Banks/Banks.vue";

const routes = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/dashboard",
    component: Dashboard,

    children: [
      {
        path: "",
        component: Panel,
      },
      {
        path: "/business-new",
        component: BusinessNew,
      },
      {
        path: "/business-search",
        component: BusinessSearch,
      },
      {
        path: "/potencial-search",
        component: PotencialSearch,
      },
      {
        path: "/group",
        component: Group,
      },
      {
        path: "/payments",
        component: Payments,
      },
      {
        path: "/payments/pay-unique",
        component: PayUnique,
      },
      {
        path: "/payments/pay-license/:pay_type",
        component: PayLicense,
      },
      {
        path: "/pay-search",
        component: PaySearch,
      },
      {
        path: "/countries",
        component: Countries,
      },
      {
        path: "/departaments",
        component: Departaments,
      },
      {
        path: "/provinces",
        component: Provinces,
      },
      {
        path: "/districts",
        component: Districts,
      },
      {
        path: "/generals",
        component: Generals,
      },
      {
        path: "/licenses",
        component: Licenses,
      },
      {
        path: "/products",
        component: Products,
      },
      {
        path: "/business-activation",
        component: BusinessActivation,
      },
      {
        path: "/banks",
        component: Banks
      }
    ],
  },
];

export default new VueRouter({
  routes: routes,
  mode: "history",
});
