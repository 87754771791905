<template>
  <div class="mt-5">
    <v-card outlined :loading="loadingForm">
      <v-card-title>
        Informacion de la empresa
        <v-spacer />
        <v-tooltip bottom v-if="schema == 'new'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="findClientsP"
              ><v-icon size="30" color="primary"
                >mdi-briefcase-search</v-icon
              ></v-btn
            >
          </template>
          <span>Listar Clientes potenciales</span>
        </v-tooltip>

        <v-tooltip bottom v-if="schema == 'new'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!clientPSelect"
              icon
              v-bind="attrs"
              v-on="on"
              color="red"
              class="ml-2"
              @click="cancelClientP"
              ><v-icon size="30">mdi-delete</v-icon></v-btn
            >
          </template>
          <span>Cancelar cliente potencial</span>
        </v-tooltip>
      </v-card-title>
      <v-card-subtitle> Datos basicos de la empresa</v-card-subtitle>
      <v-divider />

      <v-card-subtitle v-if="schema != 'new'">
        <v-row>
          <v-col cols="6"> CODIGO: {{ business.code }} </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-icon :color="business.expire_info.color"
              >{{ business.expire_info.icon }}
            </v-icon>
            {{ business.expire_info.info }}
          </v-col>
        </v-row>
      </v-card-subtitle>

      <v-divider />
      <v-card-text>
        <v-form
          ref="formInfo"
          v-model="valid"
          :readonly="schema == 'search' ? disabledForms : false"
        >
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                :items="countries"
                v-model="businessCountry"
                @change="findDepartaments(businessCountry)"
                label="Seleccione el pais"
                :rules="schema == 'search' ? [] : rulesBusinessCountry"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                :items="departaments"
                label="Seleccione el departamento"
                :loading="loadingDepartaments"
                @change="findProvinces(businessDepartament)"
                v-model="businessDepartament"
                :rules="schema == 'search' ? [] : rulesBusinessDepartament"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                :items="provinces"
                :loading="loadingProvinces"
                label="Seleccione la provincia"
                @change="findDistricts(businessProvince)"
                v-model="businessProvince"
                :rules="schema == 'search' ? [] : rulesBusinessProvince"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                label="Seleccione el distrito"
                :items="districts"
                v-model="businessDistrict"
                :loading="loadingDistrict"
                :rules="schema == 'search' ? [] : rulesBusinessDistrict"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="ID"
                counter="20"
                v-model="businessId"
                :rules="schema == 'search' ? [] : rulesIdBusiness"
              ></v-text-field>
            </v-col>

            <v-col cols="12" :md="schema == 'new' ? 6 : 5">
              <v-file-input
                ref="businessLogo"
                label="Logo de empresa"
                show-size
                counter
                truncate-length="20"
                accept="image/*"
                :rules="schema == 'search' ? [] : rulesBusinessLogo"
                @change="selectLogo"
              ></v-file-input>
            </v-col>

            <v-col cols="12" md="1" v-if="schema != 'new'">
              <v-btn icon>
                <v-icon
                  :disabled="disabledButtons"
                  @click="openLogo"
                  color="primary"
                  >mdi-image</v-icon
                >
              </v-btn>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="Nombre de la empresa"
                counter="50"
                v-model="businessName"
                :rules="schema == 'search' ? [] : rulesBusinessName"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="Direccion"
                counter="200"
                v-model="businessAddress"
                :rules="schema == 'search' ? [] : rulesBusinessAddress"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                label="Tipo de empresa"
                v-model="businessType"
                :items="businessTypes"
                :rules="schema == 'search' ? [] : rulesBusinessType"
              ></v-select>
            </v-col>

            <v-col cols="10" md="5">
              <v-select
                label="Licencia"
                v-model="businessLicence"
                :items="businessLicences"
                :rules="schema == 'search' ? [] : rulesBusinessLicence"
              ></v-select>
            </v-col>

            <v-col cols="1" md="1">
              <v-btn
                :disabled="
                  schema == 'new' && businessLicence != ''
                    ? false
                    : disabledButtons
                "
                icon
                class="mt-3"
                @click="openShowLicense"
                ><v-icon color="primary">mdi-eye</v-icon></v-btn
              >
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Latitud"
                v-model="businessLat"
                :rules="schema == 'search' ? [] : rulesBusinessLatitud"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Longitud"
                v-model="businessLog"
                :rules="schema == 'search' ? [] : rulesBusinessLongitud"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Email de la empresa"
                v-model="businessEmail"
                :rules="schema == 'search' ? [] : rulesBusinessEmail"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Telefono #1 de la empresa"
                v-model="businessPhone1"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Telefono #2 de la empresa"
                v-model="businessPhone2"
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" :md="schema == 'new' ? '4' : '3'">
              <v-text-field
                label="Cantidad de dispositivos a sincronizar"
                v-model="businessQuantityDevice"
                :rules="
                  schema == 'new' ? rulesBusinessQuantityDevice : rulesEmpty
                "
              ></v-text-field>
            </v-col> -->
            <!-- 
            <v-col cols="1" md="1" v-if="schema != 'new'">
              <v-btn
                :disabled="disabledButtons"
                icon
                class="mt-3"
                @click="openShowCodes"
                ><v-icon color="primary">mdi-eye</v-icon></v-btn
              >
            </v-col> -->
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogClientP" width="1000" persistent>
      <v-card>
        <v-card-title
          >Lista de clientes potenciales
          <v-spacer />
          <v-btn icon @click="dialogClientP = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar cliente potencial"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            :items="businessPotencials"
            :headers="headers"
            :search="search"
            :loading="loadingClienteP"
            hide-default-footer
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.country.name }}</td>
                <td>{{ props.item.name }}</td>
                <td>
                  <p class="users my-1">
                    {{
                      `${props.item.createdBy.names}
                       ${props.item.createdBy.surnames}`
                    }}
                    <br />
                    {{ `${props.item.created_at}` }}
                  </p>
                </td>
                <td>
                  <p class="users my-1">
                    {{
                      `${props.item.updatedBy.names}
                       ${props.item.updatedBy.surnames}`
                    }}
                    <br />
                    {{ `${props.item.updated_at}` }}
                  </p>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <button
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="selectClientP(props.item)"
                      >
                        <v-icon size="18"> mdi-check </v-icon>
                      </button>
                    </template>
                    <span>Seleccionar</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <license />
    <codes />
    <logo />
  </div>
</template>

<script>
import { mixinForm } from "../../mixins";
import bus from "../../events/bus";
import License from "./License.vue";
import Codes from "./Codes.vue";
import Logo from "../../components/Business/Logo";
import "vue-advanced-cropper/dist/style.css";

export default {
  props: {
    schema: String,
    data: Function,
    click: Number,
    clear: Number,
    business: Object,
    disabledForms: Boolean,
  },

  mixins: [mixinForm],

  components: { License, Codes, Logo },

  beforeMount() {
    this.$store
      .dispatch("findSelectLicence")
      .then(() => {})
      .catch(() => {});
  },

  mounted() {
    if (this.business) this.loadBusiness(this.business);
  },

  data() {
    return {
      url: "",
      id: "",
      businessId: "",
      businessPotencialId: 0,
      businessCountry: "",
      businessDepartament: "",
      businessProvince: "",
      businessDistrict: "",
      businessLicence: "",
      businessType: "",
      businessName: "",
      businesslogo: null,
      businessAddress: "",
      businessLat: "",
      businessLog: "",
      businessQuantityDevice: "",
      businessEmail: "",
      businessPhone1: "",
      businessPhone2: "",
      loadingDepartaments: false,
      loadingProvinces: false,
      loadingDistrict: false,
      dialogClientP: false,
      search: "",
      loadingClienteP: false,
      clientPSelect: false,
      disabledButtons: true,

      headers: [
        {
          text: "Pais",
          value: "country.name",
        },
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Registrado",
          value: "createdBy.names",
        },
        {
          text: "Editado",
          value: "updatedBy.names",
        },
        {
          text: "Acciones",
          aling: "center",
          sortable: false,
        },
      ],

      rulesBusinessCountry: [
        (country) => (country && country != "") || "Debe seleccionar el pais ",
      ],
      rulesBusinessDepartament: [
        (departament) =>
          (departament && departament != "") ||
          "Debe seleccionar el departamento",
      ],
      rulesBusinessProvince: [
        (province) =>
          (province && province != "") || "Debe seleccionar la provincia",
      ],
      rulesBusinessDistrict: [
        (district) =>
          (district && district != "") || "Debe seleccionar el distrito",
      ],
      rulesBusinessName: [
        (businessName) =>
          (businessName && businessName.length > 2) ||
          "Debe ingresar el nombre de la empresa",
        (businessName) =>
          (businessName && businessName.length < 50) ||
          "El nombre debe ser menor a 50 caracteres",
      ],
      rulesBusinessLogo: [
        (logo) => !logo || logo.size < 500000 || "El logo supera los 500 kb",
      ],
      rulesIdBusiness: [
        (businessId) =>
          !businessId ||
          businessId.length < 20 ||
          "El ID debe ser menor a 20 caracteres",
      ],
      rulesBusinessAddress: [
        (businessAddress) =>
          (businessAddress && businessAddress.length > 3) ||
          "Ingrese la direccion de la empresa",
        (businessAddress) =>
          (businessAddress && businessAddress.length < 200) ||
          "La direccion debe ser menos de 200 caracteres",
      ],
      rulesBusinessLicence: [
        (businessLicence) =>
          (businessLicence && businessLicence != "") ||
          "Debe seleccionar la licencia",
      ],
      rulesBusinessType: [
        (businessType) =>
          (businessType && businessType != "") ||
          "Debe seleccionar el tipo de empresa",
      ],
      rulesBusinessLatitud: [
        (businessLatitud) =>
          !businessLatitud ||
          !isNaN(businessLatitud) ||
          "Debe ser un valor numerico",
      ],
      rulesBusinessLongitud: [
        (businessLogintud) =>
          !businessLogintud ||
          !isNaN(businessLogintud) ||
          "Debe ser un valor numerico",
      ],
      rulesBusinessEmail: [
        (businessEmail) =>
          !businessEmail ||
          this.validEmail(businessEmail) ||
          "Debe ingresar un email valido ",
      ],
      rulesBusinessQuantityDevice: [
        (businessDeviceQuantity) =>
          (businessDeviceQuantity && !isNaN(businessDeviceQuantity)) ||
          "La cantidad de dispositivos debe ser numerico",
        (businessDeviceQuantity) =>
          (businessDeviceQuantity && businessDeviceQuantity >= 1) ||
          "La cantidad de dispositivos debe se minimo 1",
        (businessDeviceQuantity) =>
          (businessDeviceQuantity && this.validWhole(businessDeviceQuantity)) ||
          "La cantidad de dispositivos debe ser un numero entero",
      ],
      rulesEmpty: [],
    };
  },

  watch: {

    businessName(value){
      this.businessName = value.toUpperCase();
    },

    businessId(value){
      this.businessId = value.toUpperCase();
    },

    click: function () {
      if (this.valid) {
        const payload = new FormData();
        payload.append("businessPotencialId", this.businessPotencialId);
        payload.append("id", this.id);
        payload.append("logo", this.businesslogo);
        payload.append("type_id", this.businessType);
        payload.append("license_id", this.businessLicence);
        payload.append(
          "_id",
          this.businessId ? this.businessId.toUpperCase() : ""
        );
        payload.append("name", this.businessName.toUpperCase());
        payload.append("district_id", this.businessDistrict);
        payload.append("address", this.businessAddress.toUpperCase());
        payload.append("lat", this.businessLat);
        payload.append("log", this.businessLog);
        payload.append("email", this.businessEmail);
        payload.append("phone_1", this.businessPhone1);
        payload.append("phone_2", this.businessPhone2);
        this.data(payload);
      } else {
        this.$refs.formInfo.validate();
      }
    },

    clear: function () {
      this.businessId = "";
      this.businessCountry = "";
      this.businessDepartament = "";
      this.businessProvince = "";
      this.businessDistrict = "";
      this.businessLicence = "";
      this.businessType = "";
      this.businessName = "";
      this.businesslogo = null;
      this.businessAddress = "";
      this.businessLat = "";
      this.businessLog = "";
      this.businessQuantityDevice = "";
      this.businessEmail = "";
      this.businessPhone1 = "";
      this.businessPhone2 = "";
      this.$refs.businessLogo.reset();
      this.$refs.formInfo.resetValidation();
      if (this.businessPotencialId != 0) {
        this.clientPSelect = false;
        this.businessPotencialId = 0;
      }
    },
  },

  computed: {
    countries() {
      return this.$store.getters.getCountrySelect;
    },

    departaments() {
      return this.$store.getters.getDepartamentSelect;
    },

    provinces() {
      return this.$store.getters.getProvinceSelect;
    },

    districts() {
      return this.$store.getters.getDistrictSelect;
    },

    businessTypes() {
      return this.$store.getters.getSetupGeneralSelectByKey(6);
    },

    businessLicences() {
      return this.$store.getters.getLicenseSelect;
    },

    businessPotencials() {
      return this.$store.getters.getBusinessPotencials;
    },
  },

  methods: {
    loadBusiness(business) {
      this.disabledButtonEdit = false;
      this.disabledButtons = false;
      this.id = business.id;
      this.businessId = business._id;
      this.businessAddress = business.address;
      this.businessName = business.name;
      this.businessLicence = business.license.id;
      this.businessCountry = business.district.province.departament.country_id;
      this.findDepartaments(this.businessCountry);
      this.businessDepartament = business.district.province.departament_id;
      this.findProvinces(this.businessDepartament);
      this.businessProvince = business.district.province_id;
      this.findDistricts(this.businessProvince);
      this.businessDistrict = business.district_id;
      this.businessType = business.type_id;
      this.businessLat = business.lat ? business.lat : "";
      this.businessLog = business.log ? business.log : "";
      this.businessEmail = business.email ? business.email : "";
      this.businessPhone1 = business.phone_1 ? business.phone_1 : "";
      this.businessPhone2 = business.phone_2 ? business.phone_2 : "";
    },

    selectLogo(File) {
      this.businesslogo = File;
    },

    onFileChange(file) {
      //const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },

    openShowLicense() {
      bus.$emit("openLicense", { licenceId: this.businessLicence });
    },

    findClientsP() {
      this.dialogClientP = true;
      this.loadingClienteP = true;
      this.$store
        .dispatch("loadBusinessPotencial")
        .then(() => {
          this.loadingClienteP = false;
        })
        .catch(() => {});
    },

    cancelClientP() {
      this.$confirm("Desea cancelar la seleccion del cliente potencial?").then(
        (response) => {
          if (response) {
            this.clientPSelect = false;
            this.businessPotencialId = 0;
            this.resetFormClientP();
          }
        }
      );
    },

    selectClientP(item) {
      this.clientPSelect = true;
      this.dialogClientP = false;
      this.businessName = item.name;
      this.businessCountry = item.country_id;
      this.findDepartaments(this.businessCountry);
      this.businessPotencialId = item.id;
      if (item.contacts) {
        this.contacts = item.contacts;
      }
    },

    findDepartaments(country) {
      this.loadingDepartament = true;
      this.$store
        .dispatch("findSelectDepartamentsByCountryId", country)
        .then(() => {
          this.loadingDepartament = false;
        })
        .catch(() => {});
    },

    findProvinces(departament) {
      this.loadingProvinces = true;
      this.$store
        .dispatch("findSelectProvincesByDepartamentId", departament)
        .then(() => {
          this.loadingProvinces = false;
        })
        .catch(() => {});
    },

    findDistricts(province) {
      this.loadingDistrict = true;
      this.$store
        .dispatch("findSelectDistrictsByProvinceId", province)
        .then(() => {
          this.loadingDistrict = false;
        })
        .catch(() => {});
    },

    resetFormClientP() {
      this.businessName = "";
      this.businessCountry = "";
      this.$refs.formInfo.resetValidation();
    },

    openLogo() {
      bus.$emit("openLogo", {
        businessId: this.id,
        isEdit: !this.disabledForms,
      });
    },

    openShowCodes() {
      bus.$emit("openCodes", {
        businessId: this.id,
      });
    },
  },
};
</script>

<style></style>
