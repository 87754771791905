<template>
  <v-container>
    <module-bar
      :title="`NUEVO ${payType == 'pay' ? 'COBRO' : 'PAGO'} DE LICENCIA`"
    >
      <template slot="controls">
        <complete-business
          :onlyBusiness="1"
          :getBusiness="getBusiness"
          :clear="clearAutocomplete"
          class="mb-2"
          @setBusiness="setBusiness"
        />
      </template>
    </module-bar>
    <v-row v-if="loadingBusiness" class="d-flex justify-center mt-4">
      <v-progress-circular width="2" size="48" indeterminate color="primary" />
    </v-row>
    <v-card outlined class="mt-2" v-if="business">
      <v-card-text v-if="business">
        <v-list>
          <v-list-item>
            <v-list-item-avatar size="100">
              <v-img :src="business ? business.logo : photoAvatar" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ business ? business.name : "" }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-icon>mdi-calendar-outline</v-icon>
                {{ business ? business.expire_date : "" }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-icon>mdi-currency-usd</v-icon>
                {{ business ? business.license.name : "" }}
                {{
                  business
                    ? `${business.license.amount_format}
                        ${business.license.currency.name}`
                    : ""
                }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-icon
                  :color="`${business ? business.expire_info.color : ''}`"
                  >{{ business ? business.expire_info.icon : "" }}</v-icon
                >
                <span
                  :style="`color: ${
                    business ? business.expire_info.color : ''
                  }`"
                >
                  {{ business ? business.expire_info.info : "" }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-form v-model="form">
          <v-row v-if="payType == 'payment'">
            <v-col cols="12" md="6">
              <v-select
                label="Estado del pago"
                :items="statusItems"
                item-text="label"
                item-value="value"
                v-model="status"
              />
            </v-col>
            <v-col cols="12" md="6" v-if="status == 2">
              <v-text-field
                prepend-icon="mdi-check"
                label="Codigo de autorizacion"
                @click:prepend="openDialogAuto"
              />
            </v-col>
            <v-col cols="12" md="6" v-if="status == 1">
              <v-select
                :items="payTypes"
                item-value="value"
                item-text="text"
                v-model="payTypeItem"
                label="Tipo de pago"
              />
            </v-col>
            <v-col cols="12" md="6" v-if="status == 1">
              <v-select label="Banco" :items="banks" />
            </v-col>
            <v-col cols="12" md="6" v-if="status == 1">
              <v-select label="Cuenta" />
            </v-col>
            <v-col cols="12" md="6" v-if="status == 1">
              <v-file-input label="Foto del pago" />
            </v-col>

            <v-col cols="6" v-if="status == 2">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dateReminder"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateReminder"
                    label="Recordatorio"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateReminder" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(dateReminder)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6" v-if="status == 1 || status == 2">
              <v-text-field counter label="Observacion" v-model="observation" />
            </v-col>
          </v-row>
          <v-row v-if="status == 1 || status == 2 || payType == 'pay'">
            <v-col cols="12">
              <v-btn icon color="primary" @click="openNotification">
                <v-icon>mdi-email-send-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="disabledProcess" color="primary" dark @click="process"
          >Procesar</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- dialogo notification -->
    <v-dialog
      width="500"
      max-width="500"
      persistent
      v-model="dialogNotification"
    >
      <v-card outlined>
        <v-card-title>
          <v-spacer />
          <v-btn icon @click="closeNotification"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <notification
            :business="business"
            :getContacts="getContacts"
            :finalized="finalized"
            @close="closeNotification"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dialogo autorizacion -->
    <v-dialog persistent width="500" max-width="500" v-model="dialogAuto">
      <v-card>
        <v-card-title>
          <v-spacer />
          <v-btn icon @click="closeDialogAuto"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row v-if="loadingConfirming">
            <v-col class="d-flex justify-center mb-4"
              ><v-progress-circular indeterminate width="2" color="primary"
            /></v-col>
          </v-row>
          <v-list>
            <v-list-item-group>
              <template v-for="(user, key) in usersConfirming">
                <v-list-item
                  :key="key"
                  color="primary"
                  @click="selectedUserConfirming(user)"
                >
                  <v-list-item-avatar size="60">
                    <v-img
                      :src="user.photo_url ? user.photo_url : photoAvatar"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ `${user.names} ${user.surnames}` }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ user.email }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="key < usersConfirming.length - 1"
                  :key="`div_${key}`"
                />
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :loading="loadingSendCode"
            dark
            @click="sendCodeUserConfirming"
            >Enviar codigo</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CompleteBusiness from "../../components/General/CompleteBusiness.vue";
import ModuleBar from "../../components/General/ModuleBar.vue";
import { formatAmountCulqi } from "../../util";
import photoAvatar from "../../assets/avatar_default.jpg";
import Notification from "../../components/Business/Notification.vue";
import bus from "../../events/bus";

export default {
  components: {
    ModuleBar,
    CompleteBusiness,
    Notification,
  },

  data() {
    return {
      dateReminder: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      menu: false,
      payType: this.$route.params.pay_type,
      payTypeItem: 0,
      clearAutocomplete: false,
      business: null,
      loadingBusiness: false,
      disabledProcess: true,
      photoAvatar: photoAvatar,
      dialogNotification: false,
      self: [],
      others: [],
      observation: "",
      notifications: [],
      dialogAuto: false,
      form: false,
      status: 0,
      statusItems: [
        { label: "PAGADO", value: 1 },
        { label: "SIN PAGAR", value: 2 },
      ],
      usersConfirming: null,
      loadingConfirming: false,
      loadingSendCode: false,
      finalized: false,
      banks: [],
    };
  },

  beforeMount() {
    if (
      this.payType == undefined ||
      (this.payType != "pay" && this.payType != "payment")
    ) {
      this.$router.push("/payments");
    }

    this.$store.dispatch("selectBankAction").then((res) => {
      this.banks = res;
    });
  },

  computed: {
    payTypes() {
      let setupGeneral = this.$store.getters.getSetupGeneralSelect;
      let payTypes = setupGeneral.filter((pay) => pay.key == 9);
      return payTypes;
    },
  },

  watch: {
    business: function () {
      if (this.business) {
        this.disabledProcess = false;
      } else {
        this.disabledProcess = true;
      }
    },
  },

  methods: {
    getBusiness(business) {
      this.clearAutocomplete = false;
      this.finalized = false;
      this.loadingBusiness = true;
      setTimeout(() => {
        this.business = business;
        this.business.license.amount_format = formatAmountCulqi(
          this.business.license.amount
        );
        this.loadingBusiness = false;
      }, 500);
    },

    setBusiness() {
      this.business = null;
      this.status = 0;
    },

    process() {
      if (this.business) {
        if (this.self.length > 0 || this.others.length > 0) {
          let payload = {
            business_id: this.business.id,
            potencial_id: 0,
            type: "license",
            observation: this.observation,
            status: "pending",
            condition: "final",
            license: {
              name: this.business.license.name,
              currency: this.business.license.currency.name,
              amount: this.business.license.amount,
              period: this.business.license.period,
              acount_period: this.business.license.acount_period,
              expire_date_old: this.business.expire_date,
            },
            notifications: this.notifications,
          };

          //agreagr la info que falta para pago completo icluye el tipo pay o payment

          this.$store
            .dispatch("registerPaymentLicense", payload)
            .then(() => {
              this.clearAutocomplete = true;
              this.finalized = true;
            })
            .catch(() => {});
        } else {
          bus.$emit("snackbar", {
            color: "red",
            text: "Debe agregar al menos un destinatario",
          });
        }
      }
    },

    openNotification() {
      this.dialogNotification = true;
    },

    closeNotification() {
      this.dialogNotification = false;
    },

    getContacts(self, others) {
      this.self = [];
      this.others = [];
      this.notifications = [];
      this.self = self;
      this.others = others;

      if (this.self.length > 0) {
        for (let key in this.self) {
          let aux = this.self[key].split("_");
          let item = {
            is_unique: 0,
            type: aux[1],
            value: aux[2],
            option: "self",
            table: aux[0],
            full_name: aux[3],
          };
          this.notifications.push(item);
        }
      }

      if (this.others.length > 0) {
        for (let key in this.others) {
          let item = {
            is_unique: 0,
            type: this.others[key].type,
            value: `${this.others[key].value}${this.others[key].value}`,
            option: "other",
            table: "contacts",
            full_name: this.others[key].fullName,
          };
          this.notifications.push(item);
        }
      }
    },

    openDialogAuto() {
      this.dialogAuto = true;
      this.loadingConfirming = true;
      setTimeout(() => {
        this.$store
          .dispatch("findUserConfirming")
          .then((res) => {
            this.usersConfirming = res;
            this.loadingConfirming = false;
          })
          .catch((err) => {
            this.loadingConfirming = false;
            console.log(err);
          });
      }, 500);
    },

    closeDialogAuto() {
      this.dialogAuto = false;
    },

    selectedUserConfirming(user) {
      let user_aux = this.usersConfirming.find((u) => u.id == user.id);
      if (user_aux.selected) {
        user_aux.selected = false;
      } else {
        this.usersConfirming.forEach((item) => {
          if (item.id != user_aux.id) {
            item.selected = false;
          }
        });
        user_aux.selected = true;
      }
    },

    sendCodeUserConfirming() {
      this.loadingSendCode = true;
      setTimeout(() => {
        let userSelect = this.usersConfirming.find((u) => u.selected == true);
        if (userSelect) {
          let payload = {
            user_id: userSelect.id,
            business_id: this.business.id,
            country: this.business.district.province.departament.country.name,
            business_code: this.business.code_short,
            business_name: this.business.name,
            email: userSelect.email,
            full_name: `${userSelect.names} ${userSelect.surnames}`,
          };

          this.$store
            .dispatch("sendCodeUserConfirming", payload)
            .then(() => {
              this.loadingSendCode = false;
              this.dialogAuto = false;
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          bus.$emit("snackbar", {
            color: "red",
            text: "Debe seleccionar al menos un usuario",
          });
        }
      }, 500);
    },

    cancelUserConfirmingSeleted() {},
  },
};
</script>

<style scoped></style>
