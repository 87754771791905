import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import auth from "./auth";
import countries from "./countries";
import binnacles from "./binnacles";
import departaments from "./departaments";
import provinces from "./provinces";
import districts from "./districts";
import setup_general from "./setup_general";
import license from "./license";
import business from "./business";
import codes from "./codes";
import businessPotencial from "./businessPotencial";
import payments from "./payments";
import products from "./products";
import users from "./users";
import banks from "./banks";

export default new Vuex.Store({
  modules: [
    auth,
    countries,
    binnacles,
    departaments,
    provinces,
    districts,
    setup_general,
    license,
    business,
    codes,
    businessPotencial,
    payments,
    products,
    users,
    banks
  ],
});
