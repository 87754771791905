<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <module-bar title="DASHBOARD">
          <div slot="buttons">
            <v-btn icon @click="exportPdf">
              <v-icon>mdi-file-pdf</v-icon>
            </v-btn>
            <v-btn icon @click="exportXls">
              <v-icon>mdi-file-excel</v-icon>
            </v-btn>
            <v-btn icon @click="print">
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </div>
        </module-bar>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-card color="#385F73" dark loading height="200">
          <v-card-title class="headline"> Empresas Registradas </v-card-title>
          <v-card-subtitle>
            Es la cantidad de empresas o clientes
          </v-card-subtitle>
          <v-card-text style="font-size: 3em" class="text-center my-3">
            21
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text> ver detalles </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-card color="#1F7087" dark height="200" loading="true">
          <v-card-title class="headline"> Empresas deudoras </v-card-title>
          <v-card-subtitle>Empresas sin pagar la licencia.</v-card-subtitle>
          <v-card-text style="font-size: 3em" class="text-center my-3"
            >2</v-card-text
          >
          <v-card-actions>
            <v-spacer />
            <v-btn text> ver detalles </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <v-card color="#952175" dark height="200">
          <v-card-title class="headline"> Clientes potenciales </v-card-title>
          <v-card-subtitle>Posibles clientes de xcore.</v-card-subtitle>
          <v-card-text style="font-size: 3em" class="text-center my-3"
            >9</v-card-text
          >
          <v-card-actions>
            <v-spacer />
            <v-btn text> ver detalles </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ModuleBar from "../General/ModuleBar";

export default {
  components: { ModuleBar },

  methods: {
    exportPdf() {
      alert("PDF");
    },

    exportXls() {
      alert("XLS");
    },

    print() {
      alert("PRINT");
    },
  },
};
</script>

<style>
</style>