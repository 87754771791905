import axios from 'axios';

const store = {

    state: {
        banks: [],
    },

    getters: {
        getBanks(state) {
            return state.banks;
        }
    },

    mutations: {
        loadBanksMutation(state, banks) {
            state.banks = banks;
        },

        addBanksMutation(state, bank) {
            state.banks.push(bank);
        },

        updateBanksMutation(state, bank) {
            let index = state.banks.findIndex(b => b.id == bank.id);
            state.banks.splice(index, 1, bank);
        },
    },

    actions: {
        loadBanksAction({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/bank/`)
                    .then((response) => {
                        commit("loadBanksMutation", response.data.banks);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        registerBankAction({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/bank/store/`, payload)
                    .then((response) => {
                        commit("addBanksMutation", response.data.bank);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        editBankAction({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/bank/update/${payload.id}`, payload)
                    .then((response) => {
                        commit("updateBanksMutation", response.data.bank);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        changeStatusBankAction({ commit }, bankId) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/bank/change-status/${bankId}`)
                    .then((response) => {
                        commit("updateBanksMutation", response.data.bank);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        selectBankAction(Context, paymentTypeId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/bank/select/${paymentTypeId}`)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        findBankAccountAction(Context, bankId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/bank/find/account/bank/${bankId}`)
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }

    },
}

export default store;