import axios from "axios";

const store = {
  state: {
    products: [],
    productSelect: [],
  },

  getters: {
    getProducts(state) {
      return state.products;
    },

    getProductSelect(state) {
      return state.productSelect;
    },
  },

  mutations: {
    loadProducts(state, products) {
      state.products = products;
    },

    addProduct(state, product) {
      state.provinces.push(product);
    },

    editProduct(state, productUpdate) {
      let index = state.products.findIndex(
        (product) => product.id == productUpdate.id
      );

      state.products.splice(index, 1, productUpdate);
    },

    loadProductSelect(state, productSelect) {
      state.productSelect = productSelect;
    },
  },

  actions: {
    loadProducts({ commit, getters }) {
      return new Promise((resolve, reject) => {
        if (getters.getProducts.length > 0) resolve();
        else {
          axios
            .get(`/product`)
            .then((response) => {
              commit("loadProducts", response.data);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },

    registerProduct({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/product/store`, payload)
          .then((response) => {
            commit("addProduct", response.data.product);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    editProduct({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/product/${payload.get("id")}`, payload)
          .then((response) => {
            commit("editProduct", response.data._product);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default store;
