<template>
  <v-app>
    <router-view></router-view>
    <snackbar />
  </v-app>
</template>

<script>
import Snackbar from "./components/General/Snackbar.vue";

export default {
  components: {
    Snackbar,
  },
};
</script>
