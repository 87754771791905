<template>
  <v-app-bar elevation="0" dense app dark color="primary" clipped-left>
    <v-app-bar-nav-icon @click="changeDrawer" />
    <v-toolbar-title>XCORE</v-toolbar-title>
    <v-spacer />
    <v-chip v-show="loadSettings" color="primary">
      <v-progress-circular
        size="16"
        width="2"
        indeterminate
        class="mr-1"
      ></v-progress-circular>
      Loading Settings</v-chip
    >
    <v-btn icon>
      <v-icon>mdi-bell</v-icon>
    </v-btn>
    <v-btn icon class="mr-3">
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn>
    <v-menu offset-y :close-on-content-click="false" nudge-width="200">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar color="primary" size="40" v-bind="attrs" v-on="on">
          <v-img src="https://randomuser.me/api/portraits/men/77.jpg" />
        </v-avatar>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img
                src="https://randomuser.me/api/portraits/men/77.jpg"
                alt="John"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Gerwin Rangel</v-list-item-title>
              <v-list-item-subtitle
                >g.rangel1987@gmail.com</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item to="/profile">
            <v-list-item-content>
              <v-list-item-title>Mi perfil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-content>
              <v-list-item-title>Cerrar sesion</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import bus from "../../events/bus";

export default {
  data() {
    return {
      loadSettings: false,
    };
  },

  beforeCreate() {
    bus.$on("loadSettings", (value) => {
      this.loadSettings = value;
    });
  },

  methods: {
    changeDrawer: () => {
      bus.$emit("changeDrawer");
    },

    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
  },
};
</script>

<style></style>
