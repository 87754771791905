<template>
  <v-container>
    <v-row>
      <!-- Header -->
      <v-col cols="12">
        <module-bar title="DISTRITOS" icon="mdi-cog">
          <template slot="search">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              style="max-width: 350px"
            ></v-text-field>
          </template>

          <template slot="buttons">
            <v-btn color="primary" text @click="openDialog()"> agregar </v-btn>
          </template>
        </module-bar>
      </v-col>

      <!-- Datatable -->
      <v-col cols="12">
        <v-data-table
          :items="districts"
          :loading="loadingData"
          :headers="headers"
          :search="search"
          mobile-breakpoint="800"
          dense
        >
          <template v-slot:[`item.province`]="{ item }">
            {{ item.province.name }}
          </template>

          <template v-slot:[`item.departament`]="{ item }">
            {{ item.province.departament.name }}
          </template>

          <template v-slot:[`item.country`]="{ item }">
            {{ item.province.departament.country.name }}
          </template>

          <template v-slot:[`item.enabled`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="item.enabled ? 'green' : 'red'"
                  size="16"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-circle
                </v-icon>
              </template>
              <span>{{ item.enabled ? "Habilitado" : "Deshabilitado" }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.createdBy`]="{ item }">
            <p class="users my-1">
              {{ `${item.createdBy.names}` }}
              <br />
              {{ `${item.created_at}` }}
            </p>
          </template>

          <template v-slot:[`item.updatedBy`]="{ item }">
            <p class="users my-1">
              {{ `${item.updatedBy.names}` }}
              <br />
              {{ `${item.updated_at}` }}
            </p>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button icon v-bind="attrs" v-on="on" @click="openDialog(item)">
                  <v-icon size="18">mdi-pencil</v-icon>
                </button>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="ml-2"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="changeStatus(item)"
                >
                  <v-icon size="18">
                    {{ item.enabled ? "mdi-delete" : "mdi-check" }}
                  </v-icon>
                </button>
              </template>
              <span>{{ item.enabled ? "Deshabilitar" : "Habilitar" }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Dialogo para crear y editar -->
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title>
          {{ dialog_title }}          
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-select
              label="Pais"
              :items="countries"
              v-model="country"
              :rules="rulesCountry"
              @change="findDepartaments(country)"
            />
            <v-select
              label="Departamento"
              :items="departaments"
              v-model="departament"
              :rules="rulesDepartament"
              :loading="loadingDepartaments"
              @change="findProvinces(departament)"
            />
            <v-select
              label="Provincia"
              :items="provinces"
              v-model="province"
              :rules="rulesProvince"
              :loading="loadingProvince"
            />
            <v-text-field
              label="Nombre del distrito"
              :value="name | upper"
              @input="(value) => (name = value)"
              :rules="rulesName"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeDialog">cancelar</v-btn>
          <v-divider vertical class="ml-3 mr-3" />
          <v-btn
            :loading="loadingForm"
            color="primary"
            dark
            @click="dialog_register ? register() : edit()"
            >{{ dialog_btn_title }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para la bitacora -->
    <binnacle :width="1000">
      <template slot="title">{{ titleBinnacle }}</template>
    </binnacle>
  </v-container>
</template>

<script>
import bus from "../../../events/bus";
import { mixinForm } from "../../../mixins";
import ModuleBar from "../../../components/General/ModuleBar";
import Binnacle from "../../../components/General/Binnacle.vue";

export default {
  components: {
    ModuleBar,
    Binnacle,
  },

  mixins: [mixinForm],

  data() {
    return {
      id: "",
      name: "",
      country: "",
      departament: "",
      province: "",
      dialog: false,
      dialog_title: "",
      dialog_btn_title: "",
      dialog_register: true,
      dialogBinnacle: false,
      loadingData: false,
      search: "",
      titleBinnacle: "",
      loadingDepartaments: false,
      loadingProvince: false,
      headers: [
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Provincia",
          value: "province",
        },
        {
          text: "Departamento",
          value: "departament",
        },
        {
          text: "Pais",
          value: "country",
        },
        {
          text: "Estado",
          value: "enabled",
          aling: "center",
        },
        {
          text: "Registrado",
          value: "createdBy",
        },
        {
          text: "Editado",
          value: "updatedBy",
        },
        {
          text: "Acciones",
          aling: "center",
          value: "actions",
          sortable: false,
        },
      ],

      rulesCountry: [
        (country) => (country && country != "") || "Debe seleccionar el pais",
      ],
      rulesDepartament: [
        (departament) =>
          (departament && departament != "") ||
          `Debe seleccionar el departamento`,
      ],
      rulesProvince: [
        (province) =>
          (province && province != "") || `Debe seleccionar la provincia`,
      ],
      rulesName: [
        (name) =>
          (name && name.length >= 3) || "Debe ingresar el nombre del distrito",
      ],
    };
  },

  mounted() {
    this.loadingData = true;
    this.$store
      .dispatch("loadDistricts")
      .then(() => {
        this.loadingData = false;
      })
      .catch(() => {
        this.loadingData = false;
      });
  },

  computed: {
    countries() {
      return this.$store.getters.getCountrySelect;
    },

    departaments() {
      return this.$store.getters.getDepartamentSelect;
    },

    provinces() {
      return this.$store.getters.getProvinceSelect;
    },

    districts() {
      return this.$store.getters.getDistricts;
    },
  },

  watch: {
    name(value) {
      if (value) this.name = value.toUpperCase();
    },
  },

  methods: {
    register() {
      this.loadingForm = true;
      if (this.valid) {
        this.$store
          .dispatch("registerDistrict", {
            province_id: this.province,
            name: this.name.toUpperCase(),
          })
          .then(() => {
            this.loadingForm = false;
            this.$refs.form.reset();
          })
          .catch(() => {
            this.loadingForm = false;
          });
      } else {
        this.loadingForm = false;
        this.$refs.form.validate();
      }
    },

    edit() {
      this.loadingForm = true;
      if (this.valid) {
        this.$store
          .dispatch("editDistrict", {
            id: this.id,
            province_id: this.province,
            name: this.name.toUpperCase(),
          })
          .then(() => {
            this.dialog = false;
            this.loadingForm = false;
            this.$refs.form.reset();
          })
          .catch(() => {
            this.loadingForm = false;
          });
      } else {
        this.loadingForm = false;
        this.$refs.form.validate();
      }
    },

    changeStatus(item) {
      this.$confirm(
        `Desea ${item.enabled ? `deshabilitar` : `habilitar`} el distrito: ${
          item.name
        } ?`,
        {
          buttonTrueText: "Si",
          title: "Confirmacion",
          color: "primary",
          icon: "mdi-crosshairs-question",
        }
      ).then((response) => {
        if (response) {
          this.$store
            .dispatch("changeStatusDistrict", this.id)
            .then(() => {
              this.loadingForm = false;
            })
            .catch(() => {
              this.loadingForm = false;
            });
        }
      });
    },

    findDepartaments(country_id) {
      this.loadingDepartaments = true;
      this.departament = "";
      this.province = "";
      this.$store.commit("loadDepartamentSelect", []);
      this.$store.commit("loadProvinceSelect", []);
      if (this.$refs.form) this.$refs.form.resetValidation();
      this.$store
        .dispatch("findSelectDepartamentsByCountryId", country_id)
        .then(() => {
          this.loadingDepartaments = false;
        })
        .catch(() => {
          this.loadingDepartaments = false;
        });
    },

    findProvinces(departament_id) {
      this.loadingProvince = true;
      this.province = "";
      this.$store.commit("loadProvinceSelect", []);
      if (this.$refs.form) this.$refs.form.resetValidation();
      this.$store
        .dispatch("findSelectProvincesByDepartamentId", departament_id)
        .then(() => {
          this.loadingProvince = false;
        })
        .catch(() => {
          this.loadingProvince = false;
        });
    },

    openDialog(item) {
      this.dialog = true;
      this.departament = 0;

      if (item == null) {
        this.dialog_title = "Registrar Distrito";
        this.dialog_btn_title = "Registrar";
        this.dialog_register = true;
        if (this.$refs.form) this.$refs.form.resetValidation();
      } else {
        this.dialog_title = "Editar Distrito";
        this.dialog_btn_title = "Editar";
        this.dialog_register = false;
        this.name = item.name;
        this.country = item.province.departament.country.id;
        this.findDepartaments(this.country);
        this.departament = item.province.departament.id;
        this.findProvinces(this.departament);
        this.province = item.province.id;
        this.id = item.id;
      }
    },

    closeDialog() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$store.commit("loadDepartamentSelect", []);
      this.loadingForm = false;
      this.dialog = false;
    },

    openBinnacle(item) {
      this.titleBinnacle = `Bitacora del distrito: ${item.name}`;
      item.table = "districts";
      bus.$emit("binnacle", item);
    },

    closeBinacle() {
      this.dialogBinnacle = false;
      this.loadingForm - false;
    },
  },
};
</script>

<style>
.users {
  font-size: 12px;
}
</style>