<template>
  <v-container>
    <module-bar :title="!businessP ? 'NUEVA EMPRESA' : 'CLIENTE POTENCIAL'">
      <template slot="menu">
        <v-menu offset-y :close-on-content-click="false" nudge-width="200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"
              ><v-icon>mdi-menu</v-icon></v-btn
            >
          </template>
          <v-card>
            <v-card-text>
              <v-switch
                v-model="businessP"
                class="ml-2"
                label="Cliente potencial"
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </module-bar>
    <info
      schema="new"
      v-if="!businessP"
      :data="getInfo"
      :click="info_click"
      :clear="clear"
    />
    <potencial
      v-if="businessP"
      :data="getPotencial"
      :click="potencial_click"
      :clear="clear"
    />
    <devices
      schema="new"
      v-if="!businessP"
      :data="getDevices"
      :click="device_click"
      :clear="clear"
    />
    <contacts
      schema="new"
      :businessP="businessP"
      :data="getContacts"
      :click="contact_click"
      :clear="clear"
    />
    <owners
      schema="new"
      v-if="!businessP"
      :data="getOwners"
      :click="owner_click"
      :clear="clear"
    />

    <v-card elevation="0" class="mt-5">
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!businessP"
          color="primary"
          @click="registerBusiness()"
          :loading="loadingForm"
        >
          Guardar empresa
        </v-btn>

        <v-btn
          v-if="businessP"
          color="primary"
          @click="registerBusinessP()"
          :loading="loadingForm"
        >
          Guardar cliente potencial
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mixinForm } from "../../mixins";
import ModuleBar from "../../components/General/ModuleBar.vue";
import Info from "../../components/Business/Info";
import Devices from "../../components/Business/Devices.vue";
import Potencial from "../../components/Business/Potencial.vue";
import Contacts from "../../components/Business/Contacts.vue";
import Owners from "../../components/Business/Owners.vue";

export default {
  mixins: [mixinForm],

  data() {
    return {
      businessP: false,
      data: null,
      devices: [],
      contacts: [],
      owners: [],
      info_click: 0,
      device_click: 0,
      contact_click: 0,
      potencial_click: 0,
      owner_click: 0,
      clear: 0,
      is_potencial: false,
    };
  },

  components: {
    ModuleBar,
    Info,
    Devices,
    Potencial,
    Contacts,
    Owners,
  },

  methods: {
    registerBusiness(action) {
      if (action) {
        this.data.append("devices", JSON.stringify(this.devices));
        this.data.append("contacts", JSON.stringify(this.contacts));
        this.data.append("owners", JSON.stringify(this.owners));
        this.loadingForm = true;
        this.$store
          .dispatch("registerBusiness", this.data)
          .then(() => {
            this.clear++;
            this.loadingForm = false;
          })
          .catch(() => {
            this.loadingForm = false;
          });
      } else {
        this.info_click++;
      }
    },

    registerBusinessP(action) {
      if (action) {
        this.loadingForm = true;
        this.data.append("contacts", JSON.stringify(this.contacts));
        this.$store
          .dispatch("registerBusinessPotencial", this.data)
          .then(() => {
            this.loadingForm = false;
            this.businessP = false;
            this.is_potencial = false;
            this.clear++;
          })
          .catch(() => {
            this.loadingForm = false;
          });
      } else {
        this.potencial_click++;
      }
    },

    getInfo(info) {
      this.data = info;
      this.device_click++;
    },

    getDevices(devices) {
      this.devices = devices.devices;
      this.contact_click++;
    },

    getContacts(contacts) {
      this.contacts = contacts.contacts;
      if (this.is_potencial) this.registerBusinessP(true);
      else if (!this.is_potencial) this.owner_click++;
    },

    getOwners(owners) {
      this.owners = owners.owners;
      this.registerBusiness(true);
    },

    getPotencial(potencial) {
      this.is_potencial = true;
      this.data = potencial;
      this.contact_click++;
    },
  },
};
</script>

<style>
.users {
  font-size: 12px;
}
</style>
