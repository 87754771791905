<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    chips
    clearable
    hide-details
    hide-selected
    item-text="name"
    item-value="code"
    label="Nombre de empresa..."
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Puede realizar la busqueda con nombre de la empresa
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-chip
        v-bind="attr"
        :input-value="selected"
        color="indigo"
        class="white--text"
        v-on="on"
      >
        <span
          v-text="
            `${item.name} / 
            ${item.country}  
            ${
              item.type == 'Business'
                ? `/ ${item.departament}/BUSINESS`
                : `/POTENCIAL`
            }`
          "
        ></span>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar
        color="indigo"
        class="headline font-weight-light white--text"
      >
        {{ item.name.charAt(0) }}
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.name"></v-list-item-title>
        <v-list-item-subtitle
          v-text="
            `${item.country} 
             ${item.type == 'Business' ? `/ ${item.departament}` : ''} 
            `
          "
        />
        <v-list-item-subtitle v-text="`${item.type}`" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    getBusiness: Function,
    getPotencial: Function,
    clear: Boolean,
    onlyBusiness: Number,
  },


  data() {
    return {
      isLoading: false,
      search: null,
      model: null,
    };
  },

  watch: {
    model(value) {      
      if (value != null) {
        let itemSelected = this.items.find((item) => item.code == value);
        let itemSelectId = itemSelected.id;
        let itemSelectType = itemSelected.type;
        this.$emit("setLoading", true);

        setTimeout(() => {
          if (itemSelectType == "Business") {
            this.$store
              .dispatch("findBusinessById", itemSelectId)
              .then((response) => {
                this.getBusiness(response);
                this.$emit("setLoading", false);
              })
              .catch((error) => {
                console.log(error);
                this.$emit("setLoading", false);
              });
          } else if (itemSelectType == "Potencial") {
            this.$store
              .dispatch("findBusinessPotencialById", itemSelectId)
              .then((response) => {
                this.getPotencial(response);
                this.$emit("setLoading", false);
              })
              .catch((error) => {
                console.log(error);
                this.$emit("setLoading", false);
              });
          }
        }, 500);
      } else {
        this.$emit("setBusiness");
        this.$emit("setPotencial");
      }
    },

    search(value) {      
      if (value == null) {
        this.isLoading = false;
        return;
      } else {
        if (value.length > 2) {
          this.isLoading = true;
          this.$store
            .dispatch("businessAutocomplete", {
              value: value,
              onlyBusiness: this.onlyBusiness,
            })
            .then(() => {
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
        }
      }
    },

    clear: function (value) {
      if (value) {
        this.model = null;
        this.search = null;
        this.$emit("setBusiness");
        this.$emit("setPotencial");        
      }
    },
  },

  computed: {
    items() {
      return this.$store.getters.getBusinessAutocomplete;
    },
  },
};
</script>

<style></style>
