<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    chips
    clearable
    hide-details
    hide-selected
    :item-text="itemText"
    item-value="id"
    label="Nombre del cliente potencial..."
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Puede realizar la busqueda con nombre del cliente potencial
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-chip
        v-bind="attr"
        :input-value="selected"
        color="indigo"
        class="white--text"
        v-on="on"
      >
        <span
          v-text="
            `${item.name} / 
                   ${item.country}`
          "
        ></span>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar
        color="indigo"
        class="headline font-weight-light white--text"
      >
        {{ item.name.charAt(0) }}
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.name"></v-list-item-title>
        <v-list-item-subtitle v-text="`${item.country}`"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: { getPotencial: Function },

  data() {
    return {
      itemText: ["name"],
      isLoading: false,
      search: null,
      model: null,
    };
  },

  watch: {
    search(value) {
      if (value == null) {
        this.isLoading = false;
        return;
      } else {
        if (value.length > 2) {
          this.isLoading = true;
          this.$store
            .dispatch("businessPotencialAutocomplete", value)
            .then(() => {
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
        }
      }
    },

    model(value) {
      if (value != null) {
        this.$store
          .dispatch("findBusinessPotencialById", value)
          .then((response) => {
            this.getPotencial(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },

  computed: {
    items() {
      return this.$store.getters.getBusinessPotencialAutocomplete;
    },
  },
};
</script>

<style></style>
