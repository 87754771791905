import axios from "axios";

const store = {
    state: {
        binnacles: [],
    },

    getters: {
        getBinnacles(state) {
            return state.binnacles;
        }
    },

    mutations: {
        loadBinnacles(state, binnacles) {
            state.binnacles = binnacles;
        }
    },

    actions: {
        findBinnacle({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.get(`/binnacle/find/${payload.id}/${payload.table}`).then((response) => {
                    commit('loadBinnacles', response.data.binnacle);
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            });
        }
    }
}

export default store;