<template>
  <div>
    <v-toolbar class="mb-4" outlined elevation="0">

      <h4><v-icon>{{icon}}</v-icon> {{ title }}</h4>
      <v-spacer/>
      <slot name="buttons"/>
      <slot name="menu"/>
    </v-toolbar>
    
    <div class="d-flex justify-end">
      <slot name="search" />
    </div>
    
    <v-card class="d-flex flex-row-reverse" flat tile>
      <slot name="controls" />
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["title","icon"],
};
</script>

<style></style>
