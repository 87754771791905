const mixinForm = {
  data() {
    return {
      error: "",
      valid: false,
      validContact: false,
      validOwner: false,
      validDevices: false,
      loadingForm: false,
      loadingDialog: false,
      validClientP: false,
      validEdit: false,
      validOther: false,
    };
  },

  methods: {
    validEmail(email) {
      var re = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      return re.test(String(email).toLowerCase());
    },

    validWhole(number) {
      if (number % 1 == 0) {
        return true;
      } else {
        return false;
      }
    },

    validIp(ip) {
      var patronIp = new RegExp(
        "^([0-9]{1,3}).([0-9]{1,3}).([0-9]{1,3}).([0-9]{1,3})$"
      );
      var values;

      if (ip.search(patronIp) !== 0) {
        return false;
      }

      values = ip.split(".");
      return (
        values[0] <= 255 &&
        values[1] <= 255 &&
        values[2] <= 255 &&
        values[3] <= 255
      );
    },

    numberFormat(amount, decimals) {
      if (isNaN(amount) || amount === 0) return 0;

      decimals = decimals || 0;
      amount = amount.toFixed(decimals);

      var amount_parts = amount.split("."),
        regexp = /(\d+)(\d{3})/;

      while (regexp.test(amount_parts[0]))
        amount_parts[0] = amount_parts[0].replace(regexp, "$1" + "," + "$2");

      return amount_parts.join(".");
    },
  },
};

const mixinUser = {
  data() {
    return {
      email: "",
      password: "",

      rulesEmail: [
        (email) => this.validEmail(email) || "Ingrese un email valido",
      ],
      rulesPassword: [
        (password) => password.length >= 8 || "Debe ingresar un password",
      ],
    };
  },
  methods: {
    validEmail(email) {
      var re = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      return re.test(String(email).toLowerCase());
    },
  },
};

const mixinEmail = {
  data() {
    return {
      email: "",
      rulesEmail: [
        (email) => this.validEmail(email) || "Ingrese un email valido",
      ],
    };
  },
  methods: {
    validEmail(email) {
      var re = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      return re.test(String(email).toLowerCase());
    },
  },
};

export { mixinForm, mixinUser, mixinEmail };
