<template>
  <v-container>
    <module-bar :title="'BUSCAR EMPRESA'">
      <template slot="controls">
        <complete-business
          :getBusiness="getBusiness"
          @setBusiness="setBusiness"
          @setLoading="setLoading"
        />
      </template>
      <template slot="menu">
        <v-menu offset-y :close-on-content-click="false" nudge-width="200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"
              ><v-icon>mdi-menu</v-icon></v-btn
            >
          </template>
          <v-card>
            <v-card-text>
              <v-switch
                label="Editar empresa"
                :disabled="disabledButtonEdit"
                @change="switchEdit"
                v-model="switchBusinessEdit"
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </module-bar>

    <v-row v-if="loadingData">
      <v-col cols="12" class="d-flex justify-center">
        <v-progress-circular
          color="primary"
          size="60"
          width="2"
          indeterminate
          class="mt-5"
        />
      </v-col>
    </v-row>

    <info
      v-if="business"
      :schema="schema"
      :business="business"
      :disabledForms="disabledForms"
      :data="getInfo"
      :click="info_click"
      :clear="clear"
    />
    <devices
      v-if="business"
      :schema="schema"
      :business="business"
      :disabledForms="disabledForms"
      :data="getDevices"
      :click="device_click"
      :clear="clear"
    />
    <contacts
      v-if="business"
      :schema="schema"
      :business="business"
      :disabledForms="disabledForms"
      :data="getContacts"
      :click="contact_click"
      :clear="clear"
    />
    <owners
      v-if="business"
      :schema="schema"
      :business="business"
      :disabledForms="disabledForms"
      :data="getOwners"
      :click="owner_click"
      :clear="clear"
    />

    <v-card v-if="loaded" elevation="0" class="mt-5">
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          @click="editBusiness()"
          :loading="loadingForm"
          :disabled="!switchBusinessEdit"
        >
          Editar empresa
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import Devices from "../../components/Business/Devices.vue";
import Info from "../../components/Business/Info.vue";
import ModuleBar from "../../components/General/ModuleBar";
import Contacts from "../../components/Business/Contacts";
import Owners from "../../components/Business/Owners.vue";
import { mixinForm } from "../../mixins";
import CompleteBusiness from "../../components/General/CompleteBusiness.vue";

export default {
  mixins: [mixinForm],

  components: {
    ModuleBar,
    Info,
    Devices,
    Contacts,
    Owners,
    CompleteBusiness,
  },

  data() {
    return {
      schema: "search",
      loadingData: false,
      loaded: false,
      isLoading: false,
      search: null,
      model: null,
      business: null,
      disabledForms: true,
      switchBusinessEdit: false,
      disabledButtonEdit: true,
      data: null,
      info_click: 0,
      device_click: 0,
      contact_click: 0,
      owner_click: 0,
      clear: 0,
      devices: [],
      deletes: [],
      contacts: [],
      owner: [],
    };
  },

  watch: {
    business(value) {
      this.loaded = value ? true : false;
    },
  },

  methods: {
    editBusiness(action) {
      if (action) {
        this.loadingForm = true;
        this.data.append("devices", JSON.stringify(this.devices));
        this.data.append("contacts", JSON.stringify(this.contacts));
        this.data.append("owners", JSON.stringify(this.owners));
        this.data.append("deletes", JSON.stringify(this.deletes));

        this.$store
          .dispatch("editBusiness", this.data)
          .then(() => {
            this.clear++;
            this.loadingForm = false;
          })
          .catch(() => {
            this.loadingForm = false;
          });
      } else {
        this.info_click++;
      }
    },

    switchEdit() {
      this.disabledForms = !this.switchBusinessEdit;
      this.schema = this.switchBusinessEdit ? "edit" : "search";
    },

    getInfo(info) {
      this.data = info;
      this.device_click++;
    },

    getDevices(devices) {
      this.devices = devices.devices;
      this.deletes = devices.deletes;
      this.contact_click++;
    },

    getContacts(contacts) {
      this.contacts = contacts.contacts;
      if (contacts.deletes) {
        for (let key in contacts.deletes)
          this.deletes.push(contacts.deletes[key]);
      }
      this.owner_click++;
    },

    getOwners(owners) {
      this.owners = owners.owners;
      if (owners.deletes) {
        for (let key in owners.deletes) this.deletes.push(owners.deletes[key]);
      }
      this.editBusiness(true);
    },

    getBusiness(business) {
      this.business = business;
      this.loaded = true;
      this.disabledButtonEdit = false;
    },

    setBusiness() {
      this.business = null;
      this.switchBusinessEdit = false;
      this.disabledButtonEdit = true;
    },

    setLoading(value) {
      this.loadingData = value;
    },
  },
};
</script>

<style>
.users {
  font-size: 12px;
}
</style>
