import axios from 'axios';
import config from "../config";

const store = {

    state: {
        user: null,
        token: localStorage.getItem("access_token") || null,
    },

    getters: {
        getUser(state) {
            return state.user;
        }
    },

    mutations: {
        auth(state, data) {
            let user = {
                email: data.user.email,
                names: data.user.names,
                surnames: data.user.surnames,
            };
            state.user = data.user;
            state.token = data.token
            localStorage.setItem("access_token", data.token);
            localStorage.setItem("user", JSON.stringify(user));
        },

        logout(state) {
            state.user = null;
            state.token = null;
            localStorage.removeItem("user");
            localStorage.removeItem("countries");
            localStorage.removeItem("departaments");
            localStorage.removeItem("access_token");
        }

    },

    actions: {
        login({ commit }, credentials) {
            return new Promise((resolve, reject) => {
                axios.post(`${config.url}/auth/login`, {
                    email: credentials.email,
                    password: credentials.password,
                }).then((response) => {
                    commit('auth', response.data);
                    resolve();
                }).catch((error) => {
                    commit('logout')
                    reject(error)
                });
            });
        },

        logout({ commit }) {
            commit('logout');
        }

    },
}

export default store;