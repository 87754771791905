<template>
  <v-navigation-drawer v-model="drawer" clipped app>
    <v-list>
      <v-list-item to="/dashboard" exact>
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dashboard </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group :value="false" prepend-icon="mdi-home-analytics">
        <template v-slot:activator>
          <v-list-item-title>Empresas</v-list-item-title>
        </template>
        <v-list-item to="/business-new">
          <v-list-item-icon>
            <v-icon>mdi-plus-box</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Nueva empresa</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/business-search">
          <v-list-item-icon>
            <v-icon>mdi-magnify</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Buscar empresa</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/potencial-search">
          <v-list-item-icon>
            <v-icon>mdi-folder-search</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Buscar potencial</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/business-activation">
          <v-list-item-icon>
            <v-icon>mdi-buffer</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Activar Empresa</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group :value="false" prepend-icon="mdi-currency-usd">
        <template v-slot:activator>
          <v-list-item-title>Pagos</v-list-item-title>
        </template>
        <v-list-item to="/payments">
          <v-list-item-icon>
            <v-icon>mdi-currency-usd</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Nuevo pago / cobro</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/pay-search">
          <v-list-item-icon>
            <v-icon>mdi-cash-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Buscar pago</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item to="/users">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Usuarios </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/users">
        <v-list-item-icon>
          <v-icon>mdi-file-chart-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Reportes </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/products">
        <v-list-item-icon>
          <v-icon>mdi-google</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Productos </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />
      <v-list-group :value="false" prepend-icon="mdi-cog">
        <template v-slot:activator>
          <v-list-item-title>Configuracion</v-list-item-title>
        </template>

        <v-list-item to="/countries">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Paises</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/departaments">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Departamentos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/provinces">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Provincias</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/districts">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Distritos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/generals">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>General</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/licenses">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Licencias</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

         <v-list-item to="/banks">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Bancos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import bus from "../../events/bus";

export default {
  data() {
    return {
      drawer: true,
    };
  },

  beforeMount() {
    bus.$on("changeDrawer", () => {
      this.drawer = !this.drawer;
    });
  },
};
</script>

<style scoped></style>
