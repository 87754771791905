<template>
  <v-dialog v-model="dialog" width="1000">
    <v-card>
      <v-card-title> Codigos de {{ businessName }} </v-card-title>
      <v-card-subtitle
        >({{ codes.length }})
        {{ codes.length > 1 ? `codigos` : `codigo` }}</v-card-subtitle
      >
      <v-row v-if="loadingData">
        <v-col cols="12" class="d-flex justify-center mt-5">
          <v-progress-circular
            indeterminate
            color="primary"
            width="2"
            size="60"
          />
        </v-col>
      </v-row>

      <v-card-text v-if="!loadingData">
        <v-list>
          <v-item-group v-for="(code, key) in codes" :key="key">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-text="`${code.code}   ${code.is_server ? `- Servidor` : ``}`"
                />
                <v-title-item-subtitle>
                  {{code.sync}}
                </v-title-item-subtitle>                
                <v-list-item-subtitle>
                  Creador
                  {{ code.createdBy.names }}<br />
                  {{ code.created_at }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="code.device" class="mt-3">
                  Dispositivo <br />
                  {{ code.device.name }}<br />
                  {{ code.device.model }}<br />
                  {{ code.device.ip }}<br />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </v-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="closeCodes" text>Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import bus from "../../events/bus";

export default {
  data() {
    return {
      codes: [],
      loadingData: false,
      businessId: 0,
      dialog: false,
      businessName: "",
      expanded: [],
      singleExpand: true,
      loading: true,
      codesHeaders: [
        {
          text: "Codigo",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Sincronizado",
          align: "start",
          sortable: false,
          value: "sync",
        },
        {
          text: "Creador",
          align: "start",
          sortable: false,
          value: "createdBy",
        },

        { text: "", value: "data-table-expand" },
      ],
    };
  },

  beforeMount() {
    bus.$on("openCodes", (business) => {
      this.businessId = business.businessId;
      this.dialog = true;
      this.loadData();
    });
  },

  methods: {
    closeCodes() {
      this.loadingData = false;
      this.dialog = false;
    },

    loadData() {
      this.loadingData = true;
      setTimeout(() => {
        this.$store
          .dispatch("findCodesByBusinessId", this.businessId)
          .then((res) => {
            this.codes = res;
            for (let key in this.codes) {
              this.codes[key]["sync"]
                ? (this.codes[key]["sync"] = "Sinconrizado")
                : (this.codes[key]["sync"] = "Por sincronizar");
            }
            this.loadingData = false;
          })
          .catch(() => {
            this.loadingData = false;
          });
      }, 500);
    },
  },
};
</script>

<style></style>
