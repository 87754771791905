import axios from "axios";

const store = {
  state: {
    businessPotencials: [],
    businessPotencialAutocomplete: [],
  },

  getters: {
    getBusinessPotencials(state) {
      return state.businessPotencials;
    },

    getBusinessPotencialAutocomplete(state) {
      return state.businessPotencialAutocomplete;
    },
  },

  mutations: {
    addBusinessPotencial(state, businessPotencial) {
      state.businessPotencials.push(businessPotencial);
    },

    editBusinessPotencial(state, businesPotencials) {
      let index = state.businessPotencials.findIndex(
        (business) => business.id === businesPotencials.id
      );
      state.businessPotencials.splice(index, 1, businesPotencials);
    },

    loadBusinessPotencial(state, businessPotencials) {
      state.businessPotencials = businessPotencials;
    },

    businessPotencialAutocomplete(state, businessPotencials) {
      state.businessPotencialAutocomplete = businessPotencials;
    },
  },

  actions: {
    registerBusinessPotencial({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/business-potencial/store`, payload)
          .then((response) => {
            commit("addBusinessPotencial", response.data._businessPotencial);
            resolve(response.data_businessPotencial);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    editBusinessPotencial({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/business-potencial/${payload.get("id")}`, payload)
          .then((response) => {
            commit("editBusinessPotencial", response.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    loadBusinessPotencial({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/business-potencial/`)
          .then((response) => {
            commit("loadBusinessPotencial", response.data.businesPotencials);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    businessPotencialAutocomplete({ commit }, value) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/business-potencial/autocomplete/${value}`)
          .then((response) => {
            commit("businessPotencialAutocomplete", response.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    findBusinessPotencialById(Context, businessPotencialId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/business-potencial/${businessPotencialId}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default store;
