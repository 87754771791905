<template>
  <div>
    <v-card class="mb-2" outlined v-if="!aggregate">
      <v-card-text>
        <v-form v-model="form" ref="formPotencial">
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                label="Pais"
                v-model="countryId"
                :items="countries"
                :rules="rulesCountry"
                @change="changeCountry"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="fullName"
                label="Nombre Completo"
                :rules="rulesFullName"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="email" label="Email" :rules="rulesEmail" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="phone"
                :label="itemsPhone[phoneIndex].text"
                :rules="rulesPhone"
                :prefix="prefix"
                :prepend-icon="itemsPhone[phoneIndex].icon"
                @click:prepend="changeIconPhone"
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="businessName"
                label="Nombre Empresa"
                :rules="rulesBusinessName"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" dark @click="addBusinesPotencial"
          >agregar empresa</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-card v-else class="mb-2" outlined>
      <v-card-text class="subtitle-1">
        {{ businessName }} / {{ countryName }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" dark @click="editBusinessPotencial"
          >editar empresa</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mixinEmail } from "../../mixins";

export default {
  mixins: [mixinEmail],

  props: { click: Number, potencial: Function },

  data() {
    return {
      aggregate: false,
      form: false,
      countryId: 0,
      countryName: "",
      fullName: "",
      phone: "",
      businessName: "",
      prefix: "",
      preIcon: "mdi-phone",
      phoneIndex: 0,
      itemsPhone: [
        { text: "Telefono", value: "phone", icon: "mdi-phone" },
        { text: "Whatsapp", value: "whatsapp", icon: "mdi-whatsapp" },
      ],

      rulesCountry: [
        (countryId) => (countryId && countryId != 0) || "Seleccione un pais",
      ],
      rulesFullName: [
        (fullName) =>
          (fullName && fullName.length > 0) || "Ingrese el nombre completo",
      ],

      rulesPhone: [
        (phone) => (phone && phone.length > 0) || "Ingrese el telefono",
      ],

      rulesBusinessName: [
        (businessName) =>
          (businessName && businessName.length > 0) ||
          "Ingrese el nombre de la empresa",
      ],
    };
  },

  watch: {
    click: function() {
      if (!this.form) {
        this.$refs.formPotencial.validate();
      }
    },

    fullName: function(newValue) {
      if (newValue.length > 0) {
        this.fullName = this.fullName.toUpperCase();
      }
    },

    businessName: function(newValue) {
      if (newValue.length > 0) {
        this.businessName = this.businessName.toUpperCase();
      }
    },

    form: function(newValue) {
      if (newValue == false) {
        this.$emit("setPotencial");
      }
    },
  },

  computed: {
    countries() {
      return this.$store.getters.getCountrySelect;
    },
  },

  methods: {
    changeCountry() {
      let country = this.countries.find(
        (country) => country.value == this.countryId
      );
      this.prefix = country.pre;
      this.countryName = country.text;
    },

    changeIconPhone() {
      if (this.phoneIndex == 0) this.phoneIndex = 1;
      else this.phoneIndex = 0;
    },

    addBusinesPotencial() {
      if (this.form) {
        let data = {
          country_id: this.countryId,
          fullName: this.fullName,
          email: this.email,
          phoneType: this.itemsPhone[this.phoneIndex].value,
          phonePrefix: this.prefix,
          phone: this.phone,
          businessName: this.businessName,
        };
        this.potencial(data);
        this.aggregate = true;
      } else {
        this.$refs.formPotencial.validate();
        this.aggregate = false;
        this.$emit("setPotencial");
        this.$emit("setBusiness");
      }
    },

    editBusinessPotencial() {
      this.aggregate = false;
    },
  },
};
</script>

<style></style>
