import axios from "axios";

const store = {

    state: {
        departaments: [],
        departamentSelect: [],
    },

    getters: {
        getDepartaments(state) {
            return state.departaments;
        },

        getDepartamentSelect(state) {
            return state.departamentSelect;
        }
    },

    mutations: {
        loadDepartaments(state, departaments) {
            state.departaments = departaments;
        },

        addDepartament(state, departament) {
            console.log(departament);
            state.departaments.push(departament);
        },

        editDepartament(state, departamentUpdate) {
            let index = state.departaments
                .findIndex((departament) =>
                    departament.id === departamentUpdate.id
                );
            state.departaments.splice(index, 1, departamentUpdate);
        },

        loadDepartamentSelect(state, departamentSelect) {
            state.departamentSelect = departamentSelect
        }
    },

    actions: {
        loadDepartaments({ commit, getters }) {
            return new Promise((resolve, reject) => {
                if (getters.getDepartaments.length > 0)
                    resolve();
                else {
                    axios.get(`/departament`).then((response) => {
                        commit('loadDepartaments', response.data.departaments)
                        resolve();
                    }).catch((error) => {
                        reject(error);
                    });
                }
            })
        },

        registerDepartament({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post(`/departament/store`, payload).then((response) => {
                    commit('addDepartament', response.data.departament);
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            })
        },

        editDepartament({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.put(`/departament/${payload.id}`, payload).then((responde) => {
                    commit('editDepartament', responde.data.departament);
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            })
        },

        changeStatusDepartament({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.put(`/departament/change-status/${id}`).then((response) => {
                    commit('editDepartament', response.data.departament);
                    resolve(reject);
                }).catch((error) => {
                    reject(error);
                });
            })
        },

        findSelectDepartamentsByCountryId({ commit }, country_id) {
            return new Promise((resolve, reject) => {
                axios.get(`/departament/find/select/${country_id}`).then((response) => {
                    commit('loadDepartamentSelect', response.data)
                    resolve();
                }).catch((error) => {
                    reject(error)
                });
            })
        }
    }
}

export default store