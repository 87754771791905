import axios from "axios";

const store = {
  state: {
    users: null,
    usersConfirming: null,
  },

  getters: {},

  mutations: {},

  actions: {
    findUserConfirming() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/find/confirming`)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    sendCodeUserConfirming(Context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`user/send-code/confirming`, payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default store;
