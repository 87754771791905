<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="4000"
    :color="color"
    bottom
    dark
    elevation="1"
  >
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import bus from "../../events/bus";

export default {
  data() {
    return {
      snackbar: false,
      text: "",
      color: "",
    };
  },

  beforeMount() {
    bus.$on("snackbar", (options) => {
      this.color = options.color;
      this.text = options.text;
      this.snackbar = !this.snackbar;
    });
  },
};
</script>

<style></style>
