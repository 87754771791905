import axios from "axios";

const store = {
  state: {
    codes: [],
    codesByBusinessId: [],
  },

  getters: {
    getCodesByBusinessId(state) {
      return state.codesByBusinessId;
    },
  },

  actions: {
    findCodesByBusinessId(Context, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`code/find/business/${businessId}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default store;
