<template>
  <v-container>
    <module-bar title="BUSCAR CLIENTE POTENCIAL">
      <template slot="controls"> 
        <complete-potencial :getPotencial="getPotencial"/>
      </template>
      <template slot="menu">
        <v-menu offset-y :close-on-content-click="false" nudge-width="200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"
              ><v-icon>mdi-menu</v-icon></v-btn
            >
          </template>
          <v-card>
            <v-card-text>
              <v-switch
                label="Editar cliente potencial"
                :disabled="disabledButtonEdit"
                @change="switchEdit"
                v-model="switchBusinessEdit"
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </module-bar>

    <potencial
      :data="getInfo"
      :click="potencial_click"
      :clear="clear"
      :businessP="businessP"
      :disabledForms="disabledForms"
    />

    <contacts
      schema="search"
      :business="businessP"
      :disabledForms="disabledForms"
      :data="getContacts"
      :click="contact_click"
      :clear="clear"
    />

    <v-card elevation="0" class="mt-5">
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="mt-4"
          color="primary"
          @click="editBusinessP()"
          :disabled="!switchBusinessEdit"
          :loading="loadingForm"
          >Editar cliente potencial</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import Potencial from "../../components/Business/Potencial.vue";
import Contacts from "../../components/Business/Contacts.vue";
import ModuleBar from "../../components/General/ModuleBar";
import { mixinForm } from "../../mixins";
import CompletePotencial from '../../components/General/CompletePotencial.vue';

export default {
  components: { ModuleBar, Potencial, Contacts, CompletePotencial },

  mixins: [mixinForm],

  data() {
    return {
      data: null,      
      potencial_click: 0,
      contact_click: 0,
      clear: 0,
      businessP: null,
      disabledForms: true,
      switchBusinessEdit: false,
      disabledButtonEdit: true,
      contacts: [],
      deletes: [],
    };
  },

  methods: {
    editBusinessP(action) {
      if (action) {
        this.loadingForm = true;
        this.data.append("id", this.businessP.id);
        this.data.append("contacts", JSON.stringify(this.contacts));
        this.data.append("deletes", JSON.stringify(this.deletes));
        this.$store
          .dispatch("editBusinessPotencial", this.data)
          .then(() => {
            this.loadingForm = false;
            this.clear++;
          })
          .catch(() => {
            this.loadingForm = false;
          });
      } else {
        this.potencial_click++;
      }
    },

    getPotencial(potencial) {
      this.businessP = potencial;
      this.disabledButtonEdit = false;
    },

    getInfo(potencial) {
      this.data = potencial;
      this.contact_click++;
    },

    getContacts(contacts) {
      this.contacts = contacts.contacts;
      if (contacts.deletes) {
        for (let key in contacts.deletes)
          this.deletes.push(contacts.deletes[key]);
      }
      this.editBusinessP(true);
    },

    switchEdit() {
      this.disabledForms = !this.switchBusinessEdit;
    },
  },
};
</script>

<style></style>
