<template>
  <div class="">
    <app-bar></app-bar>
    <menu-master></menu-master>
    <v-main>
      <v-layout>
        <v-flex xs12>
          <router-view />
        </v-flex>
      </v-layout>
    </v-main>
    <v-overlay :value="overlay" opacity="0.4"> </v-overlay>
  </div>
</template>

<script>
import AppBar from "../components/General/AppBar";
import MenuMaster from "../components/General/Menu";
import bus from "../events/bus";

export default {
  components: {
    AppBar,
    MenuMaster,
  },

  data() {
    return {
      overlay: false,
    };
  },

  mounted() {
    this.overlay = true;
    bus.$emit("loadSettings", true);
    setTimeout(() => {
      this.$store
        .dispatch("loadCountrySelect") //cargo los paises en formato select
        .then(() => {
          this.$store
            .dispatch("loadSetupGeneralSelect") //cargo la configuracion general en formato select
            .then(() => {
              this.$store
                .dispatch("loadSetupGeneralKeySelect") //cargo los keys de la configuracion general en formato select
                .then(() => {
                  bus.$emit("loadSettings", false);
                  this.overlay = false;
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }, 2000);
  },
};
</script>

<style></style>
