<template>
  <v-container>
    <v-row>
      <!-- Header -->
      <v-col cols="12">
        <module-bar title="LICENCIAS" icon="mdi-cog">
          <template slot="search">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              style="max-width: 350px"
            ></v-text-field>
          </template>
          <template slot="buttons">
            <v-btn color="primary" text @click="openDialog()"> agregar </v-btn>
          </template>
        </module-bar>
      </v-col>

      <!-- Datatable -->
      <v-col cols="12">
        <v-data-table
          :items="licenses"
          :loading="loadingData"
          :headers="headers"
          :search="search"
          mobile-breakpoint="800"
          dense
        >
          <template v-slot:[`item.amount`]="{ item }">
            {{ formatAmount(item.amount) }} {{ item.currency.value }}
          </template>

          <template v-slot:[`item.cycle`]="{ item }">
            {{ item.cycle.name }} / {{ item.cycle_amount }}
          </template>

          <template v-slot:[`item.enabled`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="item.enabled ? 'green' : 'red'"
                  size="16"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-circle
                </v-icon>
              </template>
              <span>{{ item.enabled ? "Habilitado" : "Deshabilitado" }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.createdBy`]="{ item }">
            <p class="users my-1">
              {{ `${item.createdBy.names}` }}
              <br />
              {{ `${item.created_at}` }}
            </p>
          </template>

          <template v-slot:[`item.updatedBy`]="{ item }">
            <p class="users my-1">
              {{ `${item.updatedBy.names}` }}
              <br />
              {{ `${item.updated_at}` }}
            </p>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button icon v-bind="attrs" v-on="on" @click="openDialog(item)">
                  <v-icon size="18">mdi-pencil</v-icon>
                </button>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="ml-2"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="changeStatus(item)"
                >
                  <v-icon size="18">
                    {{ item.enabled ? "mdi-delete" : "mdi-check" }}
                  </v-icon>
                </button>
              </template>
              <span>{{ item.enabled ? "Deshabilitar" : "Habilitar" }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Dialogo para crear y editar -->
    <v-dialog v-model="dialog" width="800" persistent>
      <v-card :loading="loadingDialog">
        <v-card-title>
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text>
          <v-row v-if="loadingForm">
            <v-col cols="12" class="d-flex justify-center mt-6 mb-6">
              <v-progress-circular
                indeterminate
                size="60"
                width="2"
                color="primary"
              />
            </v-col>
          </v-row>
          <v-form v-if="!loadingForm" ref="form" v-model="valid">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Nombre"
                  v-model="name"
                  :rules="rulesName"
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field label="Descripcion" v-model="description" />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  label="Monto"
                  v-model="amount"
                  :rules="rulesAmout"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  label="Moneda"
                  :items="currencies"
                  v-model="currency"
                  :rules="rulesCurrency"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  label="Ciclo"
                  :items="cycles"
                  v-model="cycle"
                  :rules="rulesCycle"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  label="Monto de ciclo"
                  v-model="cycleAmount"
                  :rules="rulesCycleAmount"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-switch
                  label="Multi sede"
                  v-model="multiSede"
                  @change="changeMultiSede(multiSede)"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  label="Cantidad de sedes"
                  v-model="quantitySedes"
                  :rules="rulesQuantitySedes"
                  :readonly="!multiSede"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  label="Cantidad de clientes"
                  v-model="quantityClients"
                  hint="-1 no tiene limites"
                  :rules="rulesQuantityClients"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  label="Cantidad de planes"
                  v-model="quantityPlans"
                  hint="-1 no tiene limites"
                  :rules="rulesQuantityPlans"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  label="Cantidad de clases"
                  v-model="quantityClases"
                  hint="-1 no tiene limites"
                  :rules="rulesQuantityClases"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  label="Cantidad de productos"
                  v-model="quantityProducts"
                  hint="-1 no tiene limites"
                  :rules="rulesQuantityProducts"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  label="Cantidad de servicios"
                  v-model="quantityServices"
                  hint="-1 no tiene limites"
                  :rules="rulesQuantityServices"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  label="Cantidad de usuarios"
                  v-model="quantityUsers"
                  hint="-1 no tiene limites"
                  :rules="rulesQantityUser"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-switch label="Backup" v-model="backup" />
              </v-col>

              <v-col cols="12" md="3">
                <v-switch label="Android" v-model="android" />
              </v-col>

              <v-col cols="12" md="3">
                <v-switch label="IOS" v-model="ios" />
              </v-col>

              <v-col cols="12" md="3">
                <v-switch label="Notificaciones" v-model="notifications" />
              </v-col>

              <v-col cols="12" md="3">
                <v-switch label="Soporte" v-model="support" />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  label="Tiempo de notificacion"
                  hint="Dias para notificar al cliente"
                  v-model="timeNotification"
                  :rules="rulesNotification"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="!loadingForm">
          <v-spacer />
          <v-btn text @click="closeDialog">cancelar</v-btn>
          <v-divider vertical class="ml-3 mr-3" />
          <v-btn
            :loading="loadingForm"
            :disabled="loadingDialog"
            color="primary"
            dark
            @click="dialogRegister ? register() : edit()"
            >{{ dialogBtnTitle }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para la bitacora -->
    <binnacle :width="1000">
      <template slot="title">{{ titleBinnacle }}</template>
    </binnacle>

    <!-- dialogo de carga -->
    <v-dialog v-model="dialogLoaging" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo de culqi -->
    <v-dialog v-model="dialogculqi" persistent width="800">
      <v-card :loading="loadingDialog">
        <v-card-title>
          Datos de Culqi
          <v-spacer />
          <v-btn icon @click="closeDialogculqi">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row v-if="schema == 'new'">
            <v-col cols="12" md="12"> LICENCIA: {{ name }} </v-col>
            <v-col cols="12" md="12">
              MONTO: {{ `${formatAmount(amount)} ${currency}` }}
            </v-col>
            <v-col cols="12" md="12">
              CICLO: {{ `${cycle} / ${cycle_amount}` }}
            </v-col>
            <v-col cols="12" md="12" class="text-center">
              {{ culqiInfo }}
            </v-col>
          </v-row>
          <v-row v-if="schema == 'search'">
            <v-col cols="12" md="12">
              ID DE CULQI: {{ culqiJson != null ? culqiJson.id : "" }}
            </v-col>
            <v-col cols="12" md="12">
              LICENCIA: {{ culqiJson != null ? culqiJson.name : "" }}
            </v-col>
            <v-col cols="12" md="12">
              MONTO:
              {{
                culqiJson != null
                  ? `${formatAmount(culqiJson.amount)} ${
                      culqiJson.currency_code
                    }`
                  : ""
              }}
            </v-col>
            <v-col cols="12" md="12">
              CICLO:
              {{
                culqiJson != null
                  ? `${culqiJson.interval} / ${culqiJson.interval_count}`
                  : ""
              }}
            </v-col>
            <v-col cols="12" md="12">
              SUBSCRITOS:
              {{ culqiJson != null ? culqiJson.total_subscriptions : "" }}
            </v-col>
            <v-col cols="12" md="12">
              DIAS TRIAL: {{ culqiJson != null ? culqiJson.trial_days : "" }}
            </v-col>
            <v-col cols="12" md="12">
              FECHA CREACION:
              {{ culqiJson != null ? formatDate(culqiJson.creation_date) : "" }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            :loading="loadingForm"
            :disabled="disabled"
            :color="schema == 'new' ? `primary` : `red`"
            dark
            @click="schema == 'new' ? registerculqi() : deleteculqi()"
            >{{ schema == "new" ? "Sincronizar" : "Eliminar culqi" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import bus from "../../../events/bus";
import { mixinForm } from "../../../mixins";
import ModuleBar from "../../../components/General/ModuleBar";
import Binnacle from "../../../components/General/Binnacle.vue";

export default {
  components: {
    ModuleBar,
    Binnacle,
  },

  mixins: [mixinForm],

  data() {
    return {
      loadingForm: false,
      dialogLoaging: false,
      id: "",
      schema: "",
      dialog: false,
      dialogculqi: false,
      titleculqi: "",
      dialogTitle: "",
      dialogBtnTitle: "",
      dialogRegister: true,
      dialogBinnacle: false,
      loadingData: false,
      search: "",
      titleBinnacle: "",
      name: "",
      description: "",
      amount: "",
      currency: "",
      cycle: "",
      cycleAmount: "",
      multiSede: false,
      quantitySedes: 1,
      quantityClients: -1,
      quantityPlans: -1,
      quantityClases: -1,
      quantityProducts: -1,
      quantityServices: -1,
      quantityUsers: -1,
      backup: true,
      android: true,
      ios: true,
      notification: true,
      support: true,
      timeNotification: 5,
      notifications: true,
      culqiInfo: "",
      culqiId: 0,
      culqiJson: null,
      disabled: false,

      headers: [
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Codigo culqi",
          value: "culqi_id",
        },
        {
          text: "Monto",
          value: "amount",
        },
        {
          text: "Ciclo",
          value: "cycle",
        },
        {
          text: "Estado",
          value: "enabled",
          aling: "center",
        },
        {
          text: "Registrado",
          value: "createdBy",
        },
        {
          text: "Editado",
          value: "updatedBy",
        },
        {
          text: "Acciones",
          value: "actions",
          aling: "center",
          sortable: false,
        },
      ],

      rulesName: [
        (name) =>
          (name && name.length >= 3) ||
          "Debe ingresar el nombre de la licencia",
      ],

      rulesAmout: [
        (amount) => (amount && !isNaN(amount)) || "EL monto debe ser numerico",
      ],

      rulesCurrency: [
        (currency) =>
          (currency && currency != "") || "Debe seleccionar una moneda",
      ],

      rulesCycle: [
        (cycle) => (cycle && cycle != "") || "Debe seleccionar un ciclo",
      ],

      rulesCycleAmount: [
        (cycleAmount) =>
          (cycleAmount && !isNaN(cycleAmount)) ||
          "El monto del ciclo debe ser numerico",
      ],

      rulesQuantitySedes: [
        (quantitySedes) =>
          (quantitySedes && !isNaN(quantitySedes)) ||
          "La cantidad de sedes debe ser numerico",
        (quantitySedes) =>
          (quantitySedes && quantitySedes > 0) ||
          "La cantidad de sedes debe ser minimo 1",
      ],

      rulesQuantityClients: [
        (quantityClients) =>
          (quantityClients && !isNaN(quantityClients)) ||
          "La cantidad de clientes debe ser numerico",
        (quantityClients) =>
          (quantityClients && quantityClients >= -1) ||
          "La cantidad de clientes debe ser mayor o igual a -1",
        (quantityClients) =>
          (quantityClients && quantityClients != 0) ||
          "La cantidad de clientes debe ser diferente de 0",
      ],

      rulesQuantityPlans: [
        (quantityPlans) =>
          (quantityPlans && !isNaN(quantityPlans)) ||
          "La cantidad de planes debe ser numerico",
        (quantityPlans) =>
          (quantityPlans && quantityPlans >= -1) ||
          "La cantidad de planes debe ser mayor o igual a -1",
        (quantityPlans) =>
          (quantityPlans && quantityPlans != 0) ||
          "La cantidad de planes debe ser diferente de 0",
      ],

      rulesQuantityClases: [
        (quantityClases) =>
          (quantityClases && !isNaN(quantityClases)) ||
          "La cantidad de clases debe ser numerico",
        (quantityClases) =>
          (quantityClases && quantityClases >= -1) ||
          "La cantidad de clases debe ser mayor o igual a -1",
        (quantityClases) =>
          (quantityClases && quantityClases != 0) ||
          "La cantidad de clases debe ser diferente de 0",
      ],

      rulesQuantityProducts: [
        (quantityProducts) =>
          (quantityProducts && !isNaN(quantityProducts)) ||
          "La cantidad de productos debe ser numerico",
        (quantityProducts) =>
          (quantityProducts && quantityProducts >= -1) ||
          "La cantidad de productos debe ser mayor o igual a -1",
        (quantityProducts) =>
          (quantityProducts && quantityProducts != 0) ||
          "La cantidad de productos debe ser diferente de 0",
      ],

      rulesQuantityServices: [
        (quantityServices) =>
          (quantityServices && !isNaN(quantityServices)) ||
          "La cantidad de servicios debe ser numerico",
        (quantityServices) =>
          (quantityServices && quantityServices >= -1) ||
          "La cantidad de servicios debe ser mayor o igual a -1",
        (quantityServices) =>
          (quantityServices && quantityServices != 0) ||
          "La cantidad de servicios debe ser diferente de 0",
      ],

      rulesQantityUser: [
        (quantityUsers) =>
          (quantityUsers && !isNaN(quantityUsers)) ||
          "La cantidad de usuarios debe ser numerico",
        (quantityUsers) =>
          (quantityUsers && quantityUsers >= -1) ||
          "La cantidad de usuarios debe ser mayor o igual a -1",
        (quantityUsers) =>
          (quantityUsers && quantityUsers != 0) ||
          "La cantidad de usuarios debe ser diferente de 0",
      ],

      rulesNotification: [
        (timeNotification) =>
          (timeNotification && !isNaN(timeNotification)) ||
          "El tiempo de notificacion debe ser numerico",
        (timeNotification) =>
          (timeNotification && timeNotification >= -1) ||
          "El tiempo de notificacion debe ser mayor o igual a -1",
        (timeNotification) =>
          (timeNotification && timeNotification != 0) ||
          "El tiempo de notificacion debe ser diferente de 0",
      ],
    };
  },

  mounted() {
    this.dialogLoaging = true;
    this.loadingData = true;

    setTimeout(() => {
      this.$store
        .dispatch("loadLicenses")
        .then(() => {
          this.loadingData = false;
          this.dialogLoaging = false;
        })
        .catch(() => {
          this.loadingData = false;
          this.dialogLoaging = false;
        });
    }, 500);
  },

  computed: {
    licenses() {
      return this.$store.getters.getLicenses;
    },

    currencies() {
      let licenses = this.$store.getters.getSetupGeneralSelectByKey(5);
      let licenseDefault = licenses.find((l) => l.default == 1);
      let array = [];
      array.push(licenseDefault);
      return licenseDefault;
    },

    cycles() {
      return this.$store.getters.getSetupGeneralSelectByKey(1);
    },
  },

  methods: {
    register() {
      this.loadingForm = true;
      if (this.valid) {
        this.$store
          .dispatch("registerLicense", {
            name: this.name.toUpperCase(),
            amount: this.amount,
            currency_id: this.currency,
            description: this.description,
            multi_sede: this.multiSede,
            quantity_sedes: this.quantitySedes,
            quantity_clients: this.quantityClients,
            quantity_plans: this.quantityPlans,
            quantity_clases: this.quantityClases,
            quantity_products: this.quantityProducts,
            quantity_services: this.quantityServices,
            quantity_users: this.quantityUsers,
            backup: this.backup,
            android: this.android,
            ios: this.ios,
            notifications: this.notifications,
            support: this.support,
            cycle_id: this.cycle,
            cycle_amount: this.cycleAmount,
            time_notification: this.timeNotification,
          })
          .then(() => {
            this.loadingForm = false;
            this.closeDialog();
          })
          .catch(() => {
            this.loadingForm = false;
          });
      } else {
        this.loadingForm = false;
        this.$refs.form.validate();
      }
    },

    edit() {
      this.loadingForm = true;
      if (this.valid) {
        this.$store
          .dispatch("editLicense", {
            id: this.id,
            name: this.name.toUpperCase(),
            amount: this.amount,
            currency_id: this.currency,
            description: this.description,
            multi_sede: this.multiSede,
            quantity_sedes: this.quantitySedes,
            quantity_clients: this.quantityClients,
            quantity_plans: this.quantityPlans,
            quantity_clases: this.quantityClases,
            quantity_products: this.quantityProducts,
            quantity_services: this.quantityServices,
            quantity_users: this.quantityUsers,
            backup: this.backup,
            android: this.android,
            ios: this.ios,
            notifications: this.notifications,
            support: this.support,
            cycle_id: this.cycle,
            cycle_amount: this.cycleAmount,
            time_notification: this.timeNotification,
          })
          .then(() => {
            this.dialog = false;
            this.loadingForm = false;
            this.resetData();
          })
          .catch(() => {
            this.loadingForm = false;
          });
      } else {
        this.loadingForm = false;
        this.$refs.form.validate();
      }
    },

    changeStatus(item) {
      this.$confirm(
        `Desea ${item.enabled ? `deshabilitar` : `habilitar`} la licencia: ${
          item.name
        } ?`,
        {
          buttonTrueText: "Si",
          title: "Confirmacion",
          color: "primary",
          icon: "mdi-crosshairs-question",
        }
      ).then((response) => {
        if (response) {
          this.$store
            .dispatch("changeStatusLicense", this.id)
            .then(() => {
              this.loadingForm = false;
            })
            .catch(() => {
              this.loadingForm = false;
            });
        }
      });
    },

    openDialog(item) {
      if (item == null) {
        this.dialog = true;
        this.loadingForm = true;
        this.dialogTitle = "Registrar Licencia";
        this.dialogBtnTitle = "Registrar";
        this.dialogRegister = true;
        if (this.$refs.form) this.$refs.form.resetValidation();
        this.loadingForm = false;
      } else {
        this.id = item.id;
        this.loadingForm = true;
        this.dialog = true;
        setTimeout(() => {
          this.$store.dispatch("showLicense", this.id).then((res) => {
            this.name = res.name;
            this.description = res.description;
            this.amount = res.amount;
            this.currency = res.currency_id;
            this.cycle = res.cycle_id;
            this.cycleAmount = res.cycle_amount;
            this.multi_sede = res.multi_sede;
            this.quantity_sedes = res.quantity_sedes;
            this.quantity_clients = res.quantity_clients;
            this.quantity_plans = res.quantity_plans;
            this.quantity_clases = res.quantity_clases;
            this.quantity_products = res.quantity_products;
            this.quantity_services = res.quantity_services;
            this.quantity_users = res.quantity_users;
            this.backup = res.backup;
            this.android = res.android;
            this.ios = res.ios;
            this.notifications = res.notifications;
            this.support = res.support;
            this.time_notification = res.time_notification;
            this.loadingForm = false;
          });
        }, 500);

        this.dialogTitle = "Editar Licencia";
        this.dialogBtnTitle = "Editar";
        this.dialogRegister = false;
      }
    },

    closeDialog() {
      this.loadingForm = false;
      this.dialog = false;
      this.clearData();
      this.$refs.form.resetValidation();
    },

    openBinnacle(item) {
      this.titleBinnacle = `Bitacora de la licencia: ${item.name}`;
      item.table = "licenses";
      bus.$emit("binnacle", item);
    },

    closeBinacle() {
      this.dialogBinnacle = false;
      this.loadingForm - false;
    },

    changeMultiSede(multisede) {
      if (!multisede) this.quantitySedes = 1;
    },

    openDialogculqi(item) {
      this.clearData();
      this.culqiJson = null;
      this.loadingDialog = true;
      this.dialogculqi = true;
      this.disabled = true;
      if (item.culqi_id != 0) {
        this.schema = "search";
        this.$store
          .dispatch("findCulqiPlan", item.culqi_id)
          .then((response) => {
            this.culqiJson = response.data;
            this.loadingDialog = false;
            this.disabled = false;
          })
          .catch(() => {
            //mostrar mensaje de error en la busqueda
            this.loadingDialog = false;
          });
      } else {
        this.disabled = false;
        this.schema = "new";
        this.culqiId = item.culqi_id;
        this.id = item.id;
        this.name = item.name;
        this.amount = item.amount;
        this.currency = item.currency.name;
        this.cycle = item.cycle.name;
        this.cycle_amount = item.cycle_amount;
        this.loadingDialog = false;
        this.culqiInfo = "Esta licencia no esta sincronizada con culqi.com ...";
      }
    },

    closeDialogculqi() {
      this.dialogculqi = false;
    },

    registerculqi() {
      this.loadingForm = true;
      let payload = {
        id: this.id,
        name: this.name,
        amount: this.amount,
        currency_code: this.currency,
        interval: this.cycle,
        interval_count: this.cycle_amount,
      };

      this.$store
        .dispatch("registerCulqi", payload)
        .then(() => {
          this.loadingForm = false;
        })
        .catch(() => {
          //mostrar error en el registro del plan en culqi
          this.loadingForm = false;
        });
    },

    clearData() {
      this.name = "";
      this.amount = "";
      this.culqiInfo = "";
      this.description = "";
      this.amount = "";
      this.currency = "";
      this.cycle = "";
      this.cycleAmount = "";
      this.multi_sede = false;
      this.quantity_sedes = 1;
      this.quantity_clients = -1;
      this.quantity_plans = -1;
      this.quantity_clases = -1;
      this.quantity_products = -1;
      this.quantity_services = -1;
      this.quantity_users = -1;
      this.backup = false;
      this.android = false;
      this.ios = false;
      this.notifications = false;
      this.support = false;
      this.time_notification = 5;
    },

    formatAmount(amount) {
      let intPart = amount.toString().substr(0, amount.toString().length - 2);
      let decimalPart = amount.toString().substr(amount.toString().length - 2);
      return `${intPart}.${decimalPart}`;
    },

    formatDate(value) {
      value = value.toString().substr(0, 10);
      var unixtimestamp = parseInt(value);
      var months_arr = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var date = new Date(unixtimestamp * 1000);
      var year = date.getFullYear();
      var month = months_arr[date.getMonth()];
      var day = date.getDate();
      var hours = date.getHours();
      var minutes = "0" + date.getMinutes();
      var seconds = "0" + date.getSeconds();
      var convdataTime =
        day +
        "-" +
        month +
        "-" +
        year +
        " " +
        hours +
        ":" +
        minutes.substr(-2) +
        ":" +
        seconds.substr(-2);

      return convdataTime;
    },
  },
};
</script>

<style>
.users {
  font-size: 12px;
}
</style>
