import axios from "axios";

const store = {
  state: {
    licenses: [],
    licenseSelect: [],
    license: {},
  },

  getters: {
    getLicenses(state) {
      return state.licenses;
    },

    getLicenseSelect(state) {
      return state.licenseSelect;
    },

    getLicense(state) {
      return state.license;
    },
  },

  mutations: {
    loadLicenses(state, licenses) {
      state.licenses = licenses;
    },

    editLicense(state, licenseUpdate) {
      let index = state.licenses.findIndex(
        (license) => license.id === licenseUpdate.id
      );
      
      state.licenses.splice(index, 1, licenseUpdate);
    },

    loadLicenseSelect(state, licenseSelect) {
      state.licenseSelect = licenseSelect;
    },

    addLicense(state, license) {
      state.licenses.push(license);
    },

    showLicense(state, license) {
      return (state.license = license);
    },
  },

  actions: {
    loadLicenses({ getters, commit }) {
      return new Promise((resolve, reject) => {
        if (getters.getLicenses.length > 0) resolve();
        else {
          axios
            .get(`/license`)
            .then((response) => {
              commit("loadLicenses", response.data.licenses);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },

    registerLicense({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/license/store`, payload)
          .then((response) => {
            commit("addLicense", response.data.license);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    showLicense(Context, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/license/${id}`)
          .then((res) => {
            resolve(res.data.license);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    editLicense({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/license/${payload.id}`, payload)
          .then((response) => {
            commit("editLicense", response.data.license);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    changeStatusLicense({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/license/change-status/${id}`)
          .then((response) => {
            commit("editLicense", response.data.license);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    findSelectLicence({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/license/find/select`)
          .then((response) => {
            commit("loadLicenseSelect", response.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    registerCulqi({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/culqi/plans`, payload)
          .then((response) => {            
            let data = {
              id: payload.id,
              culqiId: response.data.id,
            };

            dispatch("syncCulqi", data)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {            
            reject(error);
          });
      });
    },

    syncCulqi({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/culqi/plans/${payload.id}`, payload)
          .then((response) => {
            console.log(response.data.license);
            commit("editLicense", response.data.license);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    findCulqiPlan({ getters }, culqiId) {
      getters.getLicenses;
      return new Promise((resolve, reject) => {
        axios
          .get(`/culqi/plans/${culqiId}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default store;
