import axios from "axios";

const store = {
  state: {
    paymentUniques: [],
  },

  getters: {
    getPaymentUniques(state) {
      return state.paymentUniques;
    },
  },

  mutations: {},

  actions: {
    registerPaymentUnique({ getters }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/payment-unique/store/`, payload)
          .then(() => {
            getters.getPaymentUniques;
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    findPaymentUniqueByStatus({ getters }, status) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/payment-unique/find-by-status/${status}`)
          .then((response) => {
            getters.getPaymentUniques;
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    registerPaymentLicense(Context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/payment-license/store`, payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
export default store;
