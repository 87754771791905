var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-autocomplete',{attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"chips":"","clearable":"","hide-details":"","hide-selected":"","item-text":"name","item-value":"code","label":"Nombre de empresa..."},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Puede realizar la busqueda con nombre de la empresa ")])],1)]},proxy:true},{key:"selection",fn:function({ attr, on, item, selected }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"indigo"}},'v-chip',attr,false),on),[_c('span',{domProps:{"textContent":_vm._s(
          `${item.name} / 
          ${item.country}  
          ${
            item.type == 'Business'
              ? `/ ${item.departament}/BUSINESS`
              : `/POTENCIAL`
          }`
        )}})])]}},{key:"item",fn:function({ item }){return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
          `${item.country} 
           ${item.type == 'Business' ? `/ ${item.departament}` : ''} 
          `
        )}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(`${item.type}`)}})],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})
}
var staticRenderFns = []

export { render, staticRenderFns }