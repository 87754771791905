<template>
  <v-container>
    <v-row>
      <!-- Header -->
      <v-col cols="12">
        <module-bar title="DEPARTAMENTOS" icon="mdi-cog">
          <template slot="search">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              style="max-width: 400px"
            ></v-text-field>
          </template>

          <template slot="buttons">
            <v-btn color="primary" text @click="openDialog()"> agregar </v-btn>
          </template>
        </module-bar>
      </v-col>

      <!-- Datatable -->
      <v-col cols="12">
        <v-data-table
          :items="departaments"
          :loading="loadingData"
          :headers="headers"
          :search="search"
          mobile-breakpoint="800"
          dense
        >
          <template v-slot:[`item.enabled`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="item.enabled ? 'green' : 'red'"
                  size="16"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-circle
                </v-icon>
              </template>
              <span>{{ item.enabled ? "Habilitado" : "Deshabilitado" }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.createdBy`]="{ item }">
            <p class="users my-1">
              {{ `${item.createdBy.names}` }}
              <br />
              {{ `${item.created_at}` }}
            </p>
          </template>

          <template v-slot:[`item.updatedBy`]="{ item }">
            <p class="users my-1">
              {{ `${item.updatedBy.names}` }}
              <br />
              {{ `${item.updated_at}` }}
            </p>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button icon v-bind="attrs" v-on="on" @click="openDialog(item)">
                  <v-icon size="18">mdi-pencil</v-icon>
                </button>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="ml-2"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="changeStatus(item)"
                >
                  <v-icon size="18">
                    {{ item.enabled ? "mdi-delete" : "mdi-check" }}
                  </v-icon>
                </button>
              </template>
              <span>{{ item.enabled ? "Deshabilitar" : "Habilitar" }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Dialogo para crear y editar -->
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title>
          {{ dialog_title }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-select
              label="Pais"
              :items="countries"
              v-model="country"
              :rules="rulesCountry"
            />
            <v-text-field
              label="Nombre del departamento"
              v-model="name"
              :rules="rulesName"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeDialog">cancelar</v-btn>
          <v-divider vertical class="ml-3 mr-3" />
          <v-btn
            :loading="loadingForm"
            color="primary"
            dark
            @click="dialog_register ? register() : edit()"
            >{{ dialog_btn_title }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para la bitacora -->
    <binnacle :width="1000">
      <template slot="title">{{ titleBinnacle }}</template>
    </binnacle>
  </v-container>
</template>

<script>
import bus from "../../../events/bus";
import { mixinForm } from "../../../mixins";
import ModuleBar from "../../../components/General/ModuleBar";
import Binnacle from "../../../components/General/Binnacle.vue";

export default {
  components: {
    ModuleBar,
    Binnacle,
  },

  mixins: [mixinForm],

  data() {
    return {
      id: "",
      name: "",
      country: "",
      dialog: false,
      dialog_title: "",
      dialog_btn_title: "",
      dialog_register: true,
      dialogBinnacle: false,
      loadingData: false,
      search: "",
      titleBinnacle: "",
      headers: [
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Pais",
          value: "country.name",
        },
        {
          text: "Estado",
          value: "enabled",
          aling: "center",
        },
        {
          text: "Registrado",
          value: "createdBy",
        },
        {
          text: "Editado",
          value: "updatedBy",
        },
        {
          text: "Acciones",
          aling: "center",
          value: "actions",
          sortable: false,
        },
      ],

      rulesCountry: [
        (country) => (country && country != null) || "Debe seleccionar un pais",
      ],
      rulesName: [
        (name) =>
          (name && name.length >= 3) ||
          "Debe ingresar el nombre del departamento",
      ],
    };
  },

  mounted() {
    this.loadingData = true;
    this.$store
      .dispatch("loadDepartaments")
      .then(() => {
        this.loadingData = false;
      })
      .catch(() => {
        this.loadingData = false;
      });
  },

  computed: {
    departaments() {
      return this.$store.getters.getDepartaments;
    },

    countries() {
      return this.$store.getters.getCountrySelect;
    },
  },

  watch: {
    name(value) {
      if (value) this.name = value.toUpperCase();
    },
  },

  methods: {
    register() {
      this.loadingForm = true;
      if (this.valid) {
        this.$store
          .dispatch("registerDepartament", {
            country_id: this.country,
            name: this.name,
          })
          .then(() => {
            this.loadingForm = false;
            this.$refs.form.reset();
          })
          .catch(() => {
            this.loadingForm = false;
            this.$refs.form.reset();
          });
      } else {
        this.loadingForm = false;
        this.$refs.form.validate();
      }
    },

    edit() {
      this.loadingForm = true;
      if (this.valid) {
        this.$store
          .dispatch("editDepartament", {
            id: this.id,
            country_id: this.country,
            name: this.name,
          })
          .then(() => {
            this.dialog = false;
            this.loadingForm = false;
            this.$refs.form.reset();
          })
          .catch(() => {
            this.dialog = false;
            this.loadingForm = false;
            this.$refs.form.reset();
          });
      } else {
        this.loadingForm = false;
        this.$refs.form.validate();
      }
    },

    changeStatus(item) {
      this.$confirm(
        `Desea ${
          item.enabled ? `deshabilitar` : `habilitar`
        } el departamento: ${item.name} ?`,
        {
          buttonTrueText: "Si",
          title: "Confirmacion",
          color: "primary",
          icon: "mdi-crosshairs-question",
        }
      ).then((response) => {
        if (response) {
          this.$store
            .dispatch("changeStatusDepartament", this.id)
            .then(() => {
              this.loadingForm = false;
            })
            .catch(() => {
              this.loadingForm = false;
            });
        }
      });
    },

    openDialog(item) {
      this.dialog = true;
      if (item == null) {
        this.dialog_title = "Registrar Departamento";
        this.dialog_btn_title = "Registrar";
        this.dialog_register = true;
      } else {
        this.dialog_title = "Editar Departamento";
        this.dialog_btn_title = "Editar";
        this.dialog_register = false;
        this.name = item.name;
        this.country = item.country_id;
        this.id = item.id;
      }
    },

    closeDialog() {
      this.$refs.form.reset();
      this.loadingForm = false;
      this.dialog = false;
    },

    openBinnacle(item) {
      this.titleBinnacle = `Bitacora del departamento: ${item.name}`;
      item.table = "departaments";
      bus.$emit("binnacle", item);
    },

    closeBinacle() {
      this.dialogBinnacle = false;
      this.loadingForm - false;
    },
  },
};
</script>

<style>
.users {
  font-size: 12px;
}
</style>