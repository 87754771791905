<template>
  <v-container>
    <v-text-field label="Nombre" v-model="nombre" />
  </v-container>
</template>
<script>
import bus from "../../events/bus";

export default {
  props: ["avisar"],

  beforeMount() {
    bus.$on("ejecutar", () => {
      this.avisar({ nombre: this.nombre });
    });
  },

  data() {
    return {
      nombre: null,
    };
  },
};
</script>

<style></style>
