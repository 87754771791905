<template>
  <v-container>
    <module-bar title="ACTIVACION DE EMPRESA" icon="mdi-buffer"></module-bar>
    <v-row>
      <v-col cols="12">
        <complete-business
          :getBusiness="getBusiness"
          @setLoading="setLoading"
          @setBusiness="setBusiness"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
          width="2"
          size="60"
          v-if="loadingData"
        />
      </v-col>
    </v-row>
    <v-card v-if="business" outlined class="mt-5">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <v-text-field
              label="Nombre"
              v-model="business.name"
              :readonly="true"
            />
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-text-field
              label="Distrito"
              v-model="business.district.name"
              :readonly="true"
            />
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-text-field
              label="Licencia"
              v-model="business.license.name"
              :readonly="true"
            />
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-text-field
              label="Fecha Vencimiento"
              v-model="business.expire_date_cool"
              :readonly="true"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Seleccione la proxima fecha de vencimiento"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="activate" :loading="loadingActivate"
          >activar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import CompleteBusiness from "../../components/General/CompleteBusiness.vue";
import ModuleBar from "../../components/General/ModuleBar.vue";

export default {
  components: { ModuleBar, CompleteBusiness },

  data() {
    return {
      business: null,
      loadingData: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      loadingActivate: false,
    };
  },

  methods: {
    getBusiness(business) {
      console.log(business);
      this.business = business;
    },

    setLoading(value) {
      this.loadingData = value;
    },

    setBusiness() {
      this.business = null;
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },

    activate() {
      this.loadingActivate = true;
      setTimeout(() => {
        let payload = {
          id: this.business.id,
          expire_date: this.date,
        };

        this.$store.dispatch("activateBusinessAction", payload).then(() => {
          this.business = null;
        });
        this.loadingActivate = false;
      }, 500);
    },
  },
};
</script>

<style>
</style>