<template>
  <v-container>
    <module-bar :title="title"> </module-bar>
    <v-row>
      <v-col cols="12" sm="4">
        <v-card outlined color="#385F73" dark>
          <v-card-title> Cobro unico </v-card-title>
          <v-card-text>
            Es el cobro por pasarela de pago que se hace a una empresa o cliente
            potencial, el cual es de un solo pago. Ej. Pago de equipos e
            instalaciones.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="paymentUnique">seleccionar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card outlined color="#952175" dark>
          <v-card-title> Cobro de licencia </v-card-title>
          <v-card-text>
            Es un cobro basico con link automatico a la pasarela pago que se
            hace a una empresa que va a iniciar o renovar el pago sistematico de
            su licencia
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="paymentLicense('pay')">seleccionar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card outlined color="#357B63" dark>
          <v-card-title> Pago de licencia </v-card-title>
          <v-card-text>
            Es el pago que se registra para renovar o iniciar la licencia de una empresa
            que cancelo en Efectivo o Transferencia bancaria.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="paymentLicense('payment')">seleccionar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ModuleBar from "../../components/General/ModuleBar.vue";
import { mixinForm } from "../../mixins";

export default {
  components: { ModuleBar },
  mixins: [mixinForm],

  data() {
    return {
      title: "PAGOS Y COBROS",
    };
  },

  methods: {
    paymentUnique() {
      this.$router.push("/payments/pay-unique");
    },

    paymentLicense(type) {
      this.$router.push(`/payments/pay-license/${type}`);
    },
  },
};
</script>

<style></style>
