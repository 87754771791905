<template>
  <div class="mt-5">
    <v-card outlined :loading="loadingForm" class="mt-5">
      <v-card-title
        >Informacion de contactos
        <v-spacer />
        <v-btn icon @click="dialogContacts = true">
          {{ contacts.length }}
          <v-icon
            :disabled="schema == 'search' ? disabledButtons : false"
            class="ml-1"
            size="30"
            color="primary"
            >mdi-account-multiple-outline</v-icon
          ></v-btn
        >
      </v-card-title>
      <v-card-subtitle>{{
        !businessP
          ? `Datos de los contactos de la empresa
          como el encargado o el gerente`
          : `Datos de los contactos del potencial cliente`
      }}</v-card-subtitle>
      <v-divider />
      <v-card-text>
        <v-form
          :disabled="schema == 'search' ? disabledForms : false"
          ref="formContact"
          v-model="validContact"
        >
          <v-row>
            <v-col cols="12" md="4">
              <v-select
                label="Tipo de contacto"
                :items="contactTypes"
                v-model="contactType"
                :rules="rulesContactTypes"
              ></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Nombre completo"
                counter="50"
                v-model="contactFullName"
                :rules="rulesContactFullName"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Email de contacto"
                counter="50"
                :rules="rulesContactEmail"
                v-model="contactEmail"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Telefono #1 de contacto"
                counter="20"
                :rules="rulesContactPhone1"
                v-model="contactPhone1"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Telefono #2 de contacto"
                v-model="contactPhone2"
                counter="20"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="schema == 'search' ? disabledForms : false"
          text
          color="primary"
          @click="loadContact"
        >
          {{ `${!contactIsEdit ? "REGISTRAR" : "EDITAR"} CONTACTO` }}</v-btn
        >
        <v-btn
          :disabled="schema == 'search' ? disabledButtons : false"
          color="red"
          text
          v-if="contactIsEdit"
          @click="cancelEditContact"
          >CANCELAR</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogContacts" width="800" persistent>
      <v-card>
        <v-card-title
          >Contactos agregados
          <v-spacer />
          <v-btn icon @click="dialogContacts = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="contacts"
            :headers="contactHeaders"
            hide-default-footer
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.type_text }}</td>
                <td>{{ props.item.full_name }}</td>
                <td>{{ props.item.email }}</td>
                <td>
                  <v-btn
                    v-if="!disabledForms"
                    icon
                    :disabled="contactIsEdit"
                    @click="editContact(props.index)"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!disabledForms"
                    :disabled="contactIsEdit"
                    icon
                    class="my-3"
                    @click="deleteContact(props.index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mixinForm } from "../../mixins";

export default {
  props: {
    schema: String,
    businessP: Boolean,
    data: Function,
    click: Number,
    clear: Number,
    business: Object,
    disabledForms: Boolean,
  },
  mixins: [mixinForm],

  mounted() {
    if(this.business)
      this.loadData();
  },

  data() {
    return {
      contacts: [],
      deletes: [],
      contact_id: 0,
      contactEditIndex: "",
      contactIsEdit: false,
      contactType: "",
      contactFullName: "",
      contactEmail: "",
      contactPhone1: "",
      contactPhone2: "",
      dialogContacts: false,
      disabledButtons: true,

      contactHeaders: [
        {
          text: "Tipo",
          value: "type_text",
          sortable: false,
        },
        {
          text: "Nombre",
          value: "name",
          sortable: false,
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
        },
        {
          text: "Acciones",
          aling: "center",
          sortable: false,
        },
      ],

      rulesContactTypes: [
        (contactTypes) =>
          (contactTypes && contactTypes != "") ||
          "Debe seleccionar un tipo de contacto",
      ],
      rulesContactFullName: [
        (contactFullName) =>
          (contactFullName && contactFullName.length > 0) ||
          "Debe ingresar el nombre completo del coontacto",
      ],
      rulesContactEmail: [
        (contactEmail) =>
          (contactEmail && contactEmail.length > 0) ||
          "Debe ingresar el email del contacto",
        (contactEmail) =>
          (contactEmail && this.validEmail(contactEmail)) ||
          "Debe ingresar un email valido",
      ],
      rulesContactPhone1: [
        (contactPhone1) =>
          (contactPhone1 && contactPhone1.length > 0) ||
          "Debe ingresar el telefono del contacto",
      ],
    };
  },

  watch: {
    click: function () {
      if (this.contacts.length > 0) {
        for (let key in this.contacts) {
          delete this.contacts[key].type_text;
        }
      }
      this.data({ contacts: this.contacts, deletes: this.deletes });
    },

    clear: function () {
      this.contacts = [];
      this.deletes = [];
      this.$refs.formContact.resetValidation();
    },
  },

  computed: {
    contactTypes() {
      return this.$store.getters.getSetupGeneralSelectByKey(7);
    },
  },

  methods: {
    loadData() {
      this.disabledButtons = false;
      this.contacts = this.business.contacts;

      for (let key in this.contacts) {
        let contactTypeText = this.contactTypes.find(
          (contactType) => contactType.value === this.contacts[key].type_id
        );
        this.contacts[key]["type_text"] = contactTypeText.text;
      }
    },

    loadContact() {
      if (this.validContact) {
        let contactTypeText = this.contactTypes.find(
          (contactType) => contactType.value === this.contactType
        );

        let contact = {
          type_text: contactTypeText.text,
          type_id: this.contactType,
          full_name: this.contactFullName.toUpperCase(),
          email: this.contactEmail,
          phone_1: this.contactPhone1,
          phone_2: this.contactPhone2,
        };

        if (this.schema == "search" && this.contactIsEdit) {
          contact.action = "edit";
          contact.id = this.contact_id;
        } else if (this.schema == "search" && !this.contactIsEdit)
          contact.action = "new";

        this.contactIsEdit
          ? this.contacts.splice(this.contactEditIndex, 1, contact)
          : this.contacts.push(contact);

        this.resetFormContact();
      } else this.$refs.formContact.validate();
    },

    cancelEditContact() {
      this.$confirm("Desea cancelar la edicion del contacto?").then(
        (response) => {
          if (response) {
            this.contactEditIndex = "";
            this.contactIsEdit = false;
            this.resetFormContact();
          }
        }
      );
    },

    resetFormContact() {
      this.contactType = "";
      this.contactFullName = "";
      this.contactEmail = "";
      this.contactPhone1 = "";
      this.contactPhone2 = "";
      this.contactEditIndex = "";
      this.contactIsEdit = false;
      this.$refs.formContact.resetValidation();
    },

    deleteContact(index) {
      this.$confirm("Desea eliminar este contacto?").then((response) => {
        if (response) {
          if (this.contacts[index].id) {
            let item = {
              id: this.contacts[index].id,
              name: "contact",
            };
            this.deletes.push(item);
          }
          this.contacts.splice(index, 1);
        }
      });
    },

    editContact(index) {
      this.contactType = this.contacts[index].type_id;
      this.contactFullName = this.contacts[index].full_name;
      this.contactEmail = this.contacts[index].email;
      this.contactPhone1 = this.contacts[index].phone_1;
      this.contactPhone2 = this.contacts[index].phone_2;
      this.dialogContacts = false;
      this.contactEditIndex = index;
      this.contactIsEdit = true;
      this.contact_id = this.contacts[index].id;
    },
  },
};
</script>

<style></style>
