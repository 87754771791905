<template>
  <v-container>
    <v-row>
      <!-- Header -->
      <v-col cols="12">
        <module-bar title="BANCOS" icon="mdi-cog">
          <template slot="search">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              style="max-width: 400px"
            ></v-text-field>
          </template>

          <template slot="buttons">
            <v-btn color="primary" text @click="openDialog()"> agregar </v-btn>
          </template>
        </module-bar>
      </v-col>

      <!-- Datatable -->
      <v-col cols="12">
        <v-data-table
          :items="banks"
          :loading="loadingData"
          :headers="headers"
          :search="search"
          mobile-breakpoint="800"
          dense
        >
          <template v-slot:[`item.enabled`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="item.enabled ? 'green' : 'red'"
                  size="16"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-circle
                </v-icon>
              </template>
              <span>{{ item.enabled ? "Habilitado" : "Deshabilitado" }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.paymentType`]="{ item }">
            <p>
              {{ `${item.paymentType.name}` }}
            </p>
          </template>

          <template v-slot:[`item.createdBy`]="{ item }">
            <p class="users my-1">
              {{ `${item.createdBy.names}` }}
              <br />
              {{ `${item.created_at}` }}
            </p>
          </template>

          <template v-slot:[`item.updatedBy`]="{ item }">
            <p class="users my-1">
              {{ `${item.updatedBy.names}` }}
              <br />
              {{ `${item.updated_at}` }}
            </p>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button icon v-bind="attrs" v-on="on" @click="openDialog(item)">
                  <v-icon size="18">mdi-pencil</v-icon>
                </button>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button
                  icon
                  class="ml-3"
                  v-bind="attrs"
                  v-on="on"
                  @click="openAccounts(item)"
                >
                  <v-icon size="18">mdi-playlist-plus</v-icon>
                </button>
              </template>
              <span>Cuentas</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="ml-3"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="changeStatus(item)"
                >
                  <v-icon size="18">
                    {{ item.enabled ? "mdi-delete" : "mdi-check" }}
                  </v-icon>
                </button>
              </template>
              <span>{{ item.enabled ? "Deshabilitar" : "Habilitar" }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Dialogo para crear y editar -->
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card>
        <v-card-title>
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-select
              :items="typePayments"
              item-value="value"
              item-text="text"
              v-model="typePayment"
              label="Tipo de pago"
              :rules="rulesType"
            />
            <v-text-field
              label="Nombre del banco"
              v-model="name"
              :rules="rulesName"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeDialog">cancelar</v-btn>
          <v-divider vertical class="ml-3 mr-3" />
          <v-btn
            :loading="loadingForm"
            color="primary"
            dark
            @click="dialogRegister ? register() : edit()"
            >{{ dialogBtnTitle }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialogo cuentas -->
    <v-dialog v-model="dialogAccount" persistent max-width="1200">
      <v-card>
        <v-card-title>{{ titleDialogAccount }}</v-card-title>
        <v-card-text>
          <v-data-table
            :items="accounts"
            :loading="loadingAccounts"
            :headers="headersAccount"
            :search="search"
            mobile-breakpoint="800"
            dense
          >
            <template v-slot:[`item.bank`]="{ item }">
              <p class="users my-1">
                {{ `${item.bank.name}` }}
              </p>
            </template>

            <template v-slot:[`item.enabled`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="item.enabled ? 'green' : 'red'"
                    size="16"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-circle
                  </v-icon>
                </template>
                <span>{{ item.enabled ? "Habilitado" : "Deshabilitado" }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.createdBy`]="{ item }">
              <p class="users my-1">
                {{ `${item.createdBy.names}` }}
                <br />
                {{ `${item.created_at}` }}
              </p>
            </template>

            <template v-slot:[`item.updatedBy`]="{ item }">
              <p class="users my-1">
                {{ `${item.updatedBy.names}` }}
                <br />
                {{ `${item.updated_at}` }}
              </p>
            </template>

             <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button icon v-bind="attrs" v-on="on" @click="openDialog(item)">
                  <v-icon size="18">mdi-pencil</v-icon>
                </button>
              </template>
              <span>Editar</span>
            </v-tooltip>
            
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="ml-3"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="changeStatus(item)"
                >
                  <v-icon size="18">
                    {{ item.enabled ? "mdi-delete" : "mdi-check" }}
                  </v-icon>
                </button>
              </template>
              <span>{{ item.enabled ? "Deshabilitar" : "Habilitar" }}</span>
            </v-tooltip>
          </template>

          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeAccounts">cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mixinForm } from "../../../mixins";
import ModuleBar from "../../../components/General/ModuleBar";

export default {
  components: {
    ModuleBar,
  },

  mixins: [mixinForm],

  data() {
    return {
      loadingAccounts: false,
      accounts: [],
      bankId: 0,
      titleDialogAccount: "",
      dialogAccount: false,
      typePayment: 0,
      id: "",
      name: "",
      dialog: false,
      dialogTitle: "",
      dialogBtnTitle: "",
      dialogRegister: true,
      dialogBinnacle: false,
      loadingData: false,
      search: "",
      titleBinnacle: "",
      headers: [
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Tipo de pago",
          value: "paymentType",
        },
        {
          text: "Estado",
          value: "enabled",
          aling: "center",
        },
        {
          text: "Registrado",
          value: "createdBy",
        },
        {
          text: "Editado",
          value: "updatedBy",
        },
        {
          text: "Acciones",
          aling: "center",
          value: "actions",
          sortable: false,
        },
      ],
      headersAccount: [
        {
          text: "Bank",
          value: "bank",
        },
        {
          text: "Titular",
          value: "owner",
        },
        {
          text: "Tipo",
          value: "type",
        },
        {
          text: "Estado",
          value: "enabled",
          aling: "center",
        },
        {
          text: "Registrado",
          value: "createdBy",
        },
        {
          text: "Editado",
          value: "updatedBy",
        },
        {
          text: "Acciones",
          aling: "center",
          value: "actions",
          sortable: false,
        },
      ],

      rulesName: [
        (name) =>
          (name && name.length >= 3) || "Debe ingresar el nombre del banco",
      ],

      rulesType: [
        (typePayment) =>
          (typePayment && typePayment > 0) || "Seleccione el tipo de pago",
      ],
    };
  },

  mounted() {
    this.loadingData = true;
    this.$store
      .dispatch("loadBanksAction")
      .then(() => {
        this.loadingData = false;
      })
      .catch(() => {
        this.loadingData = false;
      });
  },

  computed: {
    banks() {
      return this.$store.getters.getBanks;
    },

    typePayments() {
      let setup = this.$store.getters.getSetupGeneralSelect;
      let currencies = setup.filter((s) => s.key == 9);
      return currencies;
    },
  },

  watch: {
    name(value) {
      if (value) this.name = value.toUpperCase();
    },
  },

  methods: {
    register() {
      this.loadingForm = true;
      if (this.valid) {
        let payload = {
          name: this.name,
          payment_type_id: this.typePayment,
        };
        this.$store
          .dispatch("registerBankAction", payload)
          .then(() => {
            this.loadingForm = false;
            this.$refs.form.reset();
          })
          .catch(() => {
            this.loadingForm = false;
            this.$refs.form.reset();
          });
      } else {
        this.loadingForm = false;
        this.$refs.form.validate();
      }
    },

    edit() {
      this.loadingForm = true;
      if (this.valid) {
        this.$store
          .dispatch("editBankAction", {
            id: this.id,
            name: this.name,
            payment_type_id: this.typePayment,
          })
          .then(() => {
            this.dialog = false;
            this.loadingForm = false;
            this.$refs.form.reset();
          })
          .catch(() => {
            this.dialog = false;
            this.loadingForm = false;
            this.$refs.form.reset();
          });
      } else {
        this.loadingForm = false;
        this.$refs.form.validate();
      }
    },

    changeStatus(item) {
      this.$confirm(
        `Desea ${item.enabled ? `deshabilitar` : `habilitar`} el pais: ${
          item.name
        } ?`
      ).then((response) => {
        if (response) {
          this.$store
            .dispatch("changeStatusBankAction", item.id)
            .then(() => {
              this.loadingForm = false;
            })
            .catch(() => {
              this.loadingForm = false;
            });
        }
      });
    },

    openDialog(item) {
      this.dialog = true;
      if (item == null) {
        this.dialogTitle = "Registrar Pais";
        this.dialogBtnTitle = "Registrar";
        this.dialogRegister = true;
      } else {
        this.dialogTitle = "Editar Pais";
        this.dialogBtnTitle = "Editar";
        this.dialogRegister = false;
        this.name = item.name;
        this.typePayment = item.paymentType.id;
        this.id = item.id;
      }
    },

    closeDialog() {
      this.$refs.form.reset();
      this.loadingForm = false;
      this.dialog = false;
    },

    openAccounts(item) {
      this.titleDialogAccount = `Cuentas Bancarias de (${item.name})`;
      this.bankId = item.id;
      this.dialogAccount = true;
      this.loadingAccounts = true;
      setTimeout(() => {
        this.$store
          .dispatch("findBankAccountAction", this.bankId)
          .then((res) => {
            this.accounts = res;
            this.loadingAccounts = false;
          });
      }, 500);
    },

    closeAccounts() {
      this.dialogAccount = false;
    },
  },
};
</script>

<style>
.users {
  font-size: 12px;
}
</style>