import axios from "axios";

const store = {

    state: {
        districts: [],
        districtSelect: [],
    },

    getters: {
        getDistricts(state) {
            return state.districts;
        },

        getDistrictSelect(state) {
            return state.districtSelect;
        }
    },

    mutations: {
        loadDistricts(state, districts) {
            state.districts = districts;
        },

        addDistrict(state, district) {
            state.districts.push(district);
        },

        editDistrict(state, districtUpdate) {
            let index = state.districts
                .findIndex((district) =>
                    district.id === districtUpdate.id
                );
            state.districts.splice(index, 1, districtUpdate);
        },

        loadDistrictSelect(state, districtSelect) {
            state.districtSelect = districtSelect;
        }
    },

    actions: {

        loadDistricts({ commit, getters }) {
            return new Promise((resolve, reject) => {
                if (getters.getDistricts.length > 0)
                    resolve();
                else {
                    axios.get(`/district`).then((response) => {
                        commit('loadDistricts', response.data.districts);
                        resolve();
                    }).catch((error) => {
                        reject(error)
                    });
                }
            })
        },

        registerDistrict({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post(`/district/store`, payload).then((response) => {
                    commit('addDistrict', response.data.district);
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            })
        },

        editDistrict({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.put(`/district/${payload.id}`, payload).then((response) => {
                    commit('editDistrict', response.data.district)
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            })
        },

        changeStatusDistrict({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.put(`/district/change-status/${id}`).then((response) => {
                    commit('editDistrict', response.data.district)
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            })
        },

        findSelectDistrictsByProvinceId({ commit }, province_id) {
            return new Promise((resolve, reject) => {
                axios.get(`/district/find/select/${province_id}`).then((response) => {
                    commit('loadDistrictSelect', response.data)
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            })
        }
    }
}

export default store;