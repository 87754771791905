<template>
  <v-container>
    <!-- card de contactos -->
    <v-card outlined>
      <v-card-title>Contactos</v-card-title>
      <v-card-text>
        <template v-if="business != null">
          <v-expansion-panels focusable>
            <v-expansion-panel
              v-for="(contact, key) in business.contacts"
              :key="key"
            >
              <v-expansion-panel-header>
                {{ contact.full_name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      {{ contact.email }}
                    </v-list-item-content>
                    <v-list-item-action
                      ><v-switch
                        v-model="self"
                        :value="
                          `contacts_email_${contact.email}_${contact.full_name}_${key}`
                        "
                      ></v-switch
                    ></v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="contact.phone_1_type == 'whatsapp'">
                    <v-list-item-content>
                      {{ contact.phone_1 }}
                    </v-list-item-content>
                    <v-list-item-action
                      ><v-switch
                        v-model="self"
                        :value="
                          `contacts_whatsapp_${contact.phone_1}_${contact.full_name}_${key}`
                        "
                      ></v-switch
                    ></v-list-item-action>
                  </v-list-item>

                  <v-list-item
                    v-if="contact.phone_2 && contact.phone_2_type == 'whatsapp'"
                  >
                    <v-list-item-content>
                      {{ contact.phone_2 }}
                    </v-list-item-content>
                    <v-list-item-action
                      ><v-switch
                        v-model="self"
                        :value="
                          `contacts_whatsapp_${contact.phone_2}_${contact.full_name}_${key}`
                        "
                      ></v-switch
                    ></v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>

        <template v-if="potencial != null">
          <v-expansion-panels focusable>
            <v-expansion-panel
              v-for="(contact, key) in potencial.contacts"
              :key="key"
            >
              <v-expansion-panel-header>
                {{ contact.full_name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      {{ contact.email }}
                    </v-list-item-content>
                    <v-list-item-action
                      ><v-switch
                        v-model="self"
                        :value="
                          `contacts_email_${contact.email}_${contact.full_name}_${key}`
                        "
                      ></v-switch
                    ></v-list-item-action>
                  </v-list-item>

                  <v-list-item v-if="contact.phone_1_type == 'whatsapp'">
                    <v-list-item-content>
                      {{ contact.phone_1 }}
                    </v-list-item-content>
                    <v-list-item-action
                      ><v-switch
                        v-model="self"
                        :value="
                          `contacts_whatsapp_${contact.phone_1}_${contact.full_name}_${key}`
                        "
                      ></v-switch
                    ></v-list-item-action>
                  </v-list-item>

                  <v-list-item v-if="contact.phone_2_type == 'whatsapp'">
                    <v-list-item-content>
                      {{ contact.phone_2 }}
                    </v-list-item-content>
                    <v-list-item-action
                      ><v-switch
                        v-model="self"
                        :value="
                          `contacts_whatsapp_${contact.phone_2}_${contact.full_name}_${key}`
                        "
                      ></v-switch
                    ></v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-card-text>
    </v-card>

    <!-- card propietarios-->
    <v-card outlined class="mt-4">
      <v-card-title>Propietarios</v-card-title>
      <v-card-text>
        <template v-if="business != null">
          <v-expansion-panels focusable>
            <v-expansion-panel
              v-for="(owner, key) in business.owners"
              :key="key"
            >
              <v-expansion-panel-header>
                {{ owner.full_name }}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      {{ owner.email }}</v-list-item-content
                    >
                    <v-list-item-action
                      ><v-switch
                        v-model="self"
                        :value="
                          `owners_email_${owner.email}_${owner.full_name}_${key}`
                        "
                      ></v-switch
                    ></v-list-item-action>
                  </v-list-item>

                  <v-list-item v-if="owner.phone_type == 'whatsapp'">
                    <v-list-item-content>
                      {{ owner.phone }}</v-list-item-content
                    >
                    <v-list-item-action
                      ><v-switch
                        v-model="self"
                        :value="
                          `owners_whatsapp_${owner.phone}_${owner.full_name}_${key}`
                        "
                      ></v-switch
                    ></v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-card-text>
    </v-card>

    <!-- card de otros -->
    <v-card outlined class="mt-4">
      <v-card-title>Otros</v-card-title>
      <v-card-text>
        <v-form ref="formOther" v-model="validOther">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="fullName"
                label="Nombre completo"
                :rules="rulesFullName"
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-select
                v-model="type"
                label="Tipo"
                :items="['email', 'whatsapp']"
                :rules="rulesType"
                @change="changeType"
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                :prefix="prefixPhone"
                v-model="value"
                label="Valor"
                :rules="rulesValue"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="addOther" text>Agregar Otro</v-btn>
      </v-card-actions>
      <v-divider />
      <v-card-text v-if="others.length > 0">
        <v-list>
          <v-list-item v-for="(other, key) in others" :key="key">
            <v-list-item-content>
              {{ `${other.fullName} / ${other.value} ` }}
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="deleteOther(key)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col cols="12" class="d-flex justify-end mt-4">
        <v-btn color="primary" dark @click="save">Guardar y cerrar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mixinForm } from "../../mixins";

export default {
  props: {
    business: Object,
    potencial: Object,
    getContacts: Function,
    finalized: Boolean,
  },
  mixins: [mixinForm],

  data() {
    return {
      self: [],
      fullName: "",
      type: "",
      value: "",
      others: [],
      prefixPhone: "",
      rulesFullName: [
        (name) => (name && name != "") || "Debe ingresar el nombre completo ",
      ],
      rulesType: [(type) => (type && type != "") || "Debe seleccionar el tipo"],
      rulesValue: [
        (value) => (value && value != "") || "Debe ingresar el valor",
      ],
    };
  },

  computed: {
    country() {
      let countries = this.$store.getters.getCountrySelect;
      let country = {};
      if (this.business) {
        let countryId = this.business.district.province.departament.country.id;
        country = countries.find((country) => country.value == countryId);
      }
      return country;
    },
  },

  watch: {
    finalized: function(value) {
      if (value) {
        this.self = [];
        this.others = [];
      }
    },
  },

  methods: {
    addOther() {
      if (this.validOther) {
        let other = {
          fullName: this.fullName,
          type: this.type,
          pre: this.prefixPhone,
          value: this.value,
        };
        this.others.push(other);
        this.fullName = "";
        this.type = "";
        this.value = "";
        this.prefixPhone = "";
        this.$refs.formOther.resetValidation();
      } else {
        this.$refs.formOther.validate();
      }
    },

    deleteOther(key) {
      this.$confirm("Desea eliminar este item?")
        .then((response) => {
          if (response) {
            this.others.splice(key, 1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    changeType() {
      if (this.type == "whatsapp") {
        this.prefixPhone = this.country.pre;
      } else {
        this.prefixPhone = "";
      }
    },

    save() {
      this.getContacts(this.self, this.others);
      this.$emit("close");
    },
  },
};
</script>

<style></style>
