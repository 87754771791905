<template>
  <div class="mt-5">
    <v-card outlined :loading="loadingForm" class="mt-5">
      <v-card-title
        >Informacion de propietario(s)
        <v-spacer />
        <v-btn icon @click="dialogOwner = true">
          {{ owners.length }}
          <v-icon
            :disabled="schema == 'search' ? disabledButtons : false"
            class="ml-1"
            size="30"
            color="primary"
            >mdi-account-tie</v-icon
          >
        </v-btn>
      </v-card-title>
      <v-card-subtitle>Datos del propietario de la empresa</v-card-subtitle>
      <v-divider />
      <v-card-text>
        <v-form
          :disabled="schema == 'search' ? disabledForms : false"
          ref="formOwner"
          v-model="valid"
        >
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                label="Nombre completo"
                counter="50"
                v-model="ownerFullName"
                :rules="rulesOwnerFullName"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Email de propietario"
                counter="50"
                :rules="rulesOwnerEmail"
                v-model="ownerEmail"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Telefono del propietario"
                counter="15"
                :rules="rulesOwnerPhone"
                v-model="ownerPhone"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="schema == 'search' ? disabledForms : false"
          color="primary"
          text
          @click="loadOwner"
          >{{ `${!ownerIsEdit ? "REGISTRAR" : "EDITAR"} PROPIETARIO` }}</v-btn
        >
        <v-btn
          :disabled="schema == 'search' ? disabledForms : false"
          color="red"
          text
          v-if="ownerIsEdit"
          @click="cancelEditOwner"
          >CANCELAR</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogOwner" width="500" persistent>
      <v-card>
        <v-card-title
          >Propietarios
          <v-spacer />
          <v-btn icon @click="dialogOwner = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="owners"
            :headers="ownerHeaders"
            hide-default-footer
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{ props.item.full_name }}</td>
                <td>
                  <v-btn
                    v-if="!disabledForms"
                    :disabled="ownerIsEdit"
                    icon
                    @click="editOwner(props.index)"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!disabledForms"
                    :disabled="ownerIsEdit"
                    icon
                    class="my-3"
                    @click="deleteOwner(props.index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mixinForm } from "../../mixins";

export default {
  props: {
    schema: String,
    data: Function,
    click: Number,
    clear: Number,
    disabledForms: Boolean,
    business: Object,
  },
  mixins: [mixinForm],

  mounted() {
    if(this.business)
      this.loadData();
  },

  data() {
    return {
      owners: [],
      ownerId: 0,
      ownerIsEdit: false,
      ownerEditIndex: "",
      ownerFullName: "",
      ownerEmail: "",
      ownerPhone: "",
      dialogOwner: false,
      disabledButtons: true,
      deletes: [],

      ownerHeaders: [
        {
          text: "Nombre",
          value: "name",
          sortable: false,
        },
        {
          text: "Acciones",
          aling: "center",
          sortable: false,
        },
      ],

      rulesOwnerFullName: [
        (ownerFullName) =>
          (ownerFullName && ownerFullName.length > 0) ||
          "Debe ingresar el nombre del propietario",
      ],
      rulesOwnerEmail: [
        (ownerEmail) =>
          (ownerEmail && ownerEmail.length > 0) ||
          "Debe ingresar el email del propietario",
        (ownerEmail) =>
          (ownerEmail && this.validEmail(ownerEmail)) ||
          "Ingrese un email valido",
      ],
      rulesOwnerPhone: [
        (ownerPhone) =>
          (ownerPhone && ownerPhone.length > 0) ||
          "Debe ingresar el telefono del propietario",
      ],
    };
  },

  watch: {
    click: function () {
      this.data({ owners: this.owners, deletes: this.deletes });
    },

    clear: function () {
      this.owners = [];
      this.$refs.formOwner.resetValidation();
    },
  },

  methods: {
    loadData() {
      this.disabledButtons = false;
      this.owners = this.business.owners;
    },

    loadOwner() {
      if (this.valid) {
        let owner = {
          full_name: this.ownerFullName.toUpperCase(),
          email: this.ownerEmail,
          phone: this.ownerPhone,
        };

        if (this.schema == "search" && this.ownerIsEdit) {
          owner.action = "edit";
          owner.id = this.ownerId;
        } else if (this.schema == "search" && !this.ownerIsEdit)
          owner.action = "new";

        this.ownerIsEdit
          ? this.owners.splice(this.ownerEditIndex, 1, owner)
          : this.owners.push(owner);

        this.resetFormOwner();
      } else this.$refs.formOwner.validate();
    },

    editOwner(index) {
      this.ownerFullName = this.owners[index].full_name;
      this.ownerEmail = this.owners[index].email;
      this.ownerPhone = this.owners[index].phone;
      this.dialogOwner = false;
      this.ownerEditIndex = index;
      this.ownerIsEdit = true;
      this.ownerId = this.owners[index].id;
    },

    cancelEditOwner() {
      this.$confirm("Desea cancelar la edicion del propietario?").then(
        (response) => {
          if (response) {
            this.ownerEditIndex = "";
            this.ownerIsEdit = false;
            this.resetFormOwner();
          }
        }
      );
    },

    resetFormOwner() {
      this.ownerFullName = "";
      this.ownerEmail = "";
      this.ownerPhone = "";
      this.ownerEditIndex = "';";
      this.ownerIsEdit = false;
      this.$refs.formOwner.resetValidation();
    },

    deleteOwner(index) {
      this.$confirm("Desea eliminar este propietario?").then((response) => {
        if (response) {
          if (this.owners[index].id) {
            let item = {
              id: this.owners[index].id,
              name: "owner",
            };
            this.deletes.push(item);
          }
          this.owners.splice(index, 1);
        }
      });
    },
  },
};
</script>

<style></style>
