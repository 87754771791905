import axios from "axios";

const store = {

    state: {
        setupGenerals: [],
        setupGeneralSelect: [],
        keySelect: [],
    },

    getters: {
        getSetupGenerals(state) {
            return state.setupGenerals;
        },

        getSetupGeneralSelect(state) {
            return state.setupGeneralSelect;
        },

        getSetupGeneralSelectByKey: (state) => (key) => {
            let result = [];
            state.setupGeneralSelect.map((element) => {
                if (element.key == key) {
                    result.push(element);
                }
            });

            return result;
        },

        getSetupGeneralKeySelect(state) {
            return state.keySelect;
        }
    },

    mutations: {
        loadSetupGenerals(state, setupGenerals) {
            state.setupGenerals = setupGenerals;
        },

        loadSetupGeneralSelect(state, setupGeneralSelect) {
            state.setupGeneralSelect = setupGeneralSelect;
        },

        loadSetupGeneralKeySelect(state, setupGeneralkeySelect) {
            state.keySelect = setupGeneralkeySelect;
        },

        addSetupGeneral(state, setupGeneral) {
            state.setupGenerals.push(setupGeneral);
        },

        editSetupGeneral(state, setupGeneralUpdate) {
            let index = state.setupGenerals
                .findIndex((setupGeneral) =>
                    setupGeneral.id === setupGeneralUpdate.id
                );
            state.setupGenerals.splice(index, 1, setupGeneralUpdate);
        }
    },

    actions: {
        loadSetupGeneral({ getters, commit }) {
            return new Promise((resolve, reject) => {
                if (getters.getSetupGenerals.length > 0)
                    resolve();
                else {
                    axios.get(`/setup-general`).then((response) => {
                        commit('loadSetupGenerals', response.data.setupGenerals);
                        resolve();
                    }).catch((error) => {
                        reject(error)
                    });
                }
            })
        },

        registerSetupGeneral({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post(`/setup-general/store`, payload).then((response) => {
                    commit('addSetupGeneral', response.data.setupGeneral);
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            })
        },

        editSetupGeneral({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.put(`/setup-general/${payload.id}`, payload).then((response) => {
                    commit('editSetupGeneral', response.data.setupGeneral)
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            })
        },

        changeStatusSetupGeneral({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.put(`/setup-general/change-status/${id}`).then((response) => {
                    commit('editSetupGeneral', response.data.setupGeneral)
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            })
        },

        loadSetupGeneralSelect({ getters, commit }) {
            return new Promise((resolve, reject) => {
                if (getters.getSetupGeneralSelect.length > 0)
                    resolve();
                else {
                    axios.get(`/setup-general/find/select`).then((response) => {
                        commit('loadSetupGeneralSelect', response.data)
                        resolve();
                    }).catch((error) => {
                        reject(error);
                    });
                }
            })
        },

        loadSetupGeneralKeySelect({ getters, commit }) {
            return new Promise((resolve, reject) => {
                if (getters.getSetupGeneralKeySelect.length > 0)
                    resolve();
                else {
                    axios.get(`/setup-general-key/find/select`).then((response) => {
                        commit('loadSetupGeneralKeySelect', response.data);
                        resolve();
                    }).catch((error) => {
                        reject(error);
                    });
                }
            })
        }

    }
}

export default store;