<template>
  <div>
    <group :avisar="aceptar" />
    <v-btn text @click="imprimir">Aceptar</v-btn>
  </div>
</template>

<script>
import Group from "../../components/Business/Group";
import bus from "../../events/bus";

export default {
  components: { Group },

  data() {
    return {
      info: {},
    };
  },

  methods: {
    aceptar(datos) {
      console.log(datos);
    },

    imprimir() {
      bus.$emit("ejecutar");
    },
  },
};
</script>

<style></style>
