<template>
  <v-dialog
    v-model="binnacle"
    :width="width"
    max-width="1000"
    scrollable
    persistent
  >
    <v-card max-height="600" :loading="loading" min-width="800">
      <v-card-title>
        <slot name="title" />
        <v-spacer />
        <v-btn icon @click="binnacle = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-timeline align-top dense>
          <v-timeline-item
            small
            v-for="binnacle in binnacles"
            :key="binnacle.id"
            :color="loadColor(binnacle.action)"
          >
            <v-layout>
              <v-flex xs3>
                Fecha de actividad
                <div class="caption mb-2">{{ binnacle.created_at }}</div>
              </v-flex>
              <v-flex xs5>
                <strong>{{ binnacle.description }}</strong>
                <div class="caption mb-2">{{ binnacle.action }}</div>
              </v-flex>
              <v-flex xs4>
                <v-layout>
                  <v-flex xs3>
                    <v-avatar>
                      <v-img
                        :src="
                          binnacle.createdBy.photo_url
                            ? binnacle.createdBy.photo_url
                            : avatar_default
                        "
                      />
                    </v-avatar>
                  </v-flex>
                  <v-flex xs9
                    ><p><strong>
                      {{
                        `${binnacle.createdBy.names} ${binnacle.createdBy.surnames}`
                      }}</strong><br />{{ binnacle.createdBy.email }}
                    </p>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
      <v-card-actions class="blue darken-1 font-italic">
        Si desea ver un reporte mas completo debe entrar en el modulo de bitacoras
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import bus from "../../events/bus";
import avatar_default from "../../assets/avatar_default.jpg";

export default {
  props: ["width"],

  data() {
    return {
      item: null,
      binnacle: false,
      avatar_default: avatar_default,
      loading: false,
    };
  },

  beforeMount() {
    bus.$on("binnacle", (item) => {
      this.item = item;
      this.binnacle = !this.binnacle;
      this.loading = true;
      this.$store
        .dispatch("findBinnacle", this.item)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    });
  },

  computed: {
    binnacles() {
      return this.$store.getters.getBinnacles;
    },
  },

  methods:{
    loadColor(action){
      if(action=="show")
        return "blue"
      else if(action=="store")
        return "green"
      else if(action=="update")
        return "purple"
      else if(action =="disabled")
        return "red"
      else if(action =="enabled")
        return "green"
    }
  }
};
</script>

<style>
</style>