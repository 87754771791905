import axios from "axios";
import bus from "./events/bus";
import store from "./store/";
import router from "./router/";
import setup from "./config";

axios.defaults.baseURL = setup.url;

//request
axios.interceptors.request.use(
  (config) => {
    var token = localStorage.getItem("access_token");

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    config.headers["Accept-Language"] = "es";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//response
axios.interceptors.response.use(
  (response) => {
    switch (response.status) {
      case 200:
        if (response.data.interceptor) {
          showMsj("success", 200, response.data);
        }
        break;
      case 201:
        if (response.data.interceptor) {
          showMsj("success", 201, response.data);
        }
        break;
      case 202:
        if (response.data.interceptor) {
          showMsj("warning", 202, response.data);
        }
        break;
      default:
        console.clear();
    }
    return response;
  },

  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          error.response.data.message = "Unauthorized";
          showMsj("error", 401, error.response.data);
          store.dispatch("logout").then(() => {
            router.push("/");
          });
          break;
        case 403:
          if (error.response.data.interceptor) {
            showMsj("warning", 403, error.response.data);
          }
          break;
        case 404:
          if (error.response.data.interceptor) {
            showMsj("warning", 404, error.response.data);
          }
          break;
        case 422:
          if (error.response.data.interceptor) {
            showMsj("warning", 422, error.response.data);
          }
          break;
        case 423:
          if (error.response.data.interceptor) {
            showMsj("warning", 423, error.response.data);
          }
          break;
        case 500:
          if (error.response.data.interceptor) {
            showMsj("error", 500, error.response.data);
          }
          break;
        default:
      }
    }
    return Promise.reject(error);
  }
);

function showMsj(type, code, data) {
  var config = {
    title: `${data.title}`,
    text: code == 422 ? `${data.message[0].message}` : `${data.message}`,
  };

  if (type === "error") {
    config.type = "error";
    config.color = "red";
  }
  if (type === "warning") {
    config.type = "warning";
    config.color = "red";
  }
  if (type === "success") {
    config.type = "success";
    config.color = "success";
  }

  bus.$emit("snackbar", {
    color: config.color,
    text: config.text,
  });
}

export default axios;
