import axios from "axios";

const store = {
  state: {
    business: [],
    businessOne: {},
    businessAutocomplete: [],
  },

  getters: {
    getBusiness(state) {
      return state.business;
    },

    getBusinessOne(state) {
      return state.businessOne;
    },

    getBusinessAutocomplete(state) {
      return state.businessAutocomplete;
    },
  },

  mutations: {
    loadBusiness(state, business) {
      state.business = business;
    },

    addBusiness(state, business) {
      state.business.push(business);
    },

    editBusiness(state, business) {
      state.businessOne = business;
    },

    businessAutocomplete(state, business) {
      state.businessAutocomplete = business;
    },

    loadOneBusiness(state, business) {
      state.businessOne = business;
    },

    deleteLogo(state, businessId) {
      if (state.businessOne.id == businessId) {
        state.businessOne.logo = "";
        state.businessOne.logo_id = "";
      }
    },
  },

  actions: {
    registerBusiness({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/business/store`, payload)
          .then((response) => {
            commit("addBusiness", response.data.businesSaved);
            resolve(response.data.businesSaved);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    editBusiness({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`business/${payload.get("id")}`, payload)
          .then((response) => {
            commit("editBusiness", response.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    loadBusiness({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/business/`)
          .then((response) => {
            commit("loadBusiness", response.data.business);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    businessAutocomplete({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/business/find/autocomplete/${payload.value}/${payload.onlyBusiness}`
          )
          .then((response) => {
            commit("businessAutocomplete", response.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    findBusinessById({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/business/${businessId}`)
          .then((response) => {
            commit("loadOneBusiness", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteLogo({ commit }, businessId) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/business/delete-logo/${businessId}`)
          .then((response) => {
            commit("deleteLogo", businessId);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    activateBusinessAction(Context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/business/activate`, payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
export default store;
