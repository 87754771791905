<template>
  <v-main class="d-flex align-center" :style="backgroundDiv">
    <v-container>
      <v-layout class="d-flex justify-start">
        <v-flex xs12 sm8 md4>
          <v-card>
            <div class="d-flex justify-center"></div>
            <v-card-text>
              <h3 class="d-flex justify-center mb-2">INGRESE SUS DATOS</h3>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  prepend-icon="mdi-email"
                  v-model="email"
                  :rules="rulesEmail"
                  label="Email"
                />
                <v-text-field
                  prepend-icon="mdi-lock"
                  v-model="password"
                  :rules="rulesPassword"
                  label="Password"
                  type="password"
                />
                <v-btn
                  color="primary"
                  block
                  @click="login"
                  class="mt-3"
                  :loading="loadingForm"
                  >ACEPTAR</v-btn
                >
                <div class="d-flex justify-end mb-3 mt-3">
                  <router-link to="/">Olvide mi password</router-link>
                </div>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <span class="text-caption">Desarrollado por @tecnocam 2020</span>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { mixinForm, mixinUser } from "../mixins";

export default {
  mixins: [mixinForm, mixinUser],

  data() {
    return {
      backgroundDiv: {
        backgroundImage: "url(" + require("../assets/background.jpg") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
    };
  },

  methods: {
    login() {
      this.loadingForm = true;
      if (!this.valid) this.$refs.form.validate();
      else {
        this.$store
          .dispatch("login", {
            email: this.email,
            password: this.password,
          })
          .then(() => {
            this.$router.push("/dashboard");
            this.loadingForm = false;
          })
          .catch((error) => {
            console.log(error);
            this.$router.push("/");
            this.loadingForm = false;
          });
      }
    },
  },

  beforeMount() {
    if (localStorage.getItem("access_token") != null) {
      this.$router.push("/dashboard");
    }
  },
};
</script>

<style scoped>
</style>